import { useState } from "react";
import { useMutationWrapper } from "../common/api";
import {
  useUpdateUserMutation,
  useGetUserQuery,
} from "../redux/reduxQuery/usersApi";
import { useUser } from "./useLogin";
import { mapRequestsFormInputsWithToggleFunction } from "../utils/Request/util";

export const useRequestCertification = () => {
  const [showModal, setShowModal] = useState(false);
  const [checked, setCheckbox] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const user = useUser();
  const [updateUser] = useUpdateUserMutation();
  const mutateUser = useMutationWrapper(
    updateUser,
    `Changes have been saved successfully`,
    () => {}
  );
  const {
    data: userData,
    refetch: refetchUser,
    isLoading: userLoading,
    isFetching,
  } = useGetUserQuery(user?.id);
  const modalPreference = userData?.modalPreference || {};
  const { showCertifiedRequestModal = true } = modalPreference;

  const requesterInfoInputsWithTogglefunction =
    mapRequestsFormInputsWithToggleFunction(
      showCertifiedRequestModal && !isFetching,
      setShowModal
    );

  const onCloseModal = async () => {
    if (checked) {
      setButtonLoader(true);
      await mutateUser({
        ...user,
        modalPreference: {
          ...modalPreference,
          showCertifiedRequestModal: false,
        },
      });
      await refetchUser();
    }
    setShowModal(false);
    setButtonLoader(false);
  };

  return {
    showModal,
    onCloseModal,
    checked,
    setCheckbox,
    buttonLoader,
    showCertifiedRequestModal,
    userLoading,
    requesterInfoInputsWithTogglefunction,
  };
};
