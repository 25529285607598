import {
  Dispatch,
  Fragment,
  SetStateAction,
  useEffect,
  useState,
  useRef,
} from "react";
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  usePagination,
  useFilters,
} from "react-table";
import styled from "styled-components";
import { PlusIcon, AdjustmentsIcon, XIcon } from "@heroicons/react/outline";
import get from "lodash/get";
import { ParagraphS, TitleM } from "../atoms/Typography";
import { Input } from "../atoms/Input";
import { Button, ButtonOutline } from "../atoms/Button";
import { Theme } from "../atoms/theme";
import searchIcon from "../assets/search.svg";
import { Pagination } from "../atoms/Pagination";
import {
  TableWrapper,
  TableStyle,
  TableH,
  TableData,
  HeaderText,
  HeaderWrapper,
  PaginationWrapper,
  DownArrow,
  UpArrow,
} from "./SharedTableStyles";
import { useForm } from "react-hook-form";
import { CustomSelect } from "../atoms/Select";
import { Tooltip } from "../atoms/Tooltip";
import { getColumnSize } from "../../utils/getColumnSize";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../hooks/redux";
import { setUserFilter } from "../../redux/reducers/filter";

const FilterButtonContainer = styled.div`
  display: flex;
  margin-left: 1rem;
  margin-right: 1rem;
`;

const FilterWrapper = styled.div`
  display: flex;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-color: ${Theme.colors.neutrals_100};
  opacity: 0.5;
  z-index: 998;
`;

const FilterSideBar = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0px;
  right: 0px;
  width: 21.25rem;
  background-color: ${Theme.colors.neutrals_500};
  opacity: 1;
  height: 100vh;
  z-index: 999;
  overflow: scroll;
`;

const FilterTitleContainer = styled.div`
  display: flex;
  margin-left: 2rem;
  margin-top: 2rem;
`;

const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 2rem;
  margin-right: 2rem;
`;

const CUSTOM_RENDER_COLUMNS = ["Status", "Actions", "Requests", "FV Status"];

interface ITable {
  columns: any;
  data: any;
  rowClick?: any;
  showFilter?: any;
  onClick?: any;
  showAddNewConnection?: any;
  openModal?: any;
  tableType?: string;
  setPage?: Dispatch<SetStateAction<number>>;
  totalAmountOfRecords?: number;
  searchByPage?: boolean;
  filterValues?: any;
  setFilterValues?: Dispatch<SetStateAction<any>>;
  keyToSearch?: string;
  onScroll?: any;
  scrollPosition?: number;
}

export const Table = ({
  columns,
  data,
  rowClick,
  showFilter,
  onClick,
  showAddNewConnection,
  openModal,
  tableType,
  setPage,
  totalAmountOfRecords,
  searchByPage,
  filterValues,
  setFilterValues,
  keyToSearch,
  onScroll,
  scrollPosition,
}: ITable) => {
  const tableInstance = useTable(
    {
      columns,
      data,
      rowClick,
      initialState: { pageSize: 50 },
      autoResetSortBy: false,
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state,
    setGlobalFilter,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    allColumns,
    setFilter,
    setSortBy,
  } = tableInstance;
  const { globalFilter, pageIndex: index } = state;
  const pages = Array.from(
    Array(
      totalAmountOfRecords ? Math.ceil(totalAmountOfRecords / 10) : pageCount
    ).keys()
  );
  const [openFilters, setOpenFilters] = useState(false);
  const [filterInputs, setFilterInputs] = useState<any>([]);
  const form = useForm();
  const dispatch = useDispatch();
  const globalUserFilter = useAppSelector((state) => state.filter);

  const filtersLength = Object.values(form.watch()).reduce((acc, curr) => {
    if ((typeof curr === "string" && curr === "") || curr === undefined) {
      return acc;
    } else if (curr === null) {
      return acc;
    } else if (
      typeof curr === "object" &&
      (Object.values(curr)[0] === undefined ||
        Object.values(curr)[0] === "" ||
        Object.values(curr)[0] === null)
    ) {
      return acc;
    } else return acc + 1;
  }, 0);
  const [pageIndex, setPageIndex] = useState(0);
  const [orderBy, setOrderBy] = useState({});
  const tableRef = useRef<any>(null);
  const cancelFilters = () => {
    form.reset();
    filter();
    setOpenFilters(false);
  };

  const closeFilters = () => {
    setOpenFilters(false);
  };

  useEffect(() => {
    setPageIndex && setPageIndex(pageIndex);
    setPage && setPage(pageIndex + 1);
  }, [pageIndex, setPageIndex, setPage]);

  useEffect(() => {
    let newData: any[] = [];
    allColumns.map((column: any) => {
      newData.push({
        id: column.id,
        label: column.Header,
        type: "text",
        required: false,
        clearButton: true,
        select: !!column.options,
        options: column.options,
        canFilter: column.canFilter,
      });
      return column;
    });

    setFilterInputs(newData);
  }, [allColumns]);

  useEffect(() => {
    const filters = globalUserFilter[tableType || ""];
    Object.keys(filters || []).forEach((key) => {
      if (get(filters, key)) {
        const value = get(filters, key);
        form.setValue(key, value);
      }
    });
    const newFilterInputValues = form.getValues();
    setFilterValues && setFilterValues({ ...newFilterInputValues, ...orderBy });
  }, [globalUserFilter, tableType, form, setFilter, setFilterValues, orderBy]);

  const filter = form.handleSubmit(() => {
    const newFilterInputValues = form.getValues();
    const getValue = (obj: any, key: string) => {
      const keys = key.split(".");
      return get(obj, keys);
    };
    const formUserFilter = {};
    allColumns.map((column) => {
      const hasValue =
        getValue(newFilterInputValues, column.id) !== null &&
        getValue(newFilterInputValues, column.id) !== undefined;
      if (hasValue) {
        formUserFilter[column.id] = getValue(newFilterInputValues, column.id);
        if (
          typeof getValue(newFilterInputValues, column.id) === "string" &&
          getValue(newFilterInputValues, column.id) !== ""
        ) {
          setFilter(column.id, getValue(newFilterInputValues, column.id));
        } else if (
          typeof getValue(newFilterInputValues, column.id) === "object" &&
          column.id !== "state"
        ) {
          const { label } = getValue(newFilterInputValues, column.id);
          setFilter(column.id, label);
        } else if (column.id === "state") {
          const { value } = getValue(newFilterInputValues, column.id);
          setFilter(column.id, value);
        } else {
          setFilter(column.id, undefined);
        }
      } else {
        setFilter(column.id, undefined);
      }
      return column;
    });

    const globalUserFilters = { [tableType || ""]: formUserFilter };
    dispatch(setUserFilter(globalUserFilters));
    setPageIndex(0);
    setFilterValues && setFilterValues({ ...newFilterInputValues, ...orderBy });
    setOpenFilters(false);
  });

  const resetFilters = () => {
    form.reset();
    filter();
  };

  const handleSortBy = (column, setSortBy) => {
    const desc =
      column.isSortedDesc === true
        ? undefined
        : !!(column.isSortedDesc === false);

    const order =
      desc === undefined
        ? undefined
        : column.isSortedDesc === false
        ? "DESC"
        : "ASC";

    const sortedBy = { orderBy: column.filterName, desc: order };
    setOrderBy(sortedBy);
    const filterInputs = form.getValues();

    setFilterValues && setFilterValues({ ...filterInputs, ...sortedBy });
    setSortBy([{ id: column.id, desc }]);

    if (desc === undefined) {
      setSortBy([]);
    }
    setPageIndex(0);
  };

  useEffect(() => {
    const handleKeyUp = (event) => {
      if (openFilters) {
        event.preventDefault();
        if (event.key === "Enter") {
          filter();
        }
      }
    };

    document.addEventListener("keyup", handleKeyUp);

    return () => {
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, [openFilters, filter]);

  return (
    <>
      <TableWrapper>
        {openFilters && (
          <Fragment>
            <FilterWrapper onClick={closeFilters}></FilterWrapper>
            <FilterSideBar>
              <FilterTitleContainer>
                <TitleM>Filter</TitleM>
              </FilterTitleContainer>
              <FilterContainer>
                {filtersLength > 0 && (
                  <div style={{ width: "100%" }}>
                    <ButtonOutline
                      width={"100%"}
                      noText={true}
                      buttonText={`Reset ${filtersLength} filters`}
                      onClick={() => resetFilters()}
                      color={Theme.colors.primaries_000}
                      filter={true}
                    />
                  </div>
                )}

                <form>
                  {filterInputs.map(
                    (input) =>
                      input.canFilter &&
                      (input.select || input.type === "state" ? (
                        <div style={{ marginTop: "2.25rem", display: "flex" }}>
                          <CustomSelect
                            {...form.register(input.id)}
                            label={input.label}
                            id={input.id}
                            options={input.options}
                            setValue={(value) => {
                              form.setValue(input.id, value);
                            }}
                            value={form.watch(input.id)}
                            name={input.name}
                          />
                          {form.watch(input.id)?.value?.length > 0 && (
                            <div style={{ marginTop: "1.75rem" }}>
                              <ButtonOutline
                                icon={true}
                                color={Theme.colors.primaries_000}
                                hoverColor={Theme.colors.primaries_neg100}
                                width={"2.75rem"}
                                onClick={() => form.setValue(input.id, null)}
                                img={
                                  <XIcon
                                    width={"0.938rem"}
                                    color={Theme.colors.primaries_000}
                                  />
                                }
                                requiredHoverClick={true}
                              />
                            </div>
                          )}
                        </div>
                      ) : (
                        <div style={{ marginTop: "2.25rem", display: "flex" }}>
                          <Input
                            {...form.register(input.id)}
                            noLabel={true}
                            labelText={input.label}
                            required={input.required}
                            icon={input.icon}
                            img={input.img}
                            width={"100%"}
                            type={input.type}
                            placeholderText={
                              input.placeholder
                                ? input.placeholder
                                : input.label
                            }
                            setFormValue={(value) => {
                              form.setValue(input.id, value);
                              return value;
                            }}
                          />
                          {form.watch(input.id)?.length > 0 && (
                            <div
                              style={{
                                marginTop: "1.75rem",
                                marginLeft: "0.5rem",
                              }}
                            >
                              <ButtonOutline
                                icon={true}
                                color={Theme.colors.primaries_000}
                                hoverColor={Theme.colors.primaries_neg100}
                                width={"2.75rem"}
                                onClick={() => form.setValue(input.id, null)}
                                img={
                                  <XIcon
                                    width={"0.938rem"}
                                    color={Theme.colors.primaries_000}
                                  />
                                }
                                requiredHoverClick={true}
                              />
                            </div>
                          )}
                        </div>
                      ))
                  )}
                  <div
                    style={{
                      display: "flex",
                      marginBottom: "2.25rem",
                      marginTop: "2.25rem",
                    }}
                  >
                    <ButtonOutline
                      style={{ margin: 0 }}
                      buttonText={"Cancel"}
                      icon={false}
                      color={Theme.colors.primaries_000}
                      hoverColor={Theme.colors.primaries_neg100}
                      noText={true}
                      onClick={() => {
                        cancelFilters();
                      }}
                      width={"100%"}
                    />
                    <Button
                      style={{ marginLeft: "1rem", marginRight: 0 }}
                      buttonText={"Apply"}
                      icon={false}
                      color={Theme.colors.primaries_000}
                      hoverColor={Theme.colors.primaries_neg100}
                      noText={true}
                      width="100%"
                      onClick={() => filter()}
                    />
                  </div>
                </form>
              </FilterContainer>
            </FilterSideBar>
          </Fragment>
        )}
        <div
          style={{
            marginBottom: "2rem",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Input
            noLabel={false}
            type="text"
            error={false}
            required={true}
            icon={true}
            width={showFilter ? "76%" : "88%"}
            filter={globalFilter}
            placeholderText="Search"
            img={searchIcon}
            setFormValue={(value) => {
              searchByPage && keyToSearch
                ? setFilterValues?.({
                    ...filterValues,
                    [keyToSearch]: value,
                  })
                : setGlobalFilter(value);
              return value;
            }}
          />
          <FilterButtonContainer>
            <ButtonOutline
              icon={true}
              color={Theme.colors.primaries_000}
              hoverColor={Theme.colors.primaries_neg100}
              noText={true}
              width={"8.25rem"}
              buttonText={`Filters ${
                filtersLength > 0 ? "(" + filtersLength + ")" : ""
              }`}
              onClick={() => setOpenFilters(true)}
              img={
                <AdjustmentsIcon
                  width={"0.938rem"}
                  color={Theme.colors.primaries_000}
                />
              }
            />
          </FilterButtonContainer>

          {showAddNewConnection ? (
            <Button
              icon={true}
              color={Theme.colors.primaries_000}
              hoverColor={Theme.colors.primaries_neg100}
              noText={true}
              width={"40%"}
              buttonText="Add new Connection"
              onClick={openModal}
              img={
                <PlusIcon
                  width={"0.938rem"}
                  color={Theme.colors.neutrals_500}
                />
              }
            />
          ) : (
            <Button
              icon={true}
              color={Theme.colors.primaries_000}
              hoverColor={Theme.colors.primaries_neg100}
              noText={true}
              width={"9rem"}
              buttonText="add new"
              onClick={onClick}
              img={
                <PlusIcon
                  width={"0.938rem"}
                  color={Theme.colors.neutrals_500}
                />
              }
            />
          )}
        </div>
        <TableStyle {...getTableProps()}>
          <thead
            style={{ borderBottom: `2px solid ${Theme.colors.neutrals_400}` }}
          >
            {headerGroups.map((headerGroup) => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                style={{
                  width: "99.1%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                {headerGroup.headers.map((column, index) => {
                  return (
                    <TableH
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      onClick={() => handleSortBy(column, setSortBy)}
                      style={{
                        width: getColumnSize(tableType, index),
                        cursor: column.canFilter ? "pointer" : "default",
                      }}
                    >
                      <HeaderWrapper>
                        <HeaderText sorted={column.isSorted}>
                          {column.render("Header")}
                        </HeaderText>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <DownArrow />
                          ) : (
                            <UpArrow />
                          )
                        ) : (
                          ""
                        )}
                      </HeaderWrapper>
                    </TableH>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody
            {...getTableBodyProps()}
            style={{
              maxHeight: "50vh",
              overflow: "auto",
              display: "block",
              // transform: "translate(0,0)",
            }}
            ref={tableRef}
            onScroll={() => {
              const tableBody = tableRef.current;
              const scrollPosition = tableBody?.scrollTop;
              if (onScroll) {
                onScroll(scrollPosition);
              }
            }}
          >
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    borderBottom: `2px solid ${Theme.colors.neutrals_400}`,
                  }}
                >
                  {row.cells.map((cell: any, index) => {
                    const RenderedCell = cell.render("Cell");
                    return CUSTOM_RENDER_COLUMNS.includes(
                      cell.column.Header
                    ) ? (
                      <TableData
                        key={`custom-render-table-header-${index}`}
                        style={{
                          width: getColumnSize(tableType, index),
                        }}
                      >
                        {RenderedCell}
                      </TableData>
                    ) : (
                      <TableData
                        key={`table-header-${index}`}
                        style={{
                          width: getColumnSize(tableType, index),
                        }}
                      >
                        {cell.column.includeToolTip ? (
                          <Tooltip
                            text={RenderedCell}
                            position="top"
                            scrollPosition={scrollPosition}
                            background={Theme.colors.neutrals_000}
                          >
                            <ParagraphS
                              style={{
                                margin: 0,
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                              color={Theme.colors.neutrals_100}
                            >
                              {RenderedCell}
                            </ParagraphS>
                          </Tooltip>
                        ) : (
                          <ParagraphS
                            style={{
                              margin: 0,
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                            color={Theme.colors.neutrals_100}
                          >
                            {RenderedCell}
                          </ParagraphS>
                        )}
                      </TableData>
                    );
                  })}
                </tr>
              );
            })}
            {data.length === 0 && <ParagraphS>No Data</ParagraphS>}
          </tbody>
        </TableStyle>
      </TableWrapper>
      {pages.length > 1 ? (
        <PaginationWrapper>
          <Pagination
            pages={pages}
            pageCount={pages.length}
            nextPage={nextPage}
            prevPage={previousPage}
            index={pageIndex ? pageIndex : index}
            gotoPage={gotoPage}
            setPageIndex={setPageIndex}
            searchByPage={searchByPage}
          />
        </PaginationWrapper>
      ) : null}
    </>
  );
};
