import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootStateOrAny } from "react-redux";

export const API_URL = process.env.REACT_APP_API_URL || "http://localhost:4000";

const api = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootStateOrAny).auth.token;

      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  keepUnusedDataFor: 10,
  endpoints: () => ({}),
});

export default api.enhanceEndpoints({
  addTagTypes: [
    "Patients",
    "Firms",
    "Providers",
    "PatientForms",
    "Requests",
    "Users",
    "User",
    "MedicalRequests",
  ],
});
