import { Dispatch, SetStateAction } from "react";
import { Table } from "@tyrannosaurustech/ui";
import { Filter } from "./Filter";

interface ICustomTable {
  columns: any;
  data: any;
  isLoading?: any;
  navigation?: any;
  tableType?: string;
  actionButtonsConfig?: any[];
  setPage?: Dispatch<SetStateAction<number>>;
  dataSize?: number;
  searchByPage?: boolean;
  setFilterValues?: Dispatch<SetStateAction<any>>;
  totalAmountOfRecords?: number;
  setOpenFilters?: Dispatch<SetStateAction<boolean>>;
  openFilters?: boolean;
  loading?: boolean;
  asyncConfig?: any;
  pageSize?: number;
}

export const CoreTable = ({
  columns,
  data,
  navigation,
  tableType,
  setFilterValues,
  actionButtonsConfig = [],
  setOpenFilters = () => {},
  setPage = () => {},
  openFilters,
  loading,
  asyncConfig,
  pageSize = 50,
  ...config
}: ICustomTable) => {
  const headingHeights = "144px";
  const tableBottomMarginSpace = "10%";
  return (
    <>
      <Filter
        setFilterValues={setFilterValues}
        columns={columns}
        data={data}
        tableType={tableType}
        setOpenFilters={setOpenFilters}
        onPaginationChange={asyncConfig?.onPaginationChange}
        openFilters={openFilters}
      />
      <div
        style={{
          height: `calc(100vh - ${headingHeights} - ${tableBottomMarginSpace})`,
        }}
      >
        <Table
          columns={columns.filter((c) => c.visible !== false)}
          data={data}
          initialTableState={{
            pagination: {
              pageSize: pageSize,
              pageIndex: 0,
            },
          }}
          handleRowClick={() => navigation()}
          actionButtonsConfig={actionButtonsConfig}
          loading={loading}
          asyncConfig={asyncConfig}
          {...config}
        />
      </div>
    </>
  );
};
