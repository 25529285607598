import { useState } from "react";
import { useNavigate } from "react-router-dom";

export const useActionButtons = () => {
  const [openFilters, setOpenFilters] = useState(false);
  const navigate = useNavigate();
  const actionButtonsConfig = [
    {
      label: "Filters",
      icon: "AdjustmentsVerticalIcon",
      base: "secondary",
      onClick: () => setOpenFilters(true),
      btnContainerClasses:
        "box-border border-2 border-primary-400 border-solid",
    },
    {
      label: "Add New",
      icon: "PlusIcon",
      onClick: () => navigate("/admin/medical-providers/new"),
      btnContainerClasses: "box-border",
    },
  ];

  return { openFilters, setOpenFilters, actionButtonsConfig };
};
