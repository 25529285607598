import { useState } from "react";
import { useMutationWrapper } from "../../../common/api";
import { ModalWithTwoButtons } from "../../../components/molecules/Modal";
import { useUser } from "../../../hooks/useLogin";
import { useDeleteUserMutation } from "../../../redux/reduxQuery/usersApi";

interface IDeleteUserModal {
  isOpen: boolean;
  selectedUser?: any;
  onClose: () => any;
}
export const DeleteUserModal: React.FC<IDeleteUserModal> = (props) => {
  const loggedInUser = useUser();
  const [deleteUser] = useDeleteUserMutation();
  const [isDeleting, setIsDeleting] = useState(false);
  const mutate = useMutationWrapper(
    deleteUser,
    "Successfully Deleted User",
    () => props.onClose()
  );
  if (
    props.isOpen &&
    props.selectedUser &&
    loggedInUser?.id === props.selectedUser?.id
  ) {
    props.onClose();
    return <></>;
  }
  return props.isOpen ? (
    <ModalWithTwoButtons
      title="delete user"
      content={`Are you sure you want to delete ${props.selectedUser?.name}? If you do you won’t be able to recover it.`}
      button1Text="delete"
      button2Text="cancel"
      loader={isDeleting}
      onClick1={() => {
        setIsDeleting(true);
        mutate(props.selectedUser?.id);
      }}
      onClick2={props.onClose}
    />
  ) : (
    <></>
  );
};
