import React from "react";
import styled from "styled-components";
import { Checkbox, Toggle } from "@tyrannosaurustech/ui";
import { Theme } from "../atoms/theme";

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: ${Theme.colors.gray_100} 1px solid;
  border-radius: 8px;
  padding: 0.5rem 0.25rem;
`;

const InnerWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const CheckboxAndSwitch = ({
  id,
  label,
  getValues,
  checked,
  toggled,
  onChangeCheckbox,
  onChangeToggle,
  disabled = false,
  toggleLabel = "",
}) => {
  return (
    <Wrapper>
      <Checkbox
        name={getValues(id)}
        label={label}
        isRight={true}
        checked={checked}
        disabled={disabled}
        onChange={() => onChangeCheckbox(!checked)}
      />
      <InnerWrapper>
        <Toggle
          enabled={toggled}
          disabled={disabled}
          onChange={() => onChangeToggle(!toggled)}
        />
        {toggleLabel && (
          <div className="px-2">
            <p
              className={`text-pg-sm ${
                disabled ? "text-neutral-400" : "text-neutral-500"
              } dark:text-neutral-0 `}
            >
              {toggleLabel}
            </p>
          </div>
        )}
      </InnerWrapper>
    </Wrapper>
  );
};
