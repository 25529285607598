import { useEffect, useState } from "react";
import { useGetMedicalProviders } from "../../../../redux/reduxQuery/medicalProviderApi";
import { TableUtil } from "@tyrannosaurustech/ui";

export const useGetData = (initialQs = "", pageSize = 50) => {
  const [dataToFetch, setDataToFetch] = useState(initialQs);
  const getData = useGetMedicalProviders(dataToFetch);
  const [config, refetch] = TableUtil.useAsyncConfig(getData, pageSize);
  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataToFetch]);
  return {
    config,
    refetch,
    dataToFetch,
    setDataToFetch,
  };
};
