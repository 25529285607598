import { useMemo } from "react";
import {
  ParagraphS,
  SubtitlesS,
} from "../../../../components/atoms/Typography";
import { Theme } from "../../../../components/atoms/theme";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { statusColor } from "../../../../utils/statusColor";
import { statusLabel } from "../../../../utils/statusLabel";
import { Badge, Button } from "@tyrannosaurustech/ui";
import styled from "styled-components";
import { formatDate } from "../../../../utils/formatDate";
import {
  RequestType,
  RequestTypeLabelMapping,
} from "../../../../components/atoms/RequestTypes";
import { MiniStatusBadge } from "../../../../components/molecules/MiniStatusBadge";
import { providerVerifiedLabel } from "../../../../utils/providerVerifiedLabel";

const status: selectProps[] = [
  {
    value: "New",
    label: "New",
  },
  {
    value: "Entered",
    label: "Entered",
  },
  {
    value: "Processing",
    label: "Processing",
  },
  {
    value: "awaiting_law_firm_info",
    label: "Awaiting Law Firm Info",
  },
  {
    value: "vetted",
    label: "Vetted",
  },
  {
    value: "closed_not_sent",
    label: "Closed - Not Sent",
  },
  {
    value: "needs_further_review",
    label: "Needs Further Review",
  },
];

const providerStatus = [
  {
    value: "verified",
    label: "Verified",
  },
  {
    value: "unverified",
    label: "Unverified",
  },
  {
    value: "outdated",
    label: "Outdated",
  },
];

interface selectProps {
  value: string;
  label: string;
}

const UL = styled.ul`
  margin: 0;
`;
const RequestList = ({ requests }) => (
  <UL>
    {requests.map((request, index) => (
      <li key={`${request}-${index}`}>
        <ParagraphS>{request}</ParagraphS>
      </li>
    ))}
  </UL>
);

export const Status = (props) => {
  return (
    <ParagraphS
      color={statusColor[props.status]}
      style={{ margin: 0, textTransform: "capitalize" }}
    >
      {statusLabel[props.status]}
    </ParagraphS>
  );
};

export const useColumns = (medicalProvider, patients) => {
  return {
    columns: useMemo(
      () => [
        {
          id: "firmName",
          Header: "Law Firm",
          accessor: "firm.name",
          header: "Law Firm",
          accessorKey: "firm.name",
          size: 200,
          filterName: "Law Firm",
          includeToolTip: true,
        },
        {
          Header: "Patient Name",
          accessor: "patient.name",
          header: "Patient Name",
          accessorKey: "patient.name",
          size: 200,
          filterName: "patientName",
          options: patients,
          includeToolTip: true,
        },
        {
          Header: "Status",
          accessor: "status",
          header: "Status",
          accessorKey: "status",
          size: 200,
          filterName: "status",
          options: status,
          cell: (cell) => <Status status={cell.row.original.status} />,
        },
        {
          Header: "Requests",
          accessor: (row) => row?.original?.medicalRequests?.length ?? 0,
          header: "Requests",
          options: RequestType,
          accessorKey: "",
          size: 50,
          cell: (cellProps) => {
            //Order the requests by the order in the RequestTypeLabelMapping (client requested order)
            const requests = Object.keys(RequestTypeLabelMapping).map(
              (type) => {
                const request = cellProps?.row?.original?.medicalRequests.find(
                  (r) => r.requestType === type
                );
                if (request) {
                  return RequestTypeLabelMapping[request.requestType];
                }
                return null;
              }
            );

            const sortedRequests = requests.filter((r) => r);
            return (
              <div>
                <Tooltip
                  // The anchor select would not accept an id that starts with a number so the T is added here
                  anchorSelect={"#T" + String(cellProps.row.original.id)}
                  style={{
                    zIndex: 1000,
                    padding: "1rem",
                    borderRadius: "0.75rem",
                    backgroundColor: Theme.colors.neutrals_000,
                  }}
                  opacity={1.0}
                >
                  <RequestList requests={sortedRequests} />
                </Tooltip>

                <div id={"T" + cellProps.row.original.id} className="w-max">
                  <Badge
                    icon="EyeIcon"
                    color={Theme.colors.primaries_000}
                    rounded={true}
                    label={`${sortedRequests?.length ?? 0}`}
                  />
                </div>
              </div>
            );
          },
        },
        {
          Header: "Medical Provider",
          accessor: "provider.name",
          header: "Medical Provider",
          accessorKey: "provider.name",
          filterName: "providerName",
          options: medicalProvider,
          includeToolTip: true,
          size: 200,
          cell: (cell) => {
            return (
              <>
                <SubtitlesS>{cell.row.original.provider.name}</SubtitlesS>
                <ParagraphS color={Theme.colors.gray_000}>
                  {cell.row.original.provider.address}
                </ParagraphS>
              </>
            );
          },
        },
        {
          Header: "Verif.",
          id: "Verif",
          filterName: "providerStatus",
          header: "Verif.",
          accessorKey: "providerStatus",
          options: providerStatus,
          accessor: (row) => row?.original?.provider?.status,
          size: 100,
          cell: (cell) => {
            return (
              <div>
                <Tooltip
                  // The anchor select would not accept an id that starts with a number so the T is added here
                  anchorSelect={"#Verified-" + String(cell.row.original.id)}
                  style={{
                    zIndex: 1000,
                    padding: "1rem",
                    borderRadius: "0.75rem",
                    backgroundColor: Theme.colors.neutrals_000,
                  }}
                  opacity={1.0}
                >
                  <ParagraphS>
                    {providerVerifiedLabel[cell.row.original.provider.status]}
                  </ParagraphS>
                </Tooltip>
                <div>
                  <MiniStatusBadge
                    id={"Verified-" + cell.row.original.id}
                    status={cell.row.original.provider.status}
                  />
                </div>
              </div>
            );
          },
        },
        {
          Header: "Service Dates",
          filterName: "serviceDates",
          header: "Service Dates",
          accessorKey: "serviceDates",
          size: 100,
          cell: (cell) => {
            return formatDate(cell.row.original.serviceDates);
          },
        },
        {
          Header: "Submitted",
          filterName: "submittedDate",
          header: "Submitted",
          accessorKey: "submittedDate",
          size: 100,
          accessor: (row) => formatDate(row.submittedDate),
          cell: (cell) => {
            return formatDate(cell.row.original.submittedDate);
          },
          disableFilters: true,
        },
        {
          Header: "Actions",
          accessor: "id",
          header: "",
          accessorKey: "id",
          disableFilters: true,
          enableSorting: false,
          size: 72,
          cell: (cell) => {
            return (
              <div className="mr-[1rem]">
                <Link to={`${cell.row.original.id}`}>
                  <Button icon="EyeIcon"></Button>
                </Link>
              </div>
            );
          },
        },
      ],
      [medicalProvider, patients]
    ),
  };
};
