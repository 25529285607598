import React, { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { EyeIcon, TrashIcon } from "@heroicons/react/outline";

import { CustomTable } from "../../components/organisms/ShimmerTable";
import { ContentWrapper } from "../../components/ContentWrapper";
import { ModalWithTwoButtons } from "../../components/molecules/Modal";
import { EyeTrashIconButtons } from "../../components/molecules/TableActionButtons";
import { TitleM } from "../../components/atoms/Typography";
import {
  useGetUsersQuery,
  useDeleteUserMutation,
} from "../../redux/reduxQuery/usersApi";
import { useUser } from "../../hooks/useLogin";
import { formatRole } from "../../utils/formatRole";
function ClientUsers() {
  const loggedInUser = useUser();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<any>({});
  const [isLoading, setIsloading] = useState(false);
  const { data: users, isFetching, refetch } = useGetUsersQuery("");
  const [deleteUser] = useDeleteUserMutation();
  const { id: clientId } = useParams();
  let navigate = useNavigate();

  const columns = useMemo(
    () => [
      {
        Header: "User Name",
        accessor: "name",
      },
      {
        Header: "Admin/Users",
        accessor: (row) => formatRole(row.roles),
      },
      {
        Header: "Address",
        accessor: "address",
      },
      {
        Header: "City",
        accessor: "city",
      },
      {
        Header: "State",
        accessor: "state",
      },
      {
        Header: "Zipcode",
        accessor: "zip",
      },
      {
        Header: "Actions",
        accessor: "id",
        disableFilters: true,
        Cell: (cellProps) => {
          let icons = [EyeIcon];
          if (loggedInUser?.id !== cellProps.row.original.id)
            icons.push(TrashIcon);
          return (
            <EyeTrashIconButtons
              keyValue={`eye-trash-dot-icons-${cellProps.row.id}`}
              icons={icons}
              link={`/client/${clientId}/users/${cellProps.row.original.id}`}
              onClick1={() =>
                navigate(
                  `/client/${clientId}/users/${cellProps.row.original.id}`
                )
              }
              onClick2={() => {
                setSelectedUser(cellProps.row.original);
                setIsOpen(true);
              }}
            />
          );
        },
      },
    ],
    [navigate, clientId, loggedInUser]
  );
  return (
    <>
      {isOpen && (
        <ModalWithTwoButtons
          title="delete user"
          content={`Are you sure you want to delete ${selectedUser?.name}? If you do you won’t be able to recover it.`}
          button1Text="delete"
          button2Text="cancel"
          loader={isLoading}
          onClick1={async () => {
            setIsloading(true);
            await deleteUser(selectedUser?.id);
            refetch();
            setIsloading(false);
            setIsOpen(false);
          }}
          onClick2={() => setIsOpen(false)}
        />
      )}
      <ContentWrapper>
        <TitleM>Users</TitleM>
        <CustomTable
          tableType="clientusers"
          columns={columns}
          data={users}
          isLoading={isFetching}
          navigation={() => navigate(`/client/${clientId}/new-user`)}
        />
      </ContentWrapper>
    </>
  );
}
export default ClientUsers;
