import { Modal } from "@tyrannosaurustech/ui";
import { useDeleteProviderMutation } from "../../../redux/reduxQuery/medicalProviderApi";
import useToast from "../../../hooks/useToast";

interface IDeleteMedicalProvider {
  name: string;
  id: string;
  open: boolean;
  setOpen: (shouldOpen: boolean) => void;
}
export const DeleteMedicalProvider = (props: IDeleteMedicalProvider) => {
  const toast = useToast();
  const [deleteProvider] = useDeleteProviderMutation();
  const deleteSelectedProvider = (providerName, providerId) => {
    deleteProvider(providerId)
      .unwrap()
      .then((res) => {
        if (res?.status === "OK") {
          toast.success(`Provider ${providerName} was deleted successfully`);
        } else if (res.error.data?.name === "Error") {
          toast.error(res.error.data.message);
        } else if (res.error?.status === "FETCH_ERROR") {
          toast.error(res.error.error);
        }
        props.setOpen(false);
      });
  };
  return (
    <Modal
      title="Delete Provider"
      icon="ExclamationTriangleIcon"
      bodyText="Are you sure you want to delete this provider? This action can't be undone."
      open={props.open}
      variant="danger"
      setOpen={props.setOpen}
      onConfirm={() => deleteSelectedProvider(props.name, props.id)}
    />
  );
};

interface IVerifyMedicalProvider {
  provider: any;
  updateProvider: (provider: any) => Promise<any>;
  refetch: () => void;
  open: boolean;
  setOpen: (shouldOpen: boolean) => void;
}
export const VerifyMedicalProvider = (props: IVerifyMedicalProvider) => {
  const toast = useToast();
  const verifyProvider = () => {
    props
      .updateProvider({ ...props.provider, status: "verified" })
      .then((_res) => {
        props.setOpen(false);
        props.refetch();
        toast.success(`${props.provider.name} has been verified Successfully`);
      });
  };
  return (
    <Modal
      title="Verify This Medical Provider"
      icon="ExclamationTriangleIcon"
      confirmText="Verify"
      bodyText="Are you sure you want to verify this provider? Please make sure all the necessary information is filled."
      open={props.open}
      variant="warning"
      setOpen={props.setOpen}
      onConfirm={() => verifyProvider()}
    />
  );
};
