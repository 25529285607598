import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { ModalWithOneButton } from "./Modal";
import { Theme } from "../atoms/theme";
import { useMemo } from "react";
import { SimpleTable, LoadingTable } from "../organisms/SimpleTable";
import { ButtonOutline } from "../atoms/Button";
import { getRequestsFromRequests } from "../../common/request";
import { Status } from "../../screens/AdminPortal/Requests";
import { Tooltip } from "../atoms/Tooltip";
import { Badge } from "../atoms/Badge";
import { EyeIcon } from "@heroicons/react/outline";
import styled from "styled-components";

interface ModalProps {
  title: string;
  content: string;
  requests?: any[];
  isLoading?: boolean;
  buttonText?: string;
  textColor?: string;
  buttonColor?: string;
  hoverColor?: string;
  icon?: boolean;
  onClick?: () => void;
}

const UL = styled.ul`
  margin: 0;
  padding-left: 0.938rem;
`;

const RequestList = ({ requests }) => (
  <UL>
    {requests.map((request, index) => (
      <li key={`medical-request-${index}`}>{request}</li>
    ))}
  </UL>
);

export const ProviderRequestModal = ({
  title,
  content,
  requests = [],
  isLoading = false,
  buttonText = "close",
  buttonColor = Theme.colors.primaries_000,
  hoverColor = Theme.colors.primaries_neg100,
  onClick = () => {},
}: ModalProps) => {
  let navigate = useNavigate();
  const [scrollPosition, setScrollPosition] = useState(0);

  const columns = useMemo(
    () => [
      {
        Header: "Requests For",
        accessor: "patient.name",
      },
      {
        Header: "Requests",
        accessor: (row) => getRequestsFromRequests(row).length,
        Cell: (cellProps) => {
          const request = cellProps.row.original;
          const requests = getRequestsFromRequests(request);
          return (
            <Tooltip
              text={<RequestList requests={requests} />}
              position="top-right"
              background={Theme.colors.neutrals_000}
              scrollPosition={scrollPosition}
            >
              <Badge
                icon={true}
                color={Theme.colors.primaries_000}
                hoverColor={Theme.colors.primaries_neg100}
                badgeText={`${requests.length}`}
                img={
                  <EyeIcon
                    width={"0.938rem"}
                    color={Theme.colors.neutrals_500}
                  />
                }
              />
            </Tooltip>
          );
        },
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: (cell) => <Status status={cell.value} />,
      },
      {
        Header: "Actions",
        accessor: "actions",
        Cell: (cell) => {
          const requestId = cell.row.original.id;
          return (
            <ButtonOutline
              buttonText={"View Request"}
              icon={false}
              color={buttonColor}
              hoverColor={hoverColor}
              noText={true}
              width="100%"
              onClick={() => navigate(`/admin/requests/${requestId}`)}
              style={{ margin: "0 0 4px 0" }}
            />
          );
        },
      },
    ],
    [buttonColor, hoverColor, navigate, scrollPosition]
  );

  const requestTable = isLoading ? (
    <LoadingTable headers={columns.map((column) => column.Header)} />
  ) : (
    <SimpleTable
      style={{ maxHeight: "16vh" }}
      columns={columns}
      data={requests}
      tableType="requestModal"
      scrollPosition={scrollPosition}
      onScroll={setScrollPosition}
    />
  );
  return (
    <ModalWithOneButton
      icon={false}
      title={title}
      content={content}
      children={requestTable}
      buttonText={buttonText}
      buttonColor={buttonColor}
      hoverColor={hoverColor}
      onClick={onClick}
      reverseButtonColor={true}
      style={{ width: "41rem", maxHeight: "31.5rem" }}
    />
  );
};
