export const getRequestsFromRequests = (request) => {
  const requests: string[] = [];
  if (!!request.requestBills) {
    if (!!request.requestBillsCertified) {
      requests.push("Bills Certified");
    } else requests.push("Bills");
  }
  if (!!request.requestERBilling) {
    if (!!request.requestERBillingCertified) {
      requests.push("ER Physicians Billing Certified");
    } else requests.push("ER Physicians Billing");
  }
  if (!!request.requestHospitalAbstract) {
    if (!!request.requestHospitalAbstractCertified) {
      requests.push("Hospital Abstract (in lieu of full recs) Certified");
    } else requests.push("Hospital Abstract (in lieu of full recs)");
  }
  if (!!request.requestRadiology) {
    if (!!request.requestRadiologyCertified) {
      requests.push("CD of Radiology Images and Films Certified");
    } else requests.push("CD of Radiology Images and Films");
  }
  if (!!request.requestRadiologyBilling) {
    if (!!request.requestRadiologyBillingCertified) {
      requests.push("Radiology Billing Certified");
    } else requests.push("Radiology Billing");
  }
  if (!!request.requestRecords) {
    if (!!request.requestRecordsCertified) {
      requests.push("Records Certified");
    } else requests.push("Records");
  }
  if (!!request.requestAnesthesiaBilling) {
    if (!!request.anesthesiaBillingCertified) {
      requests.push("Anesthesia Billing Certified");
    } else requests.push("Anesthesia Billing");
  }
  return requests;
};
