import api from "./api";
import { PaginatedApi } from "@tyrannosaurustech/ui/build/src/organisms/Table/async";
import { API_URL } from "./api";
import { requestSortMapping } from "../../constants/requestSortMapping";
import { useGet } from "./useGet";
const requestApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getRequests: builder.query<any, any>({
      query: (query) => `requests?${typeof query === "string" ? query : ""}`,
      providesTags: (result) => {
        return result?.requests
          ? [
              ...result.requests?.map(({ id }) => ({ type: "Requests", id })),
              { type: "Requests", id: "LIST" },
            ]
          : [{ type: "Requests", id: "LIST" }];
      },
    }),
    createRequest: builder.mutation({
      query: (request) => ({
        url: `requests`,
        method: `POST`,
        body: request,
      }),
      invalidatesTags: [{ type: "Requests", id: "LIST" }],
    }),
    updateRequest: builder.mutation({
      query: (request) => ({
        url: `requests/${request.id}`,
        method: `PUT`,
        body: request,
      }),
      invalidatesTags: (request) => [
        { type: "Requests", id: "LIST" },
        { type: "Requests", id: request.id },
        { type: "MedicalRequests", id: request.id },
      ],
    }),
    deleteRequest: builder.mutation({
      query: (id) => ({
        url: `requests/${id}`,
        method: `DELETE`,
      }),
      invalidatesTags: [{ type: "Requests", id: "LIST" }],
    }),
    getRequest: builder.query({
      query: (id) => `requests/${id}`,
      providesTags: (result) => [{ type: "Requests", id: result?.id }],
    }),
    getMedicalRequest: builder.query({
      query: (id) => `medicalRequest/${id}`,
      providesTags: (result) => [
        { type: "Requests", id: result?.id },
        { type: "MedicalRequests", id: result?.id },
      ],
    }),
    createMedicalRequest: builder.mutation({
      query: (requestType) => ({
        url: `medicalRequest`,
        method: `POST`,
        body: requestType,
      }),
      invalidatesTags: (requestType) => [
        { type: "Requests", id: "LIST" },
        { type: "MedicalRequests", id: requestType.id },
      ],
    }),
    updateMedicalRequest: builder.mutation({
      query: (requestType) => ({
        url: `medicalRequest/${requestType.id}`,
        method: `PUT`,
        body: requestType,
      }),
      invalidatesTags: (requestType) => [
        { type: "Requests", id: "LIST" },
        { type: "MedicalRequests", id: requestType.id },
      ],
    }),
    deleteMedicalRequest: builder.mutation({
      query: ({ id, requestType }) => ({
        url: `medicalRequest/${id}`,
        method: `DELETE`,
        body: requestType,
      }),
      invalidatesTags: (requestType) => [
        { type: "Requests", id: "LIST" },
        { type: "Requests", id: requestType.id },
      ],
    }),
    searchRequest: builder.query<any, any>({
      query: (query = "") => `request-search?${query}`,
      providesTags: (result) => {
        return result?.requests
          ? [
              ...result.requests?.map(({ id }) => ({ type: "Requests", id })),
              { type: "Requests", id: "LIST" },
            ]
          : [{ type: "Requests", id: "LIST" }];
      },
    }),
  }),
});

export const useGetRequests = (filter): PaginatedApi<any> => {
  const get = useGet();
  return async (page, pageSize, sort, searchString) => {
    const take = pageSize || 100;
    const skip = page * pageSize || 0;
    const selectedSort = sort && Array.isArray(sort) ? sort[0] : {};
    const orderBy = requestSortMapping[selectedSort?.id]
      ? requestSortMapping[selectedSort?.id]
      : selectedSort?.id;
    const desc = selectedSort?.desc ? "DESC" : "ASC";
    const response = await get(
      `${API_URL}/request-search?${filter}&searchParam=${searchString}&take=${take}&skip=${skip}${
        orderBy ? `&orderBy=${orderBy}&desc=${desc}` : ""
      }`
    );
    const json = await response.json();
    const { requests, total } = json;
    return {
      results: requests,
      pageCount: Math.ceil(total / pageSize),
      total,
    };
  };
};

export const {
  useGetRequestsQuery,
  useDeleteRequestMutation,
  useGetRequestQuery,
  useGetMedicalRequestQuery,
  useCreateMedicalRequestMutation,
  useDeleteMedicalRequestMutation,
  useUpdateMedicalRequestMutation,
  useCreateRequestMutation,
  useUpdateRequestMutation,
  useSearchRequestQuery,
} = requestApi;
