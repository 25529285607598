import { ParagraphM } from "../atoms/Typography";
import { Theme } from "../atoms/theme";
import { ModalWithOneButton } from "./Modal";

interface CertificationModalProps {
  onClick?: () => void;
  checked?: boolean;
  disabled?: boolean;
  checkboxOnChange?: () => void;
  loader?: boolean;
}

export const CertificationModal = ({
  checked = false,
  onClick = () => {},
  checkboxOnChange = () => {},
  loader = false,
  disabled = false,
}: CertificationModalProps) => {
  return (
    <ModalWithOneButton
      icon={false}
      title="Certified Request"
      buttonText="Ok"
      onClick={() => onClick()}
      checkboxChecked={checked}
      checkboxOnChange={() => checkboxOnChange()}
      checkboxLabel="Do not show this message again"
      disabled={disabled}
      content=""
      loader={loader}
      customMessage={
        <div style={{ margin: "0 0 1.5rem 0" }}>
          <ParagraphM
            color={Theme.colors.danger_000}
            style={{ display: "inline" }}
          >
            {"Note: "}
          </ParagraphM>
          <ParagraphM style={{ display: "inline" }}>
            {
              "Certified records and billing may take longer to obtain and have additional provider fees."
            }
          </ParagraphM>
        </div>
      }
    />
  );
};
