import React from "react";
import styled from "styled-components";
import { TrashIcon } from "@heroicons/react/outline";

import { TitleS, SubtitlesM } from "../atoms/Typography";
import { Button, ButtonOutline } from "../atoms/Button";
import { Theme } from "../atoms/theme";

interface ModalProps {
  title: string;
  files: string[];
  textColor?: string;
  buttonColor?: string;
  hoverColor?: string;
  icon?: boolean;
  onCloseModal?: () => void;
  initialValues?: [
    {
      name: string;
      id: string;
    }
  ];
  onFileDelete?: (file: any) => any;
  onInitialFileDelete?: (existingFile?: { id: string; name: string }) => any;
  onFileDownload?: (existingFile?: { id: string }) => any;
  canDelete?: boolean;
}

const OverLay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(73, 80, 87, 0.5);
  width: 100%;
  height: 100%;
  z-index: 99;
`;
const CenterModal = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
const ModalWrapper = styled.div`
  width: 34.5rem;
  border-radius: ${({ theme }) => theme.radii.corner_radius_l};
  padding: 1.5rem;
  background: ${({ theme }) => theme.colors.neutrals_500};
  box-shadow: ${({ theme }) => theme.shadows.dropshadow_l};
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 50%;
`;

const FilesWrapper = styled.div`
  width: 100%;
  padding: 0.5rem;
  overflow: auto;
`;

const CancelButtonWrapper = styled.div`
  display: flex;
  margin-top: auto;
  height: 2.875rem;
  width: 100%;
`;

const FileUploads = styled.div`
  display: flex;
  height: 2.75rem;
  margin: 0.75rem 0;
  justify-content: space-between;
`;

const FileName = styled(SubtitlesM)`
  cursor: pointer;
`;

const DeleteButtonWrapper = styled.div`
  display: flex;
  margin-left: 0.15rem;
  margin-top: auto;
  height: 2.875rem;
`;
export const FileUploadsModal = ({
  title,
  files,
  textColor = Theme.colors.primaries_000,
  buttonColor = Theme.colors.primaries_100,
  hoverColor = Theme.colors.primaries_neg100,
  onCloseModal = () => {},
  onFileDelete = () => {},
  onFileDownload = () => {},
  onInitialFileDelete = () => {},
  initialValues,
  canDelete = false,
}: ModalProps) => {
  return (
    <OverLay>
      <CenterModal>
        <ModalWrapper>
          <TitleS style={{ margin: "0 0 1.5rem 0" }}>{title}</TitleS>
          <FilesWrapper>
            {!!files.length &&
              files.map((file, idx) => (
                <FileUploads>
                  <FileName color={textColor}>{file}</FileName>
                  {canDelete && (
                    <DeleteButtonWrapper>
                      <Button
                        icon={true}
                        color={textColor}
                        hoverColor={hoverColor}
                        noText={false}
                        onClick={() => onFileDelete(idx)}
                        width={"2.75rem"}
                        style={{ margin: "0" }}
                        img={
                          <TrashIcon
                            width={"1.5rem"}
                            color={Theme.colors.neutrals_500}
                            id="icon"
                          />
                        }
                      />
                    </DeleteButtonWrapper>
                  )}
                </FileUploads>
              ))}

            {!!initialValues?.length &&
              initialValues?.map((file) => (
                <FileUploads>
                  <FileName
                    color={textColor}
                    onClick={() => onFileDownload(file)}
                  >
                    {file.name ? file.name : file}
                  </FileName>
                  {canDelete && (
                    <DeleteButtonWrapper>
                      <Button
                        icon={true}
                        color={textColor}
                        hoverColor={hoverColor}
                        noText={false}
                        onClick={() => onInitialFileDelete(file)}
                        width={"2.75rem"}
                        style={{ margin: "0" }}
                        img={
                          <TrashIcon
                            width={"1.5rem"}
                            color={Theme.colors.neutrals_500}
                            id="icon"
                          />
                        }
                      />
                    </DeleteButtonWrapper>
                  )}
                </FileUploads>
              ))}
          </FilesWrapper>
          <CancelButtonWrapper>
            <ButtonOutline
              icon={false}
              buttonText={"Close"}
              color={buttonColor}
              hoverColor={hoverColor}
              noText={true}
              onClick={onCloseModal}
              width={"100%"}
              style={{ margin: "0" }}
            />
          </CancelButtonWrapper>
        </ModalWrapper>
      </CenterModal>
    </OverLay>
  );
};
