//Changes made in this file may need to be duplicated to ./EditMedicalProviderFromRequest.tsx

import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ContentWrapper } from "../../../components/ContentWrapper";
import { Breadcrumb } from "../../../components/molecules/Breadcrumb";
import { TitleM } from "../../../components/atoms/Typography";
import Form from "../../../components/organisms/SectionedForm";
import { useForm } from "react-hook-form";
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  SaveIcon,
  TrashIcon,
  XCircleIcon,
} from "@heroicons/react/outline";

import { Theme } from "../../../components/atoms/theme";
import { Button, ButtonOutline } from "../../../components/atoms/Button";
import {
  useUpdateProviderMutation,
  useGetProviderQuery,
  useGetProviderRequestsQuery,
  useGetProviderConnectionsQuery,
  useUpdateProviderConnectionMutation,
} from "../../../redux/reduxQuery/medicalProviderApi";
import { BreadcrumbContainer } from "./style";
import {
  sendInformationByForm,
  medicalProviderForm,
  medicalProviderNotesForm,
  medicalProviderConnections,
  medicalProviderVettingNotesForm,
} from "../../../common/forms/medicalProviderV2";
import {
  ModalWithOneButton,
  ModalWithTwoButtons,
} from "../../../components/molecules/Modal";
import useToast from "../../../hooks/useToast";
import { UploadFVButton } from "../../../common/fv";
import { ProviderRequestModal } from "../../../components/molecules/ProviderRequestModal";
import { AmbulanceIcon } from "../../../components/assets/AmbulanceIcon";
import styled from "styled-components";
import {
  DeleteMedicalProvider,
  VerifyMedicalProvider,
} from "./EditMedicalProviderModals";
const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 2rem 0;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export default function EditMedicalProvider() {
  const { id } = useParams();
  const [alreadyVerifiedModal, setAlreadyVerifiedModal] = useState(false);
  const [updateProvider] = useUpdateProviderMutation();
  const [updateProviderConnection] = useUpdateProviderConnectionMutation();
  const selectedNewConnectionProviderId = id;
  const {
    data: selectedProvider,
    isLoading,
    refetch,
  } = useGetProviderQuery(selectedNewConnectionProviderId, {
    refetchOnMountOrArgChange: true,
  });

  const { data: requests, isLoading: isProviderRequestLoading } =
    useGetProviderRequestsQuery(selectedNewConnectionProviderId, {
      refetchOnMountOrArgChange: true,
    });

  const {
    data: selectedProviderConnections,
    isLoading: isProviderConnectionsLoading,
    refetch: refetchProviderConnections,
  } = useGetProviderConnectionsQuery(selectedNewConnectionProviderId, {
    refetchOnMountOrArgChange: true,
  });
  const [showDeleteProviderModal, setShowDeleteProviderModal] = useState(false);
  const [showVerifyProviderModal, setShowVerifyProviderModal] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const toast = useToast();
  const [initValue, setInitValue] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [openRequestModal, setOpenRequestModal] = useState(false);
  const [openConnectionRequestModal, setOpenConnectionRequestModal] =
    useState(false);
  const [connectionRequests] = useState([]);
  let navigate = useNavigate();
  const crumbLinks = {
    crumbs: [
      {
        text: "Medical Providers",
        onClick: () => validForm(),
      },
      {
        text: selectedProvider?.name,
      },
    ],
    buttons: [],
  };

  const refetchFunction = () => {
    refetchProviderConnections();
  };

  const form = useForm();
  const validForm = () => {
    const keys1 = Object.keys(initValue);
    for (let key of keys1) {
      let val = form.getValues(key);
      if (initValue[key] === null && val !== initValue[key]) {
        setOpenModal(true);
        return;
      } else if (initValue[key] !== null) {
        if (typeof val === "object" && initValue[key] !== val?.value) {
          setOpenModal(true);
          return;
        } else if (typeof val === "string" && initValue[key] !== val) {
          setOpenModal(true);
          return;
        }
      }
    }
    navigate("/admin/medical-providers");
  };

  const fetchMedicalProvider = useCallback(() => {
    selectedProvider &&
      Object.keys(selectedProvider).map((item) =>
        form.setValue(item, selectedProvider?.[item])
      );
    setInitValue(selectedProvider);
    selectedProviderConnections &&
      form.setValue("connections", selectedProviderConnections);
  }, [selectedProvider, selectedProviderConnections, form]);
  useEffect(() => {
    fetchMedicalProvider();
  }, [fetchMedicalProvider]);

  const onSubmit = form.handleSubmit((data) => {
    const medicalProviderConnectionChanges =
      data["medicalProviderConnectionChanges"];
    if (medicalProviderConnectionChanges) {
      Object.keys(medicalProviderConnectionChanges).forEach((key) => {
        const connection = medicalProviderConnectionChanges[key];
        if (connection?.senderNotes) {
          updateProvider({
            id: key,
            notes: connection?.senderNotes,
          });
        }

        if (connection?.connectionNotes) {
          updateProviderConnection({
            toId: selectedProvider.id,
            fromId: key,
            connectionNotes: connection?.connectionNotes,
          });
        }
      });
    }
    setIsProcessing(true);
    updateProvider({
      ...data,
      connections: [],
      state: data.state,
      type: data.type,
    })
      .then((_res) => {
        toast.success("Changes have been saved successfully");
        navigate(-1);
      })
      .finally(() => {
        setIsProcessing(false);
      });
  });

  return (
    <Fragment>
      {alreadyVerifiedModal && (
        <ModalWithOneButton
          icon={false}
          title="Provider Already verified"
          content="This provider has already been verified"
          buttonText="Ok"
          onClick={() => {
            setAlreadyVerifiedModal(false);
          }}
        />
      )}
      {openRequestModal && (
        <ProviderRequestModal
          icon={false}
          title="Unable to Delete Medical Provider"
          content="This provider has associated requests. Please dissociate those requests from this provider."
          buttonText="Cancel"
          requests={requests}
          isLoading={isProviderRequestLoading}
          onClick={() => {
            setOpenRequestModal(false);
          }}
        />
      )}

      {openConnectionRequestModal && (
        <ProviderRequestModal
          icon={false}
          title="Unable to Delete Provider"
          content="This provider has associated requests. Please dissociate those requests from this provider."
          buttonText="Cancel"
          requests={connectionRequests}
          isLoading={isProviderRequestLoading}
          onClick={() => {
            setOpenConnectionRequestModal(false);
          }}
        />
      )}

      <DeleteMedicalProvider
        id={selectedProvider?.id}
        name={selectedProvider?.name}
        open={showDeleteProviderModal}
        setOpen={setShowDeleteProviderModal}
      />

      <VerifyMedicalProvider
        provider={selectedProvider}
        refetch={refetch}
        updateProvider={updateProvider}
        open={showVerifyProviderModal}
        setOpen={setShowVerifyProviderModal}
      />

      {openModal && (
        <ModalWithTwoButtons
          title="Unsaved Changes"
          content="Your changes have not been saved. If you leave you will lose all your progress. Are you sure you want to leave without saving?"
          button1Text="Leave"
          button2Text="Stay"
          onClick1={() => navigate("/admin/medical-providers")}
          onClick2={() => setOpenModal(false)}
        />
      )}
      <ContentWrapper height={"100%"}>
        <div style={{ marginBottom: "50px" }}>
          <BreadcrumbContainer>
            <Breadcrumb
              crumbs={crumbLinks.crumbs}
              buttons={crumbLinks.buttons}
              icon={<AmbulanceIcon width={18} />}
            />
          </BreadcrumbContainer>
          <TitleContainer>
            <TitleM>{selectedProvider?.name}</TitleM>
            <ButtonContainer>
              <UploadFVButton
                type="provider"
                record={selectedProvider}
                modalTitle="Upload Provider to FileVine"
                modalContent={`Are you sure you want to upload ${selectedProvider?.name}'s information to FileVine?`}
                refetch={refetch}
              />
              {selectedProvider?.status === "verified" ? (
                <Button
                  icon={true}
                  width={"8.25rem"}
                  color={Theme.colors.success_000}
                  hoverColor={Theme.colors.success_neg100}
                  noText={true}
                  buttonText="Verified"
                  onClick={() => setAlreadyVerifiedModal(true)}
                  img={
                    <CheckCircleIcon
                      width={"1rem"}
                      color={Theme.colors.neutrals_500}
                    />
                  }
                />
              ) : selectedProvider?.status === "unverified" ? (
                <Button
                  icon={true}
                  width={"8.25rem"}
                  color={Theme.colors.danger_000}
                  noText={true}
                  hoverColor={Theme.colors.danger_neg100}
                  onClick={() => setShowVerifyProviderModal(true)}
                  buttonText="Unverified"
                  img={
                    <XCircleIcon
                      width={"1rem"}
                      color={Theme.colors.neutrals_500}
                    />
                  }
                />
              ) : (
                <Button
                  icon={true}
                  width={"8.25rem"}
                  color={Theme.colors.warning_000}
                  noText={true}
                  hoverColor={Theme.colors.warning_neg100}
                  buttonText="Outdated"
                  onClick={() => setShowVerifyProviderModal(true)}
                  img={
                    <ExclamationCircleIcon
                      width={"1rem"}
                      color={Theme.colors.neutrals_500}
                    />
                  }
                />
              )}

              <Button
                icon={true}
                color={Theme.colors.primaries_000}
                hoverColor={Theme.colors.primaries_neg100}
                noText={true}
                buttonText="save changes"
                img={
                  <SaveIcon
                    width={"1.25rem"}
                    color={Theme.colors.neutrals_500}
                  />
                }
                loader={isProcessing}
                onClick={() => onSubmit()}
              />

              <ButtonOutline
                icon={true}
                color={Theme.colors.primaries_000}
                hoverColor={Theme.colors.primaries_neg100}
                noText={false}
                img={
                  <TrashIcon
                    width={"1.25rem"}
                    color={Theme.colors.primaries_000}
                  />
                }
                onClick={() => {
                  if (requests?.length <= 0) {
                    setShowDeleteProviderModal(true);
                  }

                  if (requests?.length > 0) {
                    setOpenRequestModal(true);
                  }
                }}
              />
            </ButtonContainer>
          </TitleContainer>
          <Form
            {...form}
            onSubmit={onSubmit}
            initialValue={selectedProvider}
            formInputs={[
              medicalProviderNotesForm,
              sendInformationByForm,
              medicalProviderForm,
              medicalProviderVettingNotesForm,
              medicalProviderConnections,
            ]}
            numberOfColumns={2}
            submitButtonText={"Submit Request"}
            customSubmit={true}
            cancelAction={undefined}
            loading={isLoading || isProviderConnectionsLoading}
            style={{ marginBottom: "50px" }}
            refetchFunction={refetchFunction}
          />
        </div>
      </ContentWrapper>
    </Fragment>
  );
}
