import React, { Fragment, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { EyeIcon } from "@heroicons/react/outline";
import { useNavigate, useParams, Link } from "react-router-dom";
import { CustomTable } from "../../components/organisms/ShimmerTable";
import { Badge } from "../../components/atoms/Badge";
import { TitleM } from "../../components/atoms/Typography";
import { Theme } from "../../components/atoms/theme";
import { ContentWrapper } from "../../components/ContentWrapper";
import { useSearchRequestQuery } from "../../redux/reduxQuery/requestApi";
import { Tooltip } from "../../components/atoms/Tooltip";
import { useGetFirmQuery } from "../../redux/reduxQuery/firmApi";
import { statusColor } from "../../utils/statusColor";
import { statusLabel } from "../../utils/statusLabel";
import { ParagraphS } from "../../components/atoms/Typography";
import { Button } from "../../components/atoms/Button";
import { getRequestsFromRequests } from "../../common/request";
import { filtersRequest } from "../../utils/filtersRequest";
import { filterDuplicateSelectOptions } from "../../utils/filterDuplicateSelectOptions";
const RequestList = ({ requests }) => (
  <UL>
    {requests.map((request) => (
      <li>{request}</li>
    ))}
  </UL>
);

const UL = styled.ul`
  margin: 0;
  padding-left: 0.938rem;
`;
const Ellipsis = styled.p`
  width: 10rem;
  margin: 0;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

interface selectProps {
  value: string;
  label: string;
}

export default function ClientPortalHome() {
  let navigate = useNavigate();
  let { id } = useParams();
  const { data: firm } = useGetFirmQuery(id);
  const [page, setPage] = useState(0);
  const [filterValues, setFilterValues] = useState({});
  const [dataToFetch, setDataToFetch] = useState("");
  const [requests, setRequests] = useState([]);
  const [patients, setPatients] = useState<selectProps[]>([]);
  const [medicalProvider, setMedicalProvider] = useState<selectProps[]>([]);
  const [totalAmountOfRecords, setTotalAmountOfRecords] = useState(0);
  const { data, isLoading, refetch } = useSearchRequestQuery(dataToFetch);
  const [scrollPosition, setScrollPosition] = useState(0);

  const columns = [
    {
      Header: "Patient",
      accessor: "patient.name",
      filterName: "patientName",
      options: patients,
      includeToolTip: true,
    },
    {
      Header: "Status",
      accessor: "status",
      filterName: "status",
      Cell: (cell) => {
        return (
          <ParagraphS
            color={statusColor[cell.value]}
            style={{ margin: 0, textTransform: "capitalize" }}
          >
            {statusLabel[cell.value]}
          </ParagraphS>
        );
      },
    },
    {
      Header: "Medical Provider",
      accessor: "provider.name",
      filterName: "providerName",
      options: medicalProvider,
      includeToolTip: true,
    },
    {
      Header: "Requests",
      accessor: (row) => getRequestsFromRequests(row).length,
      Cell: (cellProps) => {
        const request = cellProps.row.original;
        const requests = getRequestsFromRequests(request);
        return (
          <Tooltip
            text={<RequestList requests={requests} />}
            position="bottom"
            background={Theme.colors.neutrals_000}
            scrollPosition={scrollPosition}
          >
            <Badge
              icon={true}
              color={Theme.colors.primaries_000}
              hoverColor={Theme.colors.primaries_neg100}
              badgeText={`${requests.length}`}
              img={
                <EyeIcon width={"0.938rem"} color={Theme.colors.neutrals_500} />
              }
            />
          </Tooltip>
        );
      },
    },
    {
      Header: "Service Dates",
      accessor: "serviceDates",
      filterName: "serviceDates",
    },
    {
      Header: "Notes",
      accessor: "requestNotes",
      filterName: "requestNotes",
      Cell: (cell) => <Ellipsis>{cell.value}</Ellipsis>,
      includeToolTip: true,
    },
    {
      Header: "Actions",
      accessor: "actions",
      disableFilters: true,
      Cell: (cell) => (
        <Link to={`/client/${id}/view-request/${cell.row.original.id}`}>
          <Button
            icon={true}
            color={Theme.colors.primaries_000}
            hoverColor={Theme.colors.primaries_neg100}
            noText={false}
            onClick={() =>
              navigate(`/client/${id}/view-request/${cell.row.original.id}`)
            }
            img={
              <EyeIcon width={"0.938rem"} color={Theme.colors.neutrals_500} />
            }
          />
        </Link>
      ),
    },
  ];

  const getFillSelect = useCallback(() => {
    if (data) {
      setRequests(data.requests);
      setTotalAmountOfRecords(data.total);
      const patientOptions = data.requests.map((item) => ({
        value: item.patient?.id,
        label: item.patient?.name,
      }));
      setPatients((prevState) =>
        filterDuplicateSelectOptions([...prevState, ...patientOptions])
      );
      const providerOptions = data.requests?.map((item) => ({
        value: item.provider?.id,
        label: item.provider?.name,
      }));
      setMedicalProvider((prevState) =>
        filterDuplicateSelectOptions([...prevState, ...providerOptions])
      );
    }
  }, [data]);

  useEffect(() => {
    if (isLoading) {
      return;
    } else {
      getFillSelect();
    }
  }, [isLoading, getFillSelect]);

  const handleFilters = useCallback(
    (pageChange) => {
      const qs = filtersRequest(filterValues, pageChange, page);
      setDataToFetch(qs);
      refetch();
    },
    [page, filterValues, refetch]
  );

  useEffect(() => {
    handleFilters(false);
  }, [filterValues, handleFilters]);

  useEffect(() => {
    handleFilters(true);
  }, [page, handleFilters]);

  return (
    <ContentWrapper>
      {firm && (
        <Fragment>
          <TitleM>{firm.name}</TitleM>
          <CustomTable
            tableType="clientrequest"
            columns={columns}
            data={requests}
            isLoading={isLoading}
            setPage={setPage}
            navigation={() => navigate(`/client/${id}/new-request`)}
            keyToSearch="searchParam"
            totalAmountOfRecords={totalAmountOfRecords}
            searchByPage={true}
            setFilterValues={setFilterValues}
            onScroll={setScrollPosition}
            scrollPosition={scrollPosition}
          />
        </Fragment>
      )}
    </ContentWrapper>
  );
}
