import React from "react";
import styled from "styled-components";
import { SheetButtonWrapperProps } from "../../types/atoms/buttonTypes";
import { typeOfProviders } from "../../common/forms/medicalProvider";
import { PhoneIcon } from "../../components/assets/PhoneIcon.js";
import { ParagraphS, SubtitlesBase } from "../atoms/Typography";
import { Theme } from "../atoms/theme";
import { BadgeStatus } from "../atoms/Badge";
const SheetDetailedButtonWrapper = styled.button<SheetButtonWrapperProps>`
  width: 100%;
  padding: 0.75rem 1rem;
  background: none;
  border-radius: ${({ theme }) => theme.spacing.spacing_xs};
  margin-bottom: 0.75rem;
  box-sizing: border-box;
  border: 1px solid ${({ theme }) => theme.colors.gray_100};
  cursor: pointer;
  #text {
    color: ${({ theme }) => theme.colors.neutrals_000};
    text-align: left;
  }
  &:hover,
  :hover #text,
  :hover svg {
    background: ${({ theme }) => theme.colors.primaries_300};
  }
`;

export const SheetDetailedButton = ({
  type,
  name,
  address,
  phone,
  onClick,
  status,
}) => {
  const providerType = typeOfProviders.find((p) => p.value === type);
  return (
    <SheetDetailedButtonWrapper
      onClick={onClick}
      className="border-1 border-neutral-400 border-solid"
    >
      <div className="flex flex-row justify-between w-[100%]">
        <SubtitlesBase color={Theme.colors.primaries_000}>{name}</SubtitlesBase>
        <BadgeStatus status={status} />
      </div>
      <ParagraphS className="text-left leading-6 text-neutral-900">
        {address}
      </ParagraphS>
      <ParagraphS className="text-left leading-6 text-neutral-500">
        {providerType?.label}
      </ParagraphS>
      <div className="flex flex-row items-center w-[100%] align-center gap-1">
        <PhoneIcon />
        <ParagraphS className="text-left leading-6 text-neutral-500">
          {phone}
        </ParagraphS>
      </div>
    </SheetDetailedButtonWrapper>
  );
};

export default SheetDetailedButton;
