interface ToastVariantsTypes {
  SUCCESS: string;
  WARNING: string;
  DANGER: string;
}

export const ToastVariants: ToastVariantsTypes = {
  SUCCESS: "Success",
  WARNING: "Warning",
  DANGER: "Error",
};
