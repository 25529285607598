import React, { Fragment, useState } from "react";
import styled from "styled-components";
import { Theme } from "../atoms/theme";
import { ButtonOutline } from "../atoms/Button";
import { Button } from "@tyrannosaurustech/ui";
import { UseFormReturn } from "react-hook-form";
import { Tabs } from "@tyrannosaurustech/ui";
import { RenderInput } from "./RenderInput";
interface FormWrapperProps {
  filter?: boolean;
}

interface InputContainerProps {
  filter?: boolean;
}

const TabsWrapper = styled.div`
  margin-bottom: 1rem;
`;

const FormWrapper = styled.div<FormWrapperProps>`
  background: #fff;
  width: 100%;
  min-width: ${({ style }) => style?.minWidth};
  height: fit-content;
  border-radius: 0.75rem;
  padding: 0.75rem 1.5rem;
  box-shadow: ${({ style }) => style?.boxShadow || Theme.shadows.dropshadow_m};
  box-sizing: border-box;
  margin-bottom: ${({ style }) => style?.marginBottom || "5.625rem"};
  flex-direction: column;
  height: 100%;
`;

const FormContainer = styled.form`
  display: row;
  width: 100%;
`;

const FormColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const FormColumn = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: ${({ width }: { width?: string }) => width || "100%"};
`;

const InputContainer = styled.div<InputContainerProps>`
  display: flex;
  margin-bottom: 1.25rem;
`;

const DivideLine = styled.div`
  display: flex;
  border: 1px solid ${Theme.colors.neutrals_400};
  border-radius: 9999px;
  width: 100%;
  margin: 1.5rem auto;
  margin-top: 0.25rem;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${({ align }: { align?: string }) =>
    align || "space-evenly"};
  padding: 0;
`;

interface TabbedForm extends UseFormReturn<any, any> {
  formInputs: any;
  numberOfColumns: any;
  cancelAction?: any;
  submitButtonText?: any;
  customSubmit?: boolean;
  onSubmit?: any;
  setValue: any;
  getValues: any;
  control: any;
  style?: any;
  buttonsAlignment?: any;
  onFileDelete?: any;
  filter?: boolean;
  buttonWidth?: string;
  resetFilters?: any;
  initialValue?: any;
  refetchFunction?: any;
  loading?: any;
  activeTab?: string;
  setActiveTab?: any;
  tabHeader?: any[];
}

function Form({
  formInputs,
  numberOfColumns,
  cancelAction,
  submitButtonText,
  customSubmit,
  register,
  onSubmit,
  setValue,
  getValues,
  control,
  style = {},
  watch,
  buttonsAlignment = "start",
  onFileDelete,
  filter,
  reset,
  buttonWidth = "100%",
  resetFilters,
  setFocus,
  loading,
  initialValue,
  refetchFunction,
  activeTab,
  setActiveTab,
  tabHeader = [],
  ...rest
}: TabbedForm) {
  const [isProcessing, setIsProcessing] = useState(false);

  const errors = rest.formState.errors;
  const handleSubmit = async function (this: any) {
    setIsProcessing(true);
    await onSubmit.apply(this, arguments);
    setIsProcessing(false);
  };

  const FormInput: React.FC<any> = ({ input, width }) => {
    const style = {
      ...(input.marginBottom && { marginBottom: input.marginBottom }),
      ...(width && { width: width }),
    };

    return (
      <InputContainer key={`form-${input.id}`} filter={filter} style={style}>
        {RenderInput({
          input: input,
          register: register,
          errors: errors,
          setValue: setValue,
          getValues: getValues,
          control: control,
          onFileDelete: onFileDelete,
          watch: watch,
          loading: loading,
          initialValue: initialValue,
          refetchFunction: refetchFunction,
        })}
      </InputContainer>
    );
  };

  return (
    <>
      <FormWrapper style={style} filter={filter}>
        <TabsWrapper>
          <Tabs
            base="primary"
            activeTab={activeTab || ""}
            items={tabHeader}
            onClick={(tab) => {
              setActiveTab(tab);
            }}
          />
        </TabsWrapper>
        <FormContainer onSubmit={handleSubmit}>
          {formInputs.map((section, idx) => {
            return (
              <>
                <FormColumnContainer>
                  <FormColumn>
                    {section?.inputs?.map((input) => {
                      return (
                        <>
                          <FormInput
                            width={`${100 / section?.numberOfColumns}%`}
                            input={input}
                          />
                          {input?.divideLine && <DivideLine />}
                        </>
                      );
                    })}
                    {!customSubmit && (
                      <ButtonsWrapper>
                        <ButtonOutline
                          buttonText={"Cancel"}
                          icon={false}
                          color={Theme.colors.primaries_000}
                          hoverColor={Theme.colors.primaries_neg100}
                          noText={true}
                          width={buttonWidth}
                          onClick={cancelAction}
                          loader={isProcessing}
                        />
                        <Button
                          color={Theme.colors.primaries_000}
                          type={"submit"}
                        />
                      </ButtonsWrapper>
                    )}
                  </FormColumn>
                </FormColumnContainer>
                {idx !== formInputs.length - 1 && <DivideLine />}
              </>
            );
          })}
        </FormContainer>
      </FormWrapper>
    </>
  );
}

export default Form;
