import AmbulanceIcon from "../../components/assets/Ambulance.svg";
import AnesthesiaIcon from "../../components/assets/Anesthesia.svg";
import BoneIcon from "../../components/assets/Bone.svg";
import BrainIcon from "../../components/assets/Brain.svg";
import ColumnIcon from "../../components/assets/Column.svg";
import DentalIcon from "../../components/assets/Dental.svg";
import DotsIcon from "../../components/assets/Dots.svg";
import ERIcon from "../../components/assets/ER.svg";
import EyeIcon from "../../components/assets/Eye2.svg";
import HospitalIcon from "../../components/assets/Hospital.svg";
import IdentificationIcon from "../../components/assets/Identification.svg";
import LightBulbIcon from "../../components/assets/LightBulb.svg";
import LightningBoltIcon from "../../components/assets/LightningBolt.svg";
import MonitorIcon from "../../components/assets/Monitor.svg";
import ScaleIcon from "../../components/assets/Scale.svg";

export const typeOfProviders = [
  { label: "Hospital", value: "hospital", icon: HospitalIcon },
  { label: "Ems", value: "ems", icon: AmbulanceIcon },
  { label: "Radiology", value: "radiology", icon: LightningBoltIcon },
  { label: "ER Physician", value: "er_physician", icon: ERIcon },
  { label: "Anesthesia", value: "anesthesia", icon: AnesthesiaIcon },
  { label: "Ambulance", value: "ambulance", icon: AmbulanceIcon },
  { label: "Doctor/Clinic", value: "Doctor/Clinic", icon: IdentificationIcon },
  {
    label: "Third Party Processor",
    value: "third_party_procesor",
    icon: DotsIcon,
  },
  { label: "Employer", value: "Employer", icon: DotsIcon },
  {
    label: "Ortho / Physical Therapy",
    value: "Ortho / Physical Therapy",
    icon: BoneIcon,
  },
  { label: "Mental Health", value: "Mental Health", icon: ScaleIcon },
  { label: "Chiropractor", value: "Chiropractor", icon: ColumnIcon },
  { label: "Dentist / Oral", value: "Dentist / Oral", icon: DentalIcon },
  { label: "Eye Doctor", value: "Eye Doctor", icon: EyeIcon },
  { label: "Neurology", value: "Neurology", icon: BrainIcon },
  { label: "Medical Equipment", value: "Medical Equipment", icon: MonitorIcon },
  {
    label: "Lifelight / Air Transport",
    value: "Lifelight / Air Transport",
    icon: LightBulbIcon,
  },
];

const sendItemsToOptions = [
  { label: "Fax", value: "Fax" },
  { label: "Chartswap", value: "Chartswap" },
  { label: "Email", value: "Email" },
  { label: "Ciox", value: "Ciox" },
  { label: "Mail", value: "Mail" },
  { label: "MRO", value: "MRO" },
  { label: "Other (see notes)", value: "Other (see notes)" },
  { label: "N/A - Do not send", value: "N/A - Do not send" },
];

export const sendInformationByForm = [
  [
    {
      id: "sendBillsBy",
      label: "Send Bills By",
      name: "sendBillsBy",
      required: false,
      copy: true,
      addNew: false,
      icon: false,
      conditional: true,
      input: "sendBillsTo",
      options: sendItemsToOptions.sort((a, b) =>
        a.label.localeCompare(b.label)
      ),
    },
  ],
  [
    {
      id: "sendRecordsBy",
      label: "Send Records By",
      required: false,
      copy: true,
      conditional: true,
      input: "sendRecordsTo",
      options: sendItemsToOptions.sort((a, b) =>
        a.label.localeCompare(b.label)
      ),
    },
  ],
];

export const medicalProviderForm = [
  [
    {
      id: "name",
      label: "Provider Name",
      required: true,
      copy: true,
      addNew: false,
      icon: false,
    },
    {
      id: "aka",
      label: "AKA (also known as)",
      required: false,
      copy: true,
      addNew: false,
      icon: false,
    },
    {
      id: "type",
      label: "Type of provider",
      required: true,
      copy: true,
      addNew: false,
      icon: false,
      select: true,
      options: typeOfProviders.sort((a, b) => a.label.localeCompare(b.label)),
    },
    {
      id: "phone",
      label: "Phone",
      required: true,
      copy: true,
      addNew: false,
      icon: false,
      type: "phoneNumber",
    },
    {
      id: "fax",
      label: "Fax",
      required: false,
      copy: true,
      addNew: false,
      icon: false,
      type: "phoneNumber",
    },
    {
      id: "email",
      label: "Email",
      required: false,
      copy: true,
      addNew: false,
      icon: false,
      type: "email",
    },
  ],
  [
    {
      id: "url",
      label: "Website",
      required: false,
      copy: true,
      addNew: false,
      icon: false,
    },
    {
      id: "address",
      label: "Address",
      required: true,
      copy: true,
      addNew: false,
      icon: false,
    },
    {
      id: "city",
      label: "City",
      required: true,
      copy: true,
      addNew: false,
      icon: false,
    },
    {
      id: "state",
      label: "State",
      required: true,
      copy: true,
      addNew: false,
      icon: false,
      type: "state",
    },
    {
      id: "zip",
      label: "Zip Code",
      required: true,
      copy: true,
      addNew: false,
      icon: false,
      type: "zip",
    },
  ],
];
