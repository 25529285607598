import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { SaveIcon, TrashIcon } from "@heroicons/react/outline";

import { ContentWrapper } from "../../../components/ContentWrapper";
import { Breadcrumb } from "../../../components/molecules/Breadcrumb";
import { Button, ButtonOutline } from "../../../components/atoms/Button";
import { TitleM } from "../../../components/atoms/Typography";
import { Theme } from "../../../components/atoms/theme";
import Form from "../../../components/organisms/Form";
import {
  useGetUserQuery,
  useUpdateUserMutation,
} from "../../../redux/reduxQuery/usersApi";
import { useMutationWrapper } from "../../../common/api";
import { DeleteUserModal } from "./DeleteUserModal";
import { useUser } from "../../../hooks/useLogin";

export default function EditAdmin() {
  let navigate = useNavigate();
  const loggedInUser = useUser();
  const { id } = useParams();
  const [updateUser] = useUpdateUserMutation();
  const [isProcessing, setIsProcessing] = useState(false);
  const { data: user } = useGetUserQuery(id, {
    refetchOnMountOrArgChange: true,
  });
  const [isDeleteModalVisible, showDeleteModal] = useState(false);
  const form = useForm({
    defaultValues: {
      id: user?.id,
      name: user?.name,
      email: user?.email,
    },
  });

  const mutate = useMutationWrapper(
    updateUser,
    `Changes have been saved successfully`,
    () => {
      setIsProcessing(false);
      navigate(-1);
    }
  );
  const onSubmit = form.handleSubmit((data) => {
    setIsProcessing(true);
    return mutate(data);
  });

  const [buttons, setButtons] = useState<JSX.Element[]>([]);

  const crumbLinks = [
    {
      text: "Admin Settings",
      path: "/admin/settings",
    },
    {
      text: user?.name,
    },
  ];

  useEffect(() => {
    if (user) {
      Object.keys(user).map((key: any) => form.setValue(key, user[key]));
    }
  }, [user, form]);

  useEffect(() => {
    if (loggedInUser) {
      if (id !== loggedInUser.id && buttons.length === 0) {
        setButtons([
          <ButtonOutline
            icon={true}
            color={Theme.colors.primaries_000}
            hoverColor={Theme.colors.primaries_neg100}
            noText={false}
            onClick={() => showDeleteModal(true)}
            img={
              <TrashIcon width={"1.25rem"} color={Theme.colors.primaries_000} />
            }
            loader={isProcessing}
          />,
        ]);
      }
    }
  }, [loggedInUser, id, buttons, isProcessing]);

  const formInputs = [
    {
      id: "name",
      label: "Full Name",
      required: true,
      copy: false,
      addNew: false,
      icon: false,
    },
    {
      id: "email",
      label: "Email",
      required: true,
      copy: false,
      addNew: false,
      icon: false,
      type: "email",
      disabled: true,
    },
  ];

  return (
    <ContentWrapper>
      <div style={{ marginBottom: "1.5rem" }} />
      <Breadcrumb
        crumbs={crumbLinks}
        buttons={[
          <Button
            icon={true}
            color={Theme.colors.primaries_000}
            hoverColor={Theme.colors.primaries_neg100}
            noText={true}
            onClick={onSubmit}
            loader={isProcessing}
            buttonText="save changes"
            img={
              <SaveIcon width={"1.25rem"} color={Theme.colors.neutrals_500} />
            }
          />,
          ...buttons,
        ]}
      />
      <TitleM>{user?.name}</TitleM>
      <Form
        {...form}
        onSubmit={onSubmit}
        formInputs={formInputs}
        numberOfColumns={1}
        customSubmit
      />
      <DeleteUserModal
        selectedUser={user}
        isOpen={isDeleteModalVisible}
        onClose={() => showDeleteModal(false)}
      />
    </ContentWrapper>
  );
}
