export const formatRole = (role) => {
  switch (role) {
    case "clientmanager":
      return "Admin";
    case "clientmember":
      return "User";
    default:
      return "";
  }
};
