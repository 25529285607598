import { useAppSelector } from "../../hooks/redux";
import api, { API_URL } from "./api";

const firmApi = api.injectEndpoints({
  endpoints: (build) => ({
    getFirms: build.query<any, void>({
      query: () => `firms`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "Firms", id })),
              { type: "Firms", id: "LIST" },
            ]
          : [{ type: "Firms", id: "LIST" }],
    }),
    createFirm: build.mutation({
      query: (firm) => ({
        url: `firms`,
        method: `POST`,
        body: firm,
      }),
      invalidatesTags: [{ type: "Firms", id: "LIST" }],
    }),
    updateFirm: build.mutation({
      query: (firm) => ({
        url: `firms/${firm?.id}`,
        method: `PUT`,
        body: firm,
      }),
      invalidatesTags: [{ type: "Firms", id: "LIST" }],
    }),
    deleteFirm: build.mutation({
      query: (id) => ({
        url: `firms/${id}`,
        method: `DELETE`,
      }),
      invalidatesTags: [{ type: "Firms", id: "LIST" }],
    }),
    getFirm: build.query({
      query: (id) => `firms/${id}`,
    }),
  }),
});

export const useSearchFirmsByName = () => {
  const token = useAppSelector((s) => s.auth.token);
  return async (name: string) =>
    await fetch(`${API_URL}/firms-search?name=${name}`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }).then((res) => res.json());
};

export const {
  useGetFirmsQuery,
  useGetFirmQuery,
  useCreateFirmMutation,
  useUpdateFirmMutation,
  useDeleteFirmMutation,
} = firmApi;
