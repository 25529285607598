import React, { useState, useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { TrashIcon, SaveIcon } from "@heroicons/react/outline";

import Form from "../../components/organisms/Form";
import { ContentWrapper } from "../../components/ContentWrapper";
import { TitleM } from "../../components/atoms/Typography";
import { Breadcrumb } from "../../components/molecules/Breadcrumb";
import {
  useUpdateUserMutation,
  useGetUserQuery,
} from "../../redux/reduxQuery/usersApi";
import { useMutationWrapper } from "../../common/api";
import { ModalWithTwoButtons } from "../../components/molecules/Modal";
import { Button, ButtonOutline } from "../../components/atoms/Button";
import { Theme } from "../../components/atoms/theme";
import { DeleteUserModal } from "../AdminPortal/Settings/DeleteUserModal";
import { useUser } from "../../hooks/useLogin";

function ViewUser() {
  const navigate = useNavigate();
  const { userId, id } = useParams();
  const loggedInUser = useUser();

  const [updateUser] = useUpdateUserMutation();
  const { data: userData, isFetching } = useGetUserQuery(userId);
  const [isProcessing, setIsProcessing] = useState(false);
  const form = useForm();
  const [unSavedModal, setUnSavedModal] = useState(false);
  const [initValue, setInitValue] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const getUser = useCallback(() => {
    if (userData) {
      Object.keys(userData).forEach((key) => {
        form.setValue(key, userData[key]);
      });
      setInitValue(userData);
    }
  }, [userData, form]);

  useEffect(() => {
    getUser();
  }, [getUser]);

  const mutate = useMutationWrapper(
    updateUser,
    `Changes have been saved successfully`,
    () => {
      setIsProcessing(false);
      navigate(`/client/${id}/users`);
    }
  );

  const onSubmit = form.handleSubmit((data) => {
    setIsProcessing(true);
    return mutate({ ...data, state: data.state.value });
  });

  const [buttons, setButtons] = useState<JSX.Element[]>([]);

  const checkUnsavedChanges = () => {
    const initValKeys = Object.keys(initValue);

    for (let key of initValKeys) {
      let val = form.getValues(key);

      if (key === "state") {
        if (initValue[key] !== val.value) {
          setUnSavedModal(true);
          return;
        }
        navigate(`/client/${id}/users`);
      }
      if (initValue[key] !== val) {
        setUnSavedModal(true);
        return;
      }
    }
    navigate(`/client/${id}/users`);
  };

  const crumbLinks = [
    {
      text: "Users",
      onClick: () => checkUnsavedChanges(),
    },
    {
      text: userData?.name,
    },
  ];

  useEffect(() => {
    if (loggedInUser) {
      if (id !== loggedInUser.id && buttons.length === 0) {
        setButtons([
          <ButtonOutline
            icon={true}
            color={Theme.colors.primaries_000}
            hoverColor={Theme.colors.primaries_neg100}
            noText={false}
            onClick={() => setShowDeleteModal(true)}
            img={
              <TrashIcon width={"1.25rem"} color={Theme.colors.primaries_000} />
            }
          />,
        ]);
      }
    }
  }, [loggedInUser, id, buttons]);

  const formInputs = [
    [
      { id: "name", label: "User Name", required: true, copy: false },
      {
        id: "email",
        label: "User Email",
        required: true,
        copy: false,
        type: "email",
        disabled: true,
      },
      {
        id: "roles",
        radioLabels: ["Admin", "Users"],
        values: ["clientmanager", "clientmember"],
        radioHeader: "Admin/Users",
        radio: true,
        required: true,
      },
    ],
    [
      { id: "address", label: "Address", required: true, copy: false },
      {
        id: "state",
        label: "State",
        required: true,
        copy: false,
        type: "state",
      },
    ],
    [
      { id: "city", label: "City", required: true, copy: false },
      {
        id: "zip",
        label: "Zip Code",
        required: true,
        copy: false,
        type: "zip",
      },
    ],
  ];
  return (
    <>
      {unSavedModal && (
        <ModalWithTwoButtons
          title="unsaved Changes"
          content={`Your changes have not been saved. If you 
          leave, you will lose all your progress. Are you 
          sure you want to exit without saving?`}
          button1Text="exit"
          button2Text="continue editing"
          onClick1={() => navigate(`/client/${id}/users`)}
          onClick2={() => setUnSavedModal(false)}
        />
      )}
      <DeleteUserModal
        isOpen={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        selectedUser={userData}
      />
      <ContentWrapper>
        <div style={{ marginBottom: "1.5rem" }} />
        <Breadcrumb
          crumbs={crumbLinks}
          buttons={[
            <Button
              icon={true}
              color={Theme.colors.primaries_000}
              hoverColor={Theme.colors.primaries_neg100}
              noText={true}
              onClick={onSubmit}
              loader={isProcessing}
              buttonText="save changes"
              img={
                <SaveIcon width={"1.25rem"} color={Theme.colors.neutrals_500} />
              }
            />,
            ...buttons,
          ]}
        />
        <TitleM>{userData?.name}</TitleM>
        <Form
          {...form}
          formInputs={formInputs}
          numberOfColumns={3}
          loading={isFetching}
          customSubmit
        />
      </ContentWrapper>
    </>
  );
}

export default ViewUser;
