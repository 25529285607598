import { states } from "../common/select/constants";
import get from "lodash/get";

export const multipleColumnMappingProviders = {
  city: [
    {
      id: "city",
      label: "City",
      Header: "City",
      type: "text",
      required: false,
      clearButton: true,
      canFilter: true,
    },
    {
      id: "state",
      label: "State",
      Header: "State",
      type: "select",
      required: false,
      clearButton: true,
      canFilter: true,
      options: states,
    },
  ],
  phone: [
    {
      id: "phone",
      label: "Phone",
      Header: "Phone",
      type: "text",
      required: false,
      clearButton: true,
      canFilter: true,
    },
    {
      id: "fax",
      label: "Fax",
      Header: "Fax",
      type: "text",
      required: false,
      clearButton: true,
      canFilter: true,
    },
  ],
};

const getValue = (obj: any, key: string) => {
  const keys = key.split(".");
  return get(obj, keys);
};

export const getColumnFilterValues = (column, values) => {
  let ids = [column.id];
  if (multipleColumnMappingProviders[column.id]) {
    ids = multipleColumnMappingProviders[column.id].map((col) => col.id);
  }
  return ids
    .map((id) => {
      return { id, value: getValue(values, id) };
    })
    .filter(({ value }) => value !== null && value !== undefined);
};
