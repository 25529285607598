import React, { useEffect, useState } from "react";
import { Button, Input, Modal } from "@tyrannosaurustech/ui";
import styled from "styled-components";
import { Theme } from "../atoms/theme";
import { SubtitlesS, ParagraphS } from "../atoms/Typography";
import { TextArea } from "../atoms/TextArea";
import { LightBulbIcon } from "@heroicons/react/outline";
import { ModalTwoButtons } from "../../screens/AdminPortal/MedicalProviders/types";
import { useDeleteProviderConnectionMutation } from "../../redux/reduxQuery/medicalProviderApi";
import useToast from "../../hooks/useToast";
import { BadgeStatus } from "../atoms/Badge";
import { CreateMedicalProviderConnectionModal } from "./CreateMedicalProviderConnectionModal";
import { CardWrapper } from "../atoms/Card";
import { DivideLine } from "../atoms/DivideLine";
import { RequestTypeLabelMapping } from "../atoms/RequestTypes";
import moment from "moment";
import { useForm } from "react-hook-form";
import EditMedicalProviderFromRequest from "../../screens/AdminPortal/MedicalProviders/EditMedicalProviderFromRequest";
const ConnectionLinks = styled.div`
  text-decoration: underline;
  color: ${Theme.colors.primaries_000};
  &:hover {
    color: ${Theme.colors.primaries_neg100};
  }
`;
const WarningText = styled(SubtitlesS)`
  color: ${Theme.colors.amber_000};
  margin-left: 0.5rem;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  width: 2.5rem;
  margin-top: 0.6rem;
`;
interface IProviderConnections {
  data?: any[];
  selectedProvider?: any;
  refetchFunction?: any;
  getValue?: any;
  setValue?: any;
  changeId?: string;
}

export const ConnectionCards = ({
  connection,
  changeId,
  getValue,
  setValue,
  openEditModal,
  requestType = "",
  noWarning = false,
  isConnection = true,
}) => {
  const connectionInit = getValue(changeId)?.[connection.id]?.connectionNotes;
  const [senderNotes, setSenderNotes] = useState(connection?.notes ?? "");
  const [connectionNotes, setConnectionNotes] = useState(
    connectionInit ?? connection?.connectionNotes
  );
  useEffect(() => {
    setSenderNotes(connection?.notes ?? "");
    setConnectionNotes(connection?.connectionNotes);
  }, [connection]);
  const formatDate = (date) => {
    if (date === "Present") return date;
    moment.utc(date).format("L");
  };
  const requestTypesThatShowBilling = [
    "requestBills",
    "requestRadiologyBilling",
    "requestAnesthesiaBilling",
    "requestERBilling",
  ];
  const requestTypesThatShowRecords = [
    "requestRecords",
    "requestHospitalAbstract",
  ];
  const [open, setOpen] = useState(false);
  const showSendBillsBy =
    requestTypesThatShowBilling.includes(connection?.requestType) ||
    requestTypesThatShowBilling.includes(requestType);
  const showSendRecordsBy =
    requestTypesThatShowRecords.includes(connection?.requestType) ||
    requestTypesThatShowRecords.includes(requestType);
  return (
    <CardWrapper>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <EditMedicalProviderFromRequest
            medicalProviderId={connection?.id}
            open={open}
            setOpen={setOpen}
          ></EditMedicalProviderFromRequest>
          <ConnectionLinks
            onClick={() => setOpen(true)}
            className="cursor-pointer"
          >
            {connection?.name}
          </ConnectionLinks>
          <ParagraphS>{connection?.address}</ParagraphS>
          <ParagraphS color={Theme.colors.gray_000}>
            {connection?.type}
          </ParagraphS>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <BadgeStatus status={connection?.status} iconLeft={true} />
        </div>
      </div>
      <DivideLine />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginBottom: "1rem",
        }}
      >
        {showSendBillsBy && (
          <div className="flex gap-2">
            <SubtitlesS>Send Bills By</SubtitlesS>
            <ParagraphS color={Theme.colors.gray_000}>
              {connection?.sendBillsBy} &bull; {connection?.sendBillsTo}
            </ParagraphS>
          </div>
        )}
        {showSendRecordsBy && (
          <div className="flex gap-2">
            <SubtitlesS>Send Records By</SubtitlesS>
            <ParagraphS color={Theme.colors.gray_000}>
              {connection?.sendRecordsBy} &bull; {connection?.sendRecordsTo}
            </ParagraphS>
          </div>
        )}
        {(requestType === "" || requestType === "requestRadiology") && (
          <div className="flex gap-2">
            <SubtitlesS>Send CD Of Radiology Images And Films By</SubtitlesS>
            <ParagraphS color={Theme.colors.gray_000}>
              {connection?.sendCDOfRadiologyFilmsAndImagesBy} &bull;{" "}
              {connection?.sendCDOfRadiologyFilmsAndImagesTo}
            </ParagraphS>
          </div>
        )}
      </div>
      <SubtitlesS>Sender Notes</SubtitlesS>
      <TextArea
        value={senderNotes}
        placeholder="Input Text"
        width="100%"
        lineHeight="15px"
        fullScreen={true}
        fullScreenLabel="Sender Notes"
        onChange={(e) => {
          const value = e.target.value;
          setSenderNotes(value);
          const existingChanges = getValue(changeId);
          const newChanges = {
            ...existingChanges?.[connection.id],
            senderNotes: value,
          };
          setValue(changeId, {
            ...existingChanges,
            [connection.id]: newChanges,
          });
        }}
      />
      {!noWarning && <DivideLine />}
      {!noWarning && (
        <CardWrapper>
          <div style={{ display: "flex" }}>
            <LightBulbIcon width={"1rem"} color={Theme.colors.amber_000} />
            <WarningText>
              {!connection?.requestType &&
              !connection?.validFrom &&
              !connection?.validTo ? (
                <div>
                  <span style={{ textTransform: "none" }}>
                    You can now add relevant notes & dates about this
                    connection.{" "}
                  </span>
                  <span
                    onClick={() => {
                      openEditModal(connection);
                    }}
                    style={{
                      textDecoration: "underline",
                      cursor: "pointer",
                      textTransform: "none",
                    }}
                  >
                    Click Here{" "}
                  </span>
                  <span style={{ textTransform: "none" }}>
                    to Update this information
                  </span>
                </div>
              ) : (
                `Relevant for ${
                  RequestTypeLabelMapping[connection?.requestType] || "Others"
                } between ${
                  !connection?.validFrom
                    ? "Present"
                    : formatDate(connection?.validFrom) || connection?.validFrom
                } and 
              ${
                !connection?.validTo
                  ? "Present"
                  : formatDate(connection?.validTo) || connection?.validTo
              }`
              )}
            </WarningText>
          </div>
        </CardWrapper>
      )}
      {(connectionNotes || changeId === "medicalProviderConnectionChanges") && (
        <>
          <SubtitlesS>Connection Notes</SubtitlesS>
          <TextArea
            value={connectionNotes}
            width="100%"
            lineHeight="15px"
            onChange={(e) => {
              const value = e.target.value;
              setConnectionNotes(value);
              const existingChanges = getValue(changeId);
              const newChanges = {
                ...existingChanges?.[connection.id],
                connectionNotes: value,
              };

              setValue(changeId, {
                ...existingChanges,
                [connection.id]: newChanges,
              });
            }}
          />
        </>
      )}
    </CardWrapper>
  );
};

export const MedicalProviderConnections = ({
  data,
  selectedProvider,
  refetchFunction,
  getValue,
  setValue,
  changeId,
}: IProviderConnections) => {
  const [showAddNewConnectionModal, setShowAddNewConnectionModal] =
    useState(false);
  const [showModalWithTwoButtons, setShowModalWithTwoButtons] =
    useState<ModalTwoButtons | null>();
  const [deleteProviderConnection] = useDeleteProviderConnectionMutation();
  const toast = useToast();
  const [search, setSearch] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [connectedProvider, setConnectedProvider] = useState(null);
  const form = useForm<any>();

  const openEditModal = (connection) => {
    setEditMode(true);
    setConnectedProvider(connection);
    setShowAddNewConnectionModal(true);
  };
  const setConnectionForm = (connection) => {
    const formFields = [
      "validFrom",
      "validTo",
      "requestType",
      "connectionNotes",
    ];
    form.reset();
    if (connection) {
      formFields.forEach((field) => {
        if (!!connection[field]) {
          switch (field) {
            case "requestType":
              form.setValue(field, {
                id: connection[field],
                label: RequestTypeLabelMapping[connection[field]],
              });
              break;
            case "validFrom":
            case "validTo":
              form.setValue(
                field,
                connection[field] === "Present"
                  ? connection[field]
                  : moment.utc(connection[field]).format("MM/DD/YYYY")
              );
              break;
            default:
              form.setValue(field, connection[field]);
              break;
          }
        }
      });
    }
  };
  const deleteConnectionProvider = (connectionId) => {
    const data = {
      id: selectedProvider.id,
      connectionId: connectionId,
    };
    deleteProviderConnection(data).then((res) => {
      toast.success(`The selected connection has been removed.`);
      refetchFunction();
      setShowModalWithTwoButtons(null);
    });
  };

  return (
    <>
      {showModalWithTwoButtons && (
        <Modal
          title={showModalWithTwoButtons?.title || ""}
          icon="ExclamationTriangleIcon"
          bodyText={showModalWithTwoButtons?.content}
          confirmText={showModalWithTwoButtons?.button1Text}
          cancelText={showModalWithTwoButtons?.button2Text}
          open={!!showModalWithTwoButtons}
          variant={showModalWithTwoButtons?.variant}
          setOpen={(shouldOpen) => {
            if (!shouldOpen) {
              setShowModalWithTwoButtons(null);
            }
          }}
          onConfirm={() =>
            deleteConnectionProvider(showModalWithTwoButtons?.providerId)
          }
        />
      )}
      <CreateMedicalProviderConnectionModal
        open={showAddNewConnectionModal}
        provider={selectedProvider}
        setOpen={setShowAddNewConnectionModal}
        refetchFunction={refetchFunction}
        editMode={editMode}
        connectedProvider={connectedProvider}
        setEditMode={setEditMode}
        form={form}
      />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Button
          label={"Add Connection"}
          base="primary"
          icon="PlusSmallIcon"
          type="button"
          onClick={() => {
            form.reset();
            setShowAddNewConnectionModal(true);
          }}
        />

        <Input
          name="Search"
          size="small"
          iconLeft="MagnifyingGlassIcon"
          placeholder="Search By Provider Name"
          className="w-[20rem]"
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        />
      </div>
      {data
        ?.filter((obj) => {
          return search ? obj?.name.includes(search) : true;
        })
        ?.map((connection, i) => {
          return (
            <div
              className="flex gap-2"
              key={`connection-card-${connection?.id ?? ""}-${i}`}
            >
              <ConnectionCards
                connection={connection}
                changeId={changeId}
                getValue={getValue}
                setValue={setValue}
                openEditModal={openEditModal}
              />
              <ButtonsWrapper>
                <Button
                  base="tertiary"
                  icon="PencilIcon"
                  size="small"
                  btnContainerClasses="box-border border-2 border-primary-400 border-solid hover:bg-primary-300 p-[0.3rem]"
                  type="button"
                  onClick={() => {
                    openEditModal(connection);
                    setConnectionForm(connection);
                  }}
                />
                <Button
                  base="tertiary"
                  icon="TrashIcon"
                  size="small"
                  btnContainerClasses="box-border border-2 border-primary-400 border-solid hover:bg-primary-300 p-[0.3rem]"
                  type="button"
                  onClick={() => {
                    setShowModalWithTwoButtons({
                      title: "Remove connection",
                      content: `Are you sure you want to remove this connection? This action can’t be undone.`,
                      button1Text: "Confirm",
                      button2Text: "Cancel",
                      onClick1: "removeConnection",
                      providerId: `${connection?.id}`,
                      variant: "danger",
                    });
                  }}
                />
              </ButtonsWrapper>
            </div>
          );
        })}
    </>
  );
};
