import { useAppSelector } from "../../hooks/redux";
import api, { API_URL } from "./api";

const patientApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getPatients: builder.query<any, string>({
      query: (query = "") => `patients?${query}`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "Patients", id })),
              { type: "Patients", id: "LIST" },
            ]
          : [{ type: "Patients", id: "LIST" }],
    }),
    getPatient: builder.query({
      query: (id) => `patients/${id}`,
      providesTags: (result) => [{ type: "Patients", id: result.id }],
    }),
    deletePatient: builder.mutation({
      query: (id) => ({
        url: `patients/${id}`,
        method: `DELETE`,
      }),
      invalidatesTags: [{ type: "Patients", id: "LIST" }],
    }),
    updatePatient: builder.mutation({
      query: (formData) => ({
        url: `patients/${formData.id}`,
        method: `PUT`,
        body: formData,
      }),
      invalidatesTags: [{ type: "Patients", id: "LIST" }],
    }),
    createPatient: builder.mutation({
      query: (patient) => ({
        url: `patients`,
        method: `POST`,
        body: patient,
      }),
      invalidatesTags: [{ type: "Patients", id: "LIST" }],
    }),
  }),
});

export const useSearchPatientsByName = () => {
  const token = useAppSelector((s) => s.auth.token);
  return async (name: string) =>
    await fetch(`${API_URL}/patients-search?name=${name}`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }).then((res) => res.json());
};

export const {
  useGetPatientsQuery,
  useGetPatientQuery,
  useDeletePatientMutation,
  useUpdatePatientMutation,
  useCreatePatientMutation,
} = patientApi;
