import useToast from "../hooks/useToast";

export const useMutationWrapper = (
  mutation: any,
  successMessage: string | ((data: any) => string),
  onCompletion: (result: any) => any
) => {
  const toast = useToast();
  const mutate = (args) =>
    mutation(args)
      .unwrap()
      .then((res) => {
        toast.success(
          typeof successMessage === "function"
            ? successMessage(args)
            : successMessage
        );
        onCompletion(res);
      })
      .catch((err) => {
        if (err?.data?.message === "Request Entity Too Large") {
          toast.error(
            "The uploaded files are too large, please reduce their size and try again."
          );
        } else {
          toast.error(
            err?.message ||
              err?.data?.message ||
              String(
                JSON.stringify(err) ||
                  "Unable to Process Request, try again later."
              )
          );
        }

        onCompletion(err);
      });
  return mutate;
};
