import { isRejectedWithValue } from '@reduxjs/toolkit'
import { ToastVariants } from '../../../constants/variants'
import { show } from '../../reducers/toast'

const getErrorMessage = action => action?.error?.data?.message || action?.error?.message || "Sorry, we've encountered an error."
export const rtkQueryErrorLogger = (api) => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    api.dispatch(show({ message: getErrorMessage(action), variant: ToastVariants.DANGER, withIcon: true }))
  }

  return next(action)
}