import { useMemo, useState } from "react";
import {
  ParagraphS,
  SubtitlesS,
} from "../../../../components/atoms/Typography";
import { Theme } from "../../../../components/atoms/theme";
import { BadgeStatus } from "../../../../components/atoms/Badge";
import { EyeTrashIconButtons } from "../../../../components/molecules/TableActionButtons";
import { EyeIcon, TrashIcon } from "@heroicons/react/outline";
import { useNavigate } from "react-router-dom";
import { typeOfProviders } from "../../../../common/forms/medicalProvider";
import { formatProviderType } from "../../../../common/format";
import { status } from "../../../../common/select/constants";
import { RenderCell } from "../../../../common/fv";

export const useColumns = (setIsOpen, setOpenRequestModal) => {
  const navigate = useNavigate();
  const [requestsData, setRequestsData] = useState<any[]>([]);
  const [providerData, setProviderData] = useState<any>();
  const [scrollPosition] = useState(0);
  return {
    columns: useMemo(
      () => [
        {
          Header: "Name",
          accessor: "name",
          header: "Name",
          accessorKey: "name",
          filterName: "name",
          includeToolTip: true,
          size: 400,
          cell: (cell) => {
            return (
              <>
                <SubtitlesS>{cell.row.original.name}</SubtitlesS>
                <ParagraphS color={Theme.colors.gray_000}>
                  {cell.row.original.address}
                </ParagraphS>
              </>
            );
          },
        },
        {
          Header: "Address",
          accessor: "address",
          visible: false,
          accessorKey: "address",
          filterName: "Address",
        },
        {
          Header: "City/State",
          accessor: "city",
          header: "City/State",
          accessorKey: "city",
          filterName: "City/State",
          cell: (cell) => {
            return (
              <>
                <SubtitlesS>{cell.row.original.city}</SubtitlesS>
                <ParagraphS color={Theme.colors.gray_000}>
                  {cell.row.original.state}
                </ParagraphS>
              </>
            );
          },
        },
        {
          Header: "Type",
          accessor: "type",
          header: "Type",
          accessorKey: "type",
          filterName: "type",
          options: typeOfProviders,
          cell: (cell) => {
            return (
              <>
                <ParagraphS color={Theme.colors.gray_000}>
                  {formatProviderType(cell.row.original.type)}
                </ParagraphS>
              </>
            );
          },
        },
        {
          Header: "Status",
          accessor: "status",
          header: "Status",
          accessorKey: "status",
          filterName: "status",
          options: status,
          cell: (cell) => {
            return <BadgeStatus status={cell.row.original.status} />;
          },
        },
        {
          Header: "Phone/Fax",
          accessor: "phone",
          header: "Phone/Fax",
          accessorKey: "phone",
          filterName: "Phone/Fax",
          cell: (cell) => {
            return (
              <>
                <SubtitlesS>Phone &bull; {cell.row.original.phone}</SubtitlesS>
                <ParagraphS color={Theme.colors.gray_000}>
                  Fax &bull; {cell.row.original.fax}
                </ParagraphS>
              </>
            );
          },
        },
        {
          Header: "FV Status",
          accessor: "fileVineId",
          header: "FV Status",
          accessorKey: "fileVineId",
          enableSorting: false,
          disableFilters: true,
          accessorFn: ({ fileVineId }) => !!fileVineId,
          cell: (cellProps) => RenderCell(cellProps, scrollPosition),
        },
        {
          Header: "Actions",
          accessor: "id",
          header: "",
          accessorKey: "id",
          disableFilters: true,
          enableSorting: false,
          cell: (cell) => {
            const providerId = cell.row.original.id;
            return (
              <EyeTrashIconButtons
                icons={[EyeIcon, TrashIcon]}
                link={`/admin/medical-providers/${providerId}`}
                onClick1={() =>
                  navigate(`/admin/medical-providers/${providerId}`)
                }
                onClick2={() => {
                  setProviderData(cell.row.original);
                  setRequestsData(cell.row.original?.requests);

                  if (cell.row.original?.requests.length <= 0) {
                    setIsOpen(true);
                  }

                  if (cell.row.original?.requests.length > 0) {
                    setOpenRequestModal(true);
                  }
                }}
              />
            );
          },
        },
      ],
      [navigate, scrollPosition, setIsOpen, setOpenRequestModal]
    ),
    requestsData,
    providerData,
  };
};
