import React, { useState } from "react";
import styled from "styled-components";
import { NavLink, useNavigate } from "react-router-dom";
import {
  OfficeBuildingIcon,
  UserIcon,
  UsersIcon,
  ClipboardListIcon,
  ClipboardIcon,
} from "@heroicons/react/outline";
import { useAuth0 } from "@auth0/auth0-react";

import { Logo } from "../atoms/Logo";
import { FocusOutlineButton } from "../atoms/Button";
import { Sheet } from "../molecules/Sheet";
import { Theme } from "../atoms/theme";
import { InitialsAvatar } from "../atoms/Avatar";
import { ModalWithTwoButtons } from "../molecules/Modal";
import { AmbulanceIcon } from "../assets/AmbulanceIcon";
import { useDispatch } from "react-redux";
import { logout as logoutAction } from "../../redux/reducers/auth";
import { useUser } from "../../hooks/useLogin";
import { Link } from "react-router-dom";

const NavWrapper = styled.div`
  background: ${({ theme }) => theme.colors.neutrals_500};
  box-shadow: ${({ theme }) => theme.shadows.dropshadow_m};
  padding: 1rem;
`;
const Nav = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const FlexStartContentContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
const LinkWrapper = styled(NavLink)`
  text-decoration: none;
  color: black;
`;
const LinkSpacing = styled.div`
  margin-right: 2.5rem;
`;
const DisplayFlex = styled.div`
  display: flex;
`;
const RelativeWrapper = styled.div`
  position: relative;
`;
const AbsoluteWrapper = styled.div`
  position: absolute;
  right: 0;
  margin-top: 1rem;
  z-index: 99;
`;

const adminLinks = [
  {
    icon: ClipboardIcon,
    text: "requests",
    path: "/admin/requests",
  },
  {
    icon: AmbulanceIcon,
    text: "medical Providers",
    path: "/admin/medical-providers",
  },
  {
    icon: OfficeBuildingIcon,
    text: "law firms",
    path: "/admin/law-firms",
  },
  {
    icon: UserIcon,
    text: "patients",
    path: "/admin/patients",
  },
];

const clientLinks = (firmId) => {
  return [
    {
      icon: ClipboardListIcon,
      text: "Requests",
      path: `/client/${firmId}/requests`,
    },
    {
      icon: UsersIcon,
      text: "Users",
      path: `/client/${firmId}/users`,
    },
  ];
};

export const NavBar = () => {
  let navList;
  let dropOptions;
  const [openDrop, setOpenDrop] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { logout: auth0Logout } = useAuth0();
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const user = useUser();

  const logout = () => {
    dispatch(logoutAction());
    auth0Logout({ returnTo: window.location.origin });
  };

  const adminOptions = [
    {
      text: "admins",
      action: () => {
        navigate("/admin/settings");
        setOpenDrop(false);
      },
    },
    {
      text: "logout",
      action: () => {
        setIsOpen(true);
        setOpenDrop(false);
      },
    },
  ];

  const clientOptions = [
    {
      text: "Feedback/Questions",
      action: () => {
        window.open("https://raynacorp.com/contact-us/");
        setOpenDrop(false);
      },
    },
    {
      text: "logout",
      action: () => {
        setIsOpen(true);
        setOpenDrop(false);
      },
    },
  ];

  if (user?.isClientManager()) {
    navList = clientLinks(user?.firmId);
    dropOptions = clientOptions;
  } else {
    navList = adminLinks;
    dropOptions = adminOptions;
  }

  const shouldShowNavBar = !user?.isClientMember();
  return (
    <>
      {isOpen && (
        <ModalWithTwoButtons
          title="log out"
          content={`Are you sure you want to Log Out?`}
          button1Text="confirm"
          button2Text="cancel"
          onClick1={() => {
            setIsOpen(false);
            logout();
          }}
          onClick2={() => setIsOpen(false)}
        />
      )}
      <NavWrapper>
        <Nav>
          <FlexStartContentContainer>
            <Link to="/">
              <Logo width="8.063rem" height="1.5rem" />
            </Link>
            <div style={{ marginRight: "2rem" }} />
            {shouldShowNavBar && (
              <DisplayFlex>
                {navList.map((nav, index) => {
                  return (
                    <LinkWrapper to={nav.path} key={`navbar-${index}`}>
                      {({ isActive = false }) => {
                        return (
                          <DisplayFlex>
                            <FocusOutlineButton
                              icon={true}
                              color={Theme.colors.primaries_000}
                              hoverColor={Theme.colors.primaries_neg100}
                              noText={true}
                              img={<nav.icon width={18} />}
                              buttonText={nav.text}
                              key={`navbar-inner-button-${index}`}
                              active={isActive}
                            />
                            <LinkSpacing />
                          </DisplayFlex>
                        );
                      }}
                    </LinkWrapper>
                  );
                })}
              </DisplayFlex>
            )}
          </FlexStartContentContainer>
          <RelativeWrapper>
            <div
              onClick={() => setOpenDrop(!openDrop)}
              onBlur={(e) => {
                if (!e.relatedTarget?.id?.includes(`option_`))
                  setOpenDrop(false);
              }}
              tabIndex={0}
            >
              <InitialsAvatar
                text={
                  user
                    ? `${user?.name
                        .split(" ")
                        .map((s) => s.charAt(0))
                        .join("")}`
                    : ""
                }
                background={Theme.colors.primaries_000}
                hover={Theme.colors.primaries_neg100}
                size={52}
              />
            </div>
            {openDrop ? (
              <AbsoluteWrapper>
                <Sheet options={dropOptions} />
              </AbsoluteWrapper>
            ) : null}
          </RelativeWrapper>
        </Nav>
      </NavWrapper>
    </>
  );
};
