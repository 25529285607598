import React from "react";
import styled from "styled-components";

interface ContentProps {
  height?: string;
  children: JSX.Element | JSX.Element[];
}
interface WrapperProps {
  height?: string;
}

const Wrapper = styled.div<WrapperProps>`
  width: 100%;
  height: ${({ height }) => height || "90vh"};
  display: flex;
  justify-content: center;
  background: ${({ theme }) => theme.colors.neutrals_400};
`;
const MainSection = styled.div`
  width: 85%;
  min-height: 95vh;
`;

export const ContentWrapper: React.FC<ContentProps> = ({
  children,
  height,
}) => {
  return (
    <Wrapper height={height}>
      <MainSection>{children}</MainSection>
    </Wrapper>
  );
};
