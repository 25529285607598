import { createSlice } from "@reduxjs/toolkit";
const initialState: { [key: string]: IFilter | null } = {};

interface valueLabelPair {
  value: string | number;
  label: string;
}

interface filterObj {
  name: valueLabelPair | null;
}

interface IFilter {
  [key: string]: string | filterObj;
}

const filterSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    setUserFilter(state, action) {
      Object.keys(action.payload).forEach((key) => {
        state[key] = { ...action.payload[key] };
      });
    },
    setPageSize(state, action) {
      Object.keys(action.payload).forEach((key) => {
        if (!state[key]) {
          state[key] = {};
        }
        //Note, making this a var here to avoid TS error

        const stateKey = state[key];
        if (state[key] && typeof state[key] === "object" && stateKey !== null) {
          stateKey.pageSize = action.payload[key]?.pageSize ?? 50;
        }
      });
    },
    setSearchParam(state, action) {
      Object.keys(action.payload).forEach((key) => {
        if (!state[key]) {
          state[key] = {};
        }
        //Note, making this a var here to avoid TS error
        const stateKey = state[key];
        if (state[key] && typeof state[key] === "object" && stateKey !== null) {
          stateKey.searchParam = action.payload[key]?.search;
        }
      });
    },
  },
});

export const { setUserFilter, setPageSize, setSearchParam } =
  filterSlice.actions;
export default filterSlice.reducer;
