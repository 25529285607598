import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { UserAddIcon, TruckIcon } from "@heroicons/react/outline";
import { useMutationWrapper } from "../../common/api";
import { ContentWrapper } from "../../components/ContentWrapper";
import { Breadcrumb } from "../../components/molecules/Breadcrumb";
import { TitleM } from "../../components/atoms/Typography";
import Form from "../../components/organisms/Form";
import searchIcon from "../../components/assets/search.svg";
import { Theme } from "../../components/atoms/theme";
import { useCreateRequestMutation } from "../../redux/reduxQuery/requestApi";
import { useSearchPatientsByName } from "../../redux/reduxQuery/patientApi";
import { useSearchProvidersByName } from "../../redux/reduxQuery/medicalProviderApi";
import { CertificationModal } from "../../components/molecules/CertificationModal";
import { useRequestCertification } from "../../hooks/useRequestCertification";
import { RequestTypeFromCheckboxLabelMapping } from "../../components/atoms/RequestTypes";

export default function CreateRequest() {
  const { pathname, ...location } = useLocation();
  const state = location.state as any;
  let navigate = useNavigate();
  const { id } = useParams();
  const [createRequest] = useCreateRequestMutation();
  const searchProviders = useSearchProvidersByName();
  const searchPatients = useSearchPatientsByName();
  const {
    showCertifiedRequestModal,
    userLoading,
    showModal,
    buttonLoader,
    checked,
    setCheckbox,
    onCloseModal,
    requesterInfoInputsWithTogglefunction,
  } = useRequestCertification();

  const isFromNestedForms = (cameFrom) => {
    return cameFrom === "clientPortalNewProvider" || "clientPortalNewPatient";
  };
  const mutate = useMutationWrapper(
    createRequest,
    () => "Request Was Added Successfully!",
    () => navigate("/")
  );
  const form = useForm();
  const onSubmit = form.handleSubmit((data) => {
    const dataToSubmit = {
      requestedBy: data.requester,
      requestNotes: data.requestNotes,
      serviceDates: data.dateOfService,
      patientId: data.patient.id,
      providerId: data.provider.id,
      requesterEmails: data.requesterEmails,
      status: "new",
      requestBills: !!data["Bills"],
      requestBillsCertified: !!data["Bills Certified"],
      requestRecords: !!data["Records"],
      requestRecordsCertified: !!data["Records Certified"],
      requestRadiology: !!data["CD of Radiology Images and Films"],
      requestRadiologyCertified:
        !!data["CD of Radiology Images and Films Certified"],
      requestERBilling: !!data["ER Physicians Billing"],
      requestERBillingCertified: !!data["ER Physicians Billing Certified"],
      requestRadiologyBilling: !!data["Radiology Billing"],
      requestRadiologyBillingCertified: !!data["Radiology Billing Certified"],
      requestHospitalAbstract:
        !!data["Hospital Abstract (in lieu of full recs)"],
      requestHospitalAbstractCertified:
        !!data["Hospital Abstract (in lieu of full recs) Certified"],
      requestAnesthesiaBilling: !!data["Anesthesia Billing"],
      requestAnesthesiaBillingCertified: !!data["Anesthesia Billing Certified"],
    };
    const medicalRequests = Object.keys(
      RequestTypeFromCheckboxLabelMapping
    ).map((label) => {
      if (data[label]) {
        return {
          requestType: RequestTypeFromCheckboxLabelMapping[label],
          certified: !!data[`${label} Certified`],
          createdByLawFirm: true,
        };
      }
      return null;
    });
    const filteredMedicalRequests = medicalRequests.filter(
      (request) => request
    );
    mutate({ ...dataToSubmit, medicalRequests: filteredMedicalRequests });
  });
  useEffect(() => {
    if (state) {
      if (isFromNestedForms(state.cameFrom)) {
        Object.entries(state.formData)?.map((formValue) => {
          formValue[0] === "patient" && state.isCreatedNewPatient === true
            ? form.setValue("patient", {
                ...state.patient,
                value: state.patient.id,
                label: `${state.patient.name}-${state.patient.ssn}`,
              })
            : formValue[0] === "provider" && state.isCreatedNewProvider === true
            ? form.setValue("provider", {
                ...state.provider,
                value: state.provider.id,
                label: state.provider.name,
              })
            : form.setValue(formValue[0], formValue[1]);
          return formValue;
        });
      }
    }
  }, [state, navigate, form]);
  const crumbLinks = [
    {
      text: "Requests",
      path: `/client/${id}/requests`,
    },
    {
      text: "New Request",
    },
  ];
  const formInputs = [
    [
      {
        id: "patient",
        label: "Patient",
        required: true,
        copy: false,
        select: true,
        addNewInDropDown: true,
        patientDetailed: true,
        asyncConfig: {
          loadOptions: searchPatients,
          getOptionLabel: (p: any) => p.name,
          getOptionValue: (p: any) => p.id,
          placeholder: "Search Patients",
        },
        icon: true,
        img: searchIcon,
        addNewIcon: (
          <UserAddIcon width={"0.938rem"} color={Theme.colors.primaries_000} />
        ),
        addNewAction: () => {
          navigate(`/client/${id}/new-request/new-patient`, {
            state: { formData: form.getValues(), cameFrom: pathname },
          });
        },
      },
      {
        id: "provider",
        label: "Medical Provider",
        required: true,
        copy: false,
        select: true,
        addNewInDropDown: true,
        asyncConfig: {
          loadOptions: searchProviders,
          getOptionLabel: (p: any) => p.name,
          getOptionValue: (p: any) => p.id,
          placeholder: "Search Medical Providers",
        },
        icon: true,
        img: searchIcon,
        detailed: true,
        addNewIcon: (
          <TruckIcon width={"0.938rem"} color={Theme.colors.primaries_000} />
        ),
        addNewAction: () => {
          navigate(`/client/${id}/new-request/new-medical-provider`, {
            state: { formData: form.getValues(), cameFrom: pathname },
          });
        },
      },
      {
        id: "requester",
        label: "Requester Name",
        required: true,
        copy: false,
        notFullLength: true,
      },
      {
        id: "requesterEmails",
        label: "Requester Email/emails",
        required: true,
        copy: true,
        isInnerTooltip: true,
        tooltipText:
          "Enter one or multiple emails. Separate them using the spacebar. This is where you'd like records to be sent.",
        notFullLength: false,
      },
      {
        ...requesterInfoInputsWithTogglefunction,
        checkAndSwitchTitleTooltip: true,
        titleTooltipText:
          "Please mark all of the requests you would like us to request from this provider and please indicate which of them should be certified. Please note that certified requests often have additional provider fees and take longer than uncertified requests.",
        tooltipText:
          "Note: Certified records and billing may take longer to obtain and have additional provider fees.",
      },
    ],
    [
      {
        id: "dateOfService",
        label: "Dates of service",
        required: true,
        copy: false,
        isInnerTooltip: true,
        tooltipText:
          "Please provide a date range unless you only want one specific date of service. We typically suggest you enter the date of accident to present (i.e. 3/2/21 to present).",
      },
      {
        id: "requestNotes",
        label: "Notes",
        copy: false,
        textArea: true,
      },
    ],
  ];
  return (
    <>
      <ContentWrapper height="100%">
        <div style={{ marginBottom: "1.5rem" }} />
        <Breadcrumb crumbs={crumbLinks} />
        <TitleM>New Request</TitleM>
        <Form
          {...form}
          onSubmit={onSubmit}
          formInputs={formInputs}
          numberOfColumns={2}
          submitButtonText={"Submit Request"}
          buttonsAlignment="start"
          loading={userLoading}
          cancelAction={() => {
            navigate(-1);
          }}
        />
      </ContentWrapper>
      {showModal && (
        <CertificationModal
          onClick={async () => await onCloseModal()}
          checked={checked}
          checkboxOnChange={() => setCheckbox(!checked)}
          disabled={!showCertifiedRequestModal}
          loader={buttonLoader}
        />
      )}
    </>
  );
}
