import api from "./api";

const fileVineApi = api.injectEndpoints({
  endpoints: (builder) => ({
    uploadFileVineRecord: builder.mutation({
      query: (config: { id: string; type: "patient" | "provider" }) => ({
        url: `fv/upload`,
        body: config,
        method: "POST",
      }),
      invalidatesTags: [
        { type: "Patients", id: "LIST" },
        { type: "Providers", id: "LIST" },
      ],
    }),
  }),
});

export const { useUploadFileVineRecordMutation } = fileVineApi;
