import React from 'react'

interface LogoProps {
  width: string;
  height: string;
}

export const Logo = ({ width, height }: LogoProps) => {
  return (
    <svg width={width} height={height} viewBox="0 0 267 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_306_517)">
        <path d="M41.811 33.6981C46.3101 33.7563 50.6602 32.0869 53.9654 29.0339C55.6956 27.415 57.0618 25.4467 57.9734 23.2595C58.8849 21.0723 59.3209 18.7163 59.2525 16.3478C59.0449 11.9291 57.1382 7.76137 53.9306 4.71512C50.7229 1.66888 46.4625 -0.0203622 42.0389 0.000185274H7.59648V3.12993C7.59646 3.63528 7.69649 4.13563 7.89079 4.60214C8.0851 5.06864 8.36983 5.49206 8.72857 5.84798C9.08732 6.2039 9.51298 6.48528 9.981 6.67589C10.449 6.8665 10.9502 6.96257 11.4555 6.95856H12.7925V29.2314C9.55907 30.6796 6.6187 32.7085 4.11729 35.2174L0 39.3499L4.92252 44.2724L9.11577 40.1248C10.2564 38.9828 11.5142 37.9643 12.8684 37.0862V49.5748H17.1376C17.8272 49.5555 18.483 49.2719 18.9694 48.7827C19.4557 48.2935 19.7355 47.6361 19.7508 46.9464V34.2299L20.5864 34.0628C22.4553 33.7484 24.3756 34.0525 26.0559 34.9288L55.1656 49.985L57.1255 46.2019C57.4523 45.5687 57.5159 44.832 57.3026 44.152C57.0892 43.4721 56.6161 42.9038 55.986 42.5708L38.8332 33.6981H41.811ZM49.4986 23.7164C48.5809 24.6819 47.4753 25.4496 46.2499 25.9721C45.0246 26.4947 43.7053 26.7611 42.3731 26.755H24.567C22.9522 26.7678 21.3412 26.9152 19.7508 27.1956V6.95856H42.0997C44.688 6.9459 47.1831 7.92417 49.0731 9.69267C50.9631 11.4612 52.1047 13.8859 52.2638 16.4693C52.3194 17.8018 52.1029 19.1316 51.6275 20.3776C51.1521 21.6236 50.4277 22.7596 49.4986 23.7164Z" fill="#AE3EC9"/>
        <path d="M103.312 47.9794L99.8481 39.8816H75.9648L72.4704 47.9794C72.2621 48.4631 71.9164 48.875 71.4762 49.164C71.0359 49.453 70.5205 49.6063 69.9939 49.6051H62.0024L84.169 1.16992H91.5679L113.734 49.6051H105.743C105.224 49.5975 104.719 49.44 104.287 49.1515C103.856 48.8631 103.517 48.456 103.312 47.9794ZM88.1191 12.124L78.6995 33.6221H97.2349L88.1191 12.124Z" fill="#AE3EC9"/>
        <path d="M132.999 29.6719V49.605H123.883V29.6719L105.728 1.16992H114.236C114.7 1.17092 115.157 1.29167 115.562 1.52054C115.966 1.7494 116.305 2.07865 116.545 2.47652L128.411 22.4552L140.292 2.47652C140.532 2.07865 140.87 1.7494 141.275 1.52054C141.679 1.29167 142.136 1.17092 142.601 1.16992H151.094L132.999 29.6719Z" fill="#AE3EC9"/>
        <path d="M196.581 49.6044L168.642 15.5266V46.9456C168.642 47.2988 168.572 47.6484 168.437 47.9747C168.302 48.301 168.104 48.5974 167.854 48.8471C167.604 49.0968 167.308 49.2949 166.982 49.4301C166.655 49.5652 166.306 49.6348 165.953 49.6348H159.526V1.16926H165.603C166.305 1.16111 167.001 1.3112 167.637 1.6084C168.274 1.9056 168.835 2.34229 169.28 2.88606L195.822 35.1863V3.84322C195.822 3.49079 195.892 3.14183 196.027 2.81642C196.162 2.491 196.36 2.19555 196.61 1.94705C196.86 1.69855 197.157 1.50191 197.483 1.36843C197.809 1.23494 198.159 1.16726 198.511 1.16926H204.998V49.6044H196.581Z" fill="#AE3EC9"/>
        <path d="M256.366 47.9794L252.902 39.8816H229.018L225.554 47.9794C225.349 48.4619 225.006 48.8732 224.568 49.1623C224.131 49.4513 223.618 49.6053 223.093 49.605H215.086L237.268 1.16992H244.606L266.788 49.605H258.781C258.264 49.5984 257.76 49.441 257.331 49.1523C256.902 48.8636 256.567 48.456 256.366 47.9794ZM241.173 12.124L231.738 33.6221H250.349L241.173 12.124Z" fill="#AE3EC9"/>
      </g>
      <defs>
        <clipPath id="clip0_306_517">
          <rect width="266.834" height="50" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
}
