import React, { useState } from "react";
import styled from "styled-components";
import { useAsyncDebounce } from "react-table";
import { Tooltip } from "./Tooltip";
import cloudIcon from "../assets/cloud.svg";
import eyeIcon from "../assets/eye.svg";
import eyeIconOff from "../assets/eye-off.svg";
import { ParagraphS, SubtitlesM, CoreSubtitlesM } from "./Typography";
import { Theme } from "./theme";
import { ButtonOutline } from "./Button";
import {
  QuestionMarkCircleIcon,
  ClipboardCopyIcon,
} from "@heroicons/react/outline";
import useToast from "../../hooks/useToast";

interface InputProps {
  required?: boolean;
  error?: boolean;
  noLabel?: boolean;
  icon?: boolean;
  width?: string | number;
  img: (HTMLElement & SVGAElement) | string;
  labelText?: string;
  placeholderText: string;
  errorText?: string;
  filter?: string;
  setFilter?: any;
  setFormValue?: (arg: string) => string;
  getValues?: (arg: string) => string;
  inputId?: string;
  type: string;
  isInnerTooltip?: boolean;
  tooltipText?: string;
  disabled?: boolean;
  copy?: boolean;
  onClick?: () => void;
}

interface CustomInputProps {
  error?: boolean;
  icon?: boolean;
  img?: HTMLElement & SVGAElement;
}

interface LabelProps {
  width?: string | number;
}

interface IconProps {
  setIcon: HTMLElement & SVGAElement;
}

const CopyButtonWrapper = styled.div`
  display: flex;
  margin-left: 0.15rem;
  margin-right: 0.5rem;
  margin-top: auto;
  height: 2.875rem;
`;

const CustomInput = styled.input<CustomInputProps>`
  padding: ${({ theme }) => theme.spacing.spacing_xs};
  border-radius: ${({ theme }) => theme.spacing.spacing_xs};
  border: ${({ error, theme }) =>
    error
      ? `1px solid ${theme.colors.danger_000}`
      : `1px solid ${theme.colors.neutrals_400}`};
  background-color: ${({ theme }) => theme.colors.neutrals_400};
  box-sizing: border-box
  font-size: 14px;
  line-height: 20px;
  ::placeholder {
    color: ${({ theme }) => theme.colors.neutrals_200};
    font-size: 14px;
    line-height: 20px;
  }
  &:focus {
    border: 1px solid ${({ theme }) => theme.colors.primaries_000};
    box-shadow: ${({ theme }) => theme.shadows.dropshadow_accent};
    outline: none;
  }
  ${({ icon, img }) => {
    if (icon) {
      return `background-image: url(${img}); background-repeat: no-repeat; background-position: 98% 50%;`;
    }
  }}
`;
export const Label = styled.label<LabelProps>`
  display: flex;
  position: relative;
  flex-direction: column;
  width: ${({ width }) => width || "18.75rem"};
`;
const PasswordIcon = styled.div<IconProps>`
  background-image: ${({ setIcon }) => `url(${setIcon})`};
  background-repeat: no-repeat;
  height: 20px;
  width: 20px;
  position: absolute;
  right: 2%;
  top: 60%;
`;
const InputInnerActionSection = styled.div`
  position: absolute;
  z-index: 2;
  right: 1%;
  top: 20%;
  width: 20px
  height: 20px;
`;

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      required,
      error,
      noLabel,
      icon,
      width = 0,
      img = cloudIcon,
      labelText = "label text",
      placeholderText = "Field Text",
      errorText = "Error Text",
      filter,
      setFilter,
      setFormValue,
      type = "text",
      isInnerTooltip,
      tooltipText = "",
      inputId = "",
      getValues = () => "",
      copy,
      disabled,
      onClick = () => {},
    },
    ref
  ) => {
    const [passwordIcon, setPasswordIcon] = useState(eyeIcon);
    const [hidePassword, setHidePassword] = useState(true);
    const toast = useToast();
    const onChange = useAsyncDebounce((value) => {
      if (setFilter) {
        setFilter(value || undefined);
      }
    }, 1000);

    const changeIcon = (icon) => {
      if (icon === eyeIcon) {
        setPasswordIcon(eyeIconOff);
        setHidePassword(false);
        return;
      }
      setPasswordIcon(eyeIcon);
      setHidePassword(true);
    };
    return (
      <Label width={width}>
        {type === "password" ? (
          <PasswordIcon
            setIcon={passwordIcon}
            onClick={() => changeIcon(passwordIcon)}
          />
        ) : null}
        {noLabel && (
          <CoreSubtitlesM
            style={{ margin: "0 0 .25rem 0" }}
            color={Theme.colors.neutrals_000}
          >
            {labelText}
            {required && (
              <SubtitlesM
                color={Theme.colors.danger_000}
                style={{ display: "inline" }}
              >
                {" "}
                *
              </SubtitlesM>
            )}
          </CoreSubtitlesM>
        )}
        <div style={{ position: "relative", width: "inherit" }}>
          <div style={{ display: "flex" }}>
            <CustomInput
              style={{
                width: `100%`,
                paddingTop: 12,
                paddingBottom: 12,
                paddingRight: 0,
                paddingLeft: 12,
              }}
              ref={ref}
              type={type === "password" && hidePassword ? "password" : "text"}
              placeholder={placeholderText}
              error={error}
              icon={icon}
              img={img}
              onClick={onClick}
              onChange={(e) => {
                if (setFormValue) {
                  setFormValue(e.target.value);
                } else {
                  onChange(e.target.value);
                }
              }}
              disabled={disabled}
            />
            {copy && (
              <CopyButtonWrapper style={{ marginRight: "0.5 rem" }}>
                <ButtonOutline
                  icon={true}
                  color={Theme.colors.primaries_000}
                  hoverColor={Theme.colors.primaries_neg100}
                  noText={false}
                  onClick={async () => {
                    await navigator.clipboard
                      .writeText(getValues(inputId) || "")
                      .then(() => {
                        toast.success("Copied to clipboard");
                      });
                  }}
                  width={"2.75rem"}
                  img={
                    <ClipboardCopyIcon
                      width={"0.938rem"}
                      color={Theme.colors.primaries_000}
                    />
                  }
                />
              </CopyButtonWrapper>
            )}
          </div>
          {isInnerTooltip && (
            <InputInnerActionSection>
              <Tooltip
                text={tooltipText}
                position="top"
                background="black"
                style={{ position: "absolute" }}
              >
                <QuestionMarkCircleIcon
                  style={{
                    width: "20px",
                    height: "20px",
                    color: `${Theme.colors.neutrals_200}`,
                  }}
                />
              </Tooltip>
            </InputInnerActionSection>
          )}
        </div>

        {error && (
          <ParagraphS
            style={{ margin: ".5rem 0 0 0" }}
            color={Theme.colors.danger_000}
          >
            {errorText}
          </ParagraphS>
        )}
      </Label>
    );
  }
);
