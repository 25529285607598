import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  XCircleIcon,
} from "@heroicons/react/outline";

export const MiniStatusBadge = ({ status, id }) => {
  switch (status) {
    case "verified":
      return (
        <div
          className="bg-success-500 w-[1.75rem] p-[2px] rounded-[50%] hover:bg-success-600"
          id={id}
        >
          <CheckCircleIcon stroke="white" />
        </div>
      );
    case "unverified":
      return (
        <div
          className="bg-danger-500 w-[1.75rem] p-[2px] rounded-[50%] hover:bg-danger-600"
          id={id}
        >
          <XCircleIcon stroke="white" />
        </div>
      );
    default:
      return (
        <div
          className="bg-warning-500 w-[1.75rem] p-[2px] rounded-[50%] hover:bg-warning-600"
          id={id}
        >
          <ExclamationCircleIcon stroke="white" />
        </div>
      );
  }
};
