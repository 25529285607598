import React from "react";
import { UseFormReturn } from "react-hook-form";
import {
  RequestType,
  RequestTypeCertifiedMapping,
} from "../atoms/RequestTypes";
import { ParagraphS, TitleS } from "../atoms/Typography";
import { Theme } from "../atoms/theme";
import { CheckboxAndSwitch } from "../molecules/CheckboxAndToggle";
import styled from "styled-components";
import { XIcon } from "@heroicons/react/outline";
interface CreateRequestTypeFormProps extends UseFormReturn<any, any> {
  requestType: any;
  setValue: any;
  watch: any;
  setOpen: Function;
}

const CheckAndSwitchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  gap: 1rem;
`;

function CreateRequestTypeForm({
  requestType,
  setValue,
  setOpen,
  getValues,
  watch,
  ...form
}: CreateRequestTypeFormProps) {
  const createdByLawFirm = {};
  requestType.forEach((type) => {
    if (type.createdByLawFirm) {
      createdByLawFirm[type.requestType] = true;
    }
  });
  return (
    <div>
      <div className="flex flex-col gap-4">
        <div className="flex justify-between">
          <TitleS>Add Request Type</TitleS>
          <XIcon
            width={"1rem"}
            onClick={() => setOpen(false)}
            style={{ cursor: "pointer" }}
          ></XIcon>
        </div>
        <ParagraphS color={Theme.colors.gray_000}>
          Note: Requests submitted by the law firm cannot be deleted. If they
          are no longer valid, mark them as “Closed - Not sent” in the status.
        </ParagraphS>
        <CheckAndSwitchWrapper>
          {RequestType.map((requestTypeLabel) => {
            return (
              <div className="w-[48.5%]">
                <CheckboxAndSwitch
                  id={requestTypeLabel.id}
                  label={requestTypeLabel.label}
                  getValues={getValues}
                  checked={watch(requestTypeLabel.id)}
                  toggled={watch(
                    RequestTypeCertifiedMapping[requestTypeLabel.id]
                  )}
                  toggleLabel="Certified"
                  disabled={createdByLawFirm[requestTypeLabel.id]}
                  onChangeCheckbox={(checked) => {
                    setValue(requestTypeLabel.id, checked);
                    if (!checked) {
                      setValue(
                        RequestTypeCertifiedMapping[requestTypeLabel.id],
                        false
                      );
                    }
                  }}
                  onChangeToggle={(toggled) => {
                    if (toggled) {
                      setValue(requestTypeLabel.id, true);
                    }
                    setValue(
                      RequestTypeCertifiedMapping[requestTypeLabel.id],
                      toggled
                    );
                  }}
                />
              </div>
            );
          })}
        </CheckAndSwitchWrapper>
      </div>
    </div>
  );
}

export default CreateRequestTypeForm;
