import { useMemo, useState } from "react";
import { EyeIcon, TrashIcon } from "@heroicons/react/outline";
import {
  useGetPatientsQuery,
  useDeletePatientMutation,
} from "../../../redux/reduxQuery/patientApi";
import { useNavigate } from "react-router-dom";

import { TitleM } from "../../../components/atoms/Typography";
import { EyeTrashIconButtons } from "../../../components/molecules/TableActionButtons";
import { ContentWrapper } from "../../../components/ContentWrapper";
import { ModalWithTwoButtons } from "../../../components/molecules/Modal";
import { CustomTable } from "../../../components/organisms/ShimmerTable";
import { formatDate } from "../../../utils/formatDate";
import { RenderCell } from "../../../common/fv";
import { useMutationWrapper } from "../../../common/api";
import { formatSSN } from "../../../common/format";

export default function Patient() {
  const [isOpen, setIsOpen] = useState(false);
  const [patient, setPatient] = useState<{ id: String; name: string }>();
  let navigate = useNavigate();
  const { data, isLoading, refetch } = useGetPatientsQuery("");
  const [deletePatientMutation] = useDeletePatientMutation();
  const [isProcessing, setIsProcessing] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        includeToolTip: true,
      },
      {
        Header: "Birth Date",
        accessor: (row) => formatDate(row.dateOfBirth),
      },
      {
        Header: "SSN",
        accessor: (row) => formatSSN(row.ssn),
      },
      {
        Header: "Law Firm",
        accessor: "firm.name",
        includeToolTip: true,
      },
      {
        Header: "FV Status",
        Cell: (cellProps) => RenderCell(cellProps, scrollPosition),
      },
      {
        Header: "Actions",
        accessor: "id",
        disableSortBy: true,
        disableFilters: true,
        Cell: (cell) => {
          const patientId = cell.row.values.id;
          return (
            <EyeTrashIconButtons
              link={`/admin/patients/${patientId}`}
              icons={[EyeIcon, TrashIcon]}
              onClick1={() => navigate(`/admin/patients/${patientId}`)}
              onClick2={() => {
                setPatient(cell.row.values);
                setIsOpen(true);
              }}
            />
          );
        },
      },
    ],
    [navigate, scrollPosition]
  );
  const mutate = useMutationWrapper(
    deletePatientMutation,
    `Patient Deleted Successfully`,
    () => {
      setIsProcessing(false);
      refetch();
      setIsOpen(false);
    }
  );
  const deletePatient = () => {
    setIsProcessing(true);
    mutate(patient?.id);
  };
  return (
    <>
      {isOpen && (
        <ModalWithTwoButtons
          title="delete patient"
          content={`Are you sure you want to delete ${patient?.name}? If you do you won’t be able to recover it.`}
          button1Text="delete"
          button2Text="cancel"
          loader={isProcessing}
          onClick1={() => deletePatient()}
          onClick2={() => setIsOpen(false)}
        />
      )}
      <ContentWrapper>
        <TitleM style={{ margin: "1rem 0" }}>Patients</TitleM>
        <CustomTable
          tableType="patients"
          columns={columns}
          data={data}
          isLoading={isLoading}
          navigation={() => navigate("/admin/patients/new")}
          onScroll={setScrollPosition}
          scrollPosition={scrollPosition}
        />
      </ContentWrapper>
    </>
  );
}
