import React from "react";
import { Provider } from "react-redux";
import { Auth0Provider } from "@auth0/auth0-react";
import { ThemeProvider, createGlobalStyle } from "styled-components";
import { Theme } from "./components/atoms/theme";
import { Toast } from "./components/molecules/Toast";
import RouteNavigator from "./RouteNavigator";
import { store } from "./redux/store";
import "react-calendar/dist/Calendar.css";

const GlobalStyle = createGlobalStyle``;

function App() {
  return (
    <Auth0Provider
      domain="login.rayna-app.com"
      scope="openid profile email"
      clientId="jjKa0N450DgJNiC9bnVogdAS08HZGBOy"
      redirectUri={window.location.origin}
    >
      <Provider store={store}>
        <ThemeProvider theme={Theme}>
          <GlobalStyle />
          <RouteNavigator />
          <Toast />
        </ThemeProvider>
      </Provider>
    </Auth0Provider>
  );
}

export default App;
