import React from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import { TitleM } from "../../../components/atoms/Typography";
import { ContentWrapper } from "../../../components/ContentWrapper";
import { Breadcrumb } from "../../../components/molecules/Breadcrumb";
import Form from "../../../components/organisms/Form";
import { useCreateFirmMutation } from "../../../redux/reduxQuery/firmApi";
import { useMutationWrapper } from "../../../common/api";

export default function CreateLawFirm() {
  const [createLawFirm] = useCreateFirmMutation();
  let navigate = useNavigate();

  const form = useForm();
  const mutate = useMutationWrapper(
    createLawFirm,
    `Law Firm Successfully Created`,
    () => navigate(`/admin/law-firms`)
  );
  const onSubmit = form.handleSubmit((data) => {
    return mutate({ ...data, state: data.state?.value });
  });

  const crumbLinks = [
    {
      text: "Law Firms",
      path: "/admin/law-firms",
    },
    {
      text: "Add New Law Firm",
    },
  ];

  const formInputs = [
    [
      {
        id: "name",
        label: "Firm Name",
        required: true,
        copy: false,
      },
      {
        id: "contactName",
        label: "User Name",
        required: true,
        copy: false,
      },
      {
        id: "contactEmail",
        label: "User Email",
        required: true,
        copy: false,
        type: "email",
      },
    ],
    [
      {
        id: "address",
        label: "Address",
        required: true,
        copy: false,
      },
      {
        id: "state",
        label: "State",
        required: true,
        copy: false,
        type: "state",
      },
    ],
    [
      {
        id: "city",
        label: "City",
        required: true,
        copy: false,
      },
      {
        id: "zip",
        label: "Zip Code",
        required: true,
        copy: false,
        type: "zip",
      },
    ],
  ];

  return (
    <ContentWrapper>
      <div style={{ marginBottom: "1.5rem" }} />
      <Breadcrumb crumbs={crumbLinks} />
      <TitleM>Add New Law Firm</TitleM>
      <Form
        onSubmit={onSubmit}
        formInputs={formInputs}
        numberOfColumns={3}
        submitButtonText={"Create Law Firm"}
        cancelAction={() => {
          navigate(-1);
        }}
        {...form}
      />
    </ContentWrapper>
  );
}
