import styled from "styled-components";
import { Theme } from "./theme";

export const DivideLine = styled.div`
  display: flex;
  border: 1px solid ${Theme.colors.neutrals_400};
  border-radius: 9999px;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`;
