import { requestsFormInput } from "../../screens/ClientPortal/requestConstants";
export const mapRequestsFormInputsWithToggleFunction = (
  showCertifiedRequestModal,
  setShowModal
) => {
  const requestsFormInputWithToggleFunction = {
    ...requestsFormInput,
    onEnable: (toggled) => {
      if (showCertifiedRequestModal) {
        setShowModal(toggled);
      }
    },
  };
  return requestsFormInputWithToggleFunction;
};
