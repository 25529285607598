class User {
  id: string;
  name: string;
  email: string;
  roles: string;
  firmId: string;
  modalPreference: {
    [key: string]: boolean;
  };

  constructor(user: {
    id: string;
    name: string;
    email: string;
    roles: string;
    firmId: string;
    modalPreference: { [key: string]: boolean };
  }) {
    this.id = user.id;
    this.name = user.name;
    this.email = user.email;
    this.roles = user.roles;
    this.firmId = user.firmId;
    this.modalPreference = user.modalPreference;
  }

  isAdmin() {
    return this.roles.includes("admin");
  }

  isClient() {
    return this.roles.includes("client");
  }

  isClientMember() {
    return this.roles.includes("clientmember");
  }

  isClientManager() {
    return this.roles.includes("clientmanager");
  }
}

export default User;
