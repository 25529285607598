import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useMemo, useState } from "react";
import User from "../classes/User";
import { login, logout, setCurrentUser } from "../redux/reducers/auth";
import { API_URL } from "../redux/reduxQuery/api";
import { useAppDispatch, useAppSelector } from "./redux";
import useToast from "./useToast";

export const useLogin = () => {
  const {
    isAuthenticated,
    isLoading: isLoadingAuth0,
    getAccessTokenSilently,
    getAccessTokenWithPopup,
    logout: auth0Logout,
  } = useAuth0();
  const dispatch = useAppDispatch();
  const toast = useToast();
  const [isFetching, setIsFetching] = useState(false);
  useEffect(() => {
    const opts = {
      audience: "https://rayna-prod.us.auth0.com/api/v2/",
    };
    if (isAuthenticated) {
      getAccessTokenSilently(opts)
        .catch(() => getAccessTokenWithPopup(opts))
        .then((token) => {
          dispatch(login(token));
        });
    }
  }, [
    isAuthenticated,
    dispatch,
    getAccessTokenSilently,
    getAccessTokenWithPopup,
  ]);
  const auth = useAppSelector((s) => s.auth);
  const token = auth?.token;
  const user = auth?.user;
  const hasToken = token!!;
  const isLoading = isLoadingAuth0 || (isAuthenticated && (!hasToken || !user));
  const logUserOut = useMemo(() => {
    return () => {
      toast.error(
        `This user has not been configured. Please reach out to the administrator.`
      );
      setTimeout(() => {
        dispatch(logout());
        auth0Logout({ returnTo: window.location.origin });
      }, 3000);
    };
  }, [auth0Logout, dispatch, toast]);
  useEffect(() => {
    if (isAuthenticated && hasToken && !isFetching) {
      fetch(`${API_URL}/users/me`, {
        headers: { authorization: `Bearer ${token}` },
      })
        .then(async (res) => {
          const body = await res.json();
          if (!res.ok) {
            // get error message from body or default to response status
            const error = (body.data && body.message) || res.status;
            return Promise.reject(error);
          }
          dispatch(setCurrentUser(body));
        })
        .catch(() => {
          logUserOut();
        });
      setIsFetching(true);
    }
  }, [
    isAuthenticated,
    hasToken,
    token,
    dispatch,
    logUserOut,
    isFetching,
    setIsFetching,
  ]);

  return {
    isLoading,
    isAuthenticated,
    user: user ? new User(user) : user,
  };
};

export const useUser = () => {
  const user = useAppSelector((s) => s.auth?.user);
  if (!user) return null;
  else return new User(user);
};

export const useLogout = () => {
  const { logout: auth0Logout } = useAuth0();
  const dispatch = useAppDispatch();
  dispatch(logout());
  auth0Logout({
    returnTo: window.location.origin,
  });
};
