import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ParagraphS, SubtitlesM, TitleXS } from "./Typography";
import { Theme } from "./theme";
import { Button, Modal } from "@tyrannosaurustech/ui";

interface LabelProps {
  width?: string;
}

const CustomTextarea = styled.textarea<any>`
  width: ${({ width }) => width || "18.75rem"};
  min-height: ${({ minHeight }) => minHeight || "10rem"};
  padding: ${({ theme }) => theme.spacing.spacing_xs};
  padding-right: ${({ fullScreen }) => "3rem"};
  border-radius: ${({ theme }) => theme.spacing.spacing_xs};
  position: relative;
  border: ${({ error, theme }) =>
    error
      ? `1px solid ${theme.colors.danger_000}`
      : `1px solid ${theme.colors.neutrals_400}`};
  background-color: ${({ theme }) => theme.colors.neutrals_400};
  font-size: 14px;
  line-height: ${({ lineHeight }) => lineHeight || "20px"};
  resize: none;
  ::placeholder {
    color: ${({ theme }) => theme.colors.neutrals_200};
    font-size: 14px;
    line-height: 20px;
  }
  &:focus {
    border: 1px solid ${({ theme }) => theme.colors.primaries_000};
    box-shadow: 0px 0px 0px 4px #f3dafa;
    outline: none;
  }
`;
const Label = styled.label<LabelProps>`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width};
`;

export const TextArea = (props: any) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(
    props.value ?? props?.getValues?.(props?.name) ?? ""
  );
  useEffect(() => {
    if (props?.name && props?.getValues?.(props?.name)) {
      setValue(props?.getValues?.(props?.name));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.name, props.getValues]);
  const updateValue = (e: any) => {
    setValue(e.target.value);
    props?.setValue?.(props?.name, e.target.value);
  };
  return (
    <Label width={props.width}>
      <Modal
        open={open}
        setOpen={setOpen}
        title={""}
        onConfirm={() => null}
        noCancel={true}
        noConfirm={true}
        panelClassName="!max-w-[85vw]"
      >
        <div>
          <TitleXS
            style={{ margin: "0 0 1rem 0" }}
            color={Theme.colors.neutrals_000}
          >
            {props.labelText ?? props.fullScreenLabel}
            {props.required && (
              <SubtitlesM
                color={Theme.colors.danger_000}
                style={{ display: "inline" }}
              >
                {" "}
                *
              </SubtitlesM>
            )}
          </TitleXS>
          <CustomTextarea
            placeholder={props.placeholderText}
            error={props.error}
            rows={4}
            width={"80vw"}
            minHeight={"70vh"}
            lineHeight={props.lineHeight}
            onChange={props.onChange ?? updateValue}
            value={props?.value ?? value}
          />
        </div>
      </Modal>
      <SubtitlesM
        style={{ margin: "0 0 .5rem 0" }}
        color={Theme.colors.neutrals_000}
      >
        {props.labelText}
        {props.required && (
          <SubtitlesM
            color={Theme.colors.danger_000}
            style={{ display: "inline" }}
          >
            {" "}
            *
          </SubtitlesM>
        )}
      </SubtitlesM>
      <div className="relative">
        <CustomTextarea
          placeholder={props.placeholderText}
          error={props.error}
          width={props.width}
          fullScreen={props?.fullScreen}
          rows={4}
          lineHeight={props.lineHeight}
          value={props?.value ?? value}
          {...props.register}
          onChange={props.onChange ?? updateValue}
        ></CustomTextarea>
        {props.fullScreen && (
          <div className="absolute top-2 right-2">
            <Button
              base="tertiary"
              icon="ArrowsPointingOutIcon"
              size="small"
              type="button"
              onClick={() => setOpen(true)}
            />
          </div>
        )}
      </div>
      {props.error && (
        <ParagraphS
          style={{ margin: ".5rem 0 0 0" }}
          color={Theme.colors.danger_000}
        >
          {props.errorText}
        </ParagraphS>
      )}
    </Label>
  );
};
