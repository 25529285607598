import { useState } from "react";
import { Modal, Stepper } from "@tyrannosaurustech/ui";
import { Theme } from "../atoms/theme";
import {
  useSearchProvidersByName,
  useUpdateProviderConnectionMutation,
} from "../../redux/reduxQuery/medicalProviderApi";
import Async from "react-select/async";
import { BadgeStatus } from "../atoms/Badge";
import { CustomSheetButton } from "../atoms/Button";
import { SubtitlesS, ParagraphS } from "../atoms/Typography";
import { PhoneIcon } from "@heroicons/react/outline";
import ConnectionDetailForm from "./ConnectionDetailForm";
import { FieldValues, UseFormReturn } from "react-hook-form";

interface CreateMedicalProviderConnectionModalProps {
  setOpen?: (value: boolean) => void;
  open?: boolean;
  provider?: any;
  editMode?: boolean;
  connectedProvider?: any;
  refetchFunction?: any;
  setEditMode?: (value: boolean) => void;
  form: UseFormReturn<FieldValues, any>;
}

export const CreateMedicalProviderConnectionModal = ({
  setOpen = () => {},
  open = false,
  provider = {},
  editMode = false,
  connectedProvider = null,
  refetchFunction = () => {},
  setEditMode = () => {},
  form,
}: CreateMedicalProviderConnectionModalProps) => {
  const searchProviders = useSearchProvidersByName();
  const [selectedProvider, setSelectedProvider] =
    useState<any>(connectedProvider);
  const [step, setStep] = useState(0);
  const numberOfSteps = editMode ? 1 : 2;
  const isLastStep = step === numberOfSteps - 1;
  const [updateProviderConnection] = useUpdateProviderConnectionMutation();
  const onSubmit = form.handleSubmit((data) => {
    updateProviderConnection({
      toId: selectedProvider?.id || connectedProvider?.id,
      fromId: provider.id,
      validFrom: data.validFrom,
      validTo: data.validTo,
      requestType: data.requestType?.id,
      connectionNotes: data.connectionNotes,
    }).then(() => {
      if (editMode) {
        setEditMode(false);
      }
      refetchFunction();
    });
    setOpen(false);
  });

  return (
    <Modal
      open={open}
      setOpen={(shouldOpen) => {
        if (!shouldOpen) {
          setEditMode(false);
        }
        setSelectedProvider(null);
        setOpen(shouldOpen);
        setStep(0);
      }}
      loading={false}
      title={
        isLastStep ? "Relevant for this Connection" : "Medical Provider Name"
      }
      confirmText={isLastStep ? (editMode ? "Save" : "Finish") : "Next"}
      onConfirm={() => {
        isLastStep ? onSubmit() : setStep(step + 1);
      }}
      noConfirm={!selectedProvider && !editMode}
      panelClassName="overflow-visible"
    >
      <div style={{ width: "100%" }}>
        {!editMode && (
          <Stepper
            containerClasses="w-full mt-1 mb-4 p-2"
            currentStep={step}
            successStepColor="bg-primary"
            numberOfSteps={numberOfSteps}
            pendingStepLineClasses="h-[0.15rem]"
            successLineClasses="h-[0.15rem] bg-primary"
          />
        )}
        {!editMode && step === 0 && (
          <SelectedProviderScreen
            provider={provider}
            searchProviders={searchProviders}
            setSelectedProvider={setSelectedProvider}
            selectedProvider={selectedProvider}
          />
        )}
        {(editMode || step === 1) && <ConnectionDetailsScreen form={form} />}
      </div>
    </Modal>
  );
};

const ConnectionDetailsScreen = ({ form }) => {
  return (
    <div>
      <ParagraphS
        color={Theme.colors.gray_200}
        style={{ marginBottom: "1rem" }}
      >
        Please list the dates when this connection is relevant to this provider,
        along with the corresponding request type that makes it relevant. Feel
        free to add any additional notes with extra information if needed.
      </ParagraphS>
      <ConnectionDetailForm {...form} />
    </div>
  );
};

const SelectedProviderScreen = ({
  provider,
  searchProviders,
  setSelectedProvider,
  selectedProvider,
}) => {
  return (
    <div>
      <ParagraphS
        color={Theme.colors.gray_200}
        style={{ marginBottom: "1rem" }}
      >
        {`Please select a medical provider that is associated with ${provider.name}. 
        If the provider is not listed, please visit the medical providers page and create it.`}
      </ParagraphS>
      <Async
        loadOptions={searchProviders}
        getOptionLabel={(p: any) => p.name}
        getOptionValue={(p: any) => p.id}
        onChange={(option) => {
          setSelectedProvider(option);
        }}
        value={selectedProvider}
        isClearable
        cacheOptions
        placeholder="Search Medical Providers"
        components={{
          Option: ProviderOption,
        }}
        styles={{
          container: (provided) => ({
            ...provided,
            width: "100%",
          }),
          control: (provided) => ({
            ...provided,
            height: "44px",
            marginBottom: "1rem",
            width: "100%",
          }),
        }}
      />
    </div>
  );
};

const MedicalProviderOptions = ({ provider }) => {
  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <div style={{ textAlign: "left" }}>
        <SubtitlesS>{provider.name}</SubtitlesS>
        <ParagraphS>{provider.address}</ParagraphS>
        <ParagraphS color={Theme.colors.gray_200}>{provider.type}</ParagraphS>
      </div>
      <div style={{ alignContent: "start" }}>
        <PhoneIcon color={Theme.colors.primaries_000} width={"1.25rem"} />
      </div>
    </div>
  );
};
const ProviderOption = ({ data, label, innerProps }) => {
  return (
    <CustomSheetButton
      width={"100%"}
      icon={false}
      color={"#AE3EC9"}
      badge={<BadgeStatus status={data.status} />}
      buttonText={label}
      onClick={() => innerProps.onClick()}
      children={<MedicalProviderOptions provider={data} />}
      style={{ justifyContent: "space-between" }}
    />
  );
};
