import { useEffect, useState } from "react";
import { useGetPatientFormQuery } from "../redux/reduxQuery/patientFormApi";

const useDownloadPatientForm = (patientFormId: string) => {
  const { data: patientForm } = useGetPatientFormQuery(patientFormId);
  const [shouldDownload, setShouldDownload] = useState(false);
  const download = () => setShouldDownload(true);
  useEffect(() => {
    if (patientForm && shouldDownload) {
      downloadBase64File(patientForm);
      setShouldDownload(false);
    }
  }, [patientForm, shouldDownload, setShouldDownload]);
  return { download };
};

export default useDownloadPatientForm;

export const downloadBase64File = (file) => {
  var a = document.createElement("a");
  a.href = `data:${file.mimeType};base64,${file.file}`;
  a.download = file.name;
  a.click();
};
