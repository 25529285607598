import { useEffect, useState } from "react";
import { useGetRequests } from "../../../../redux/reduxQuery/requestApi";
import { TableUtil } from "@tyrannosaurustech/ui";
import { useAppSelector } from "../../../../hooks/redux";

export const useGetData = (initialQs = "") => {
  const [dataToFetch, setDataToFetch] = useState(initialQs);
  const pageSize = useAppSelector(
    (state) => state.filter?.requestControl?.pageSize
  );
  const searchParam = useAppSelector(
    (state) => state.filter?.requestControl?.searchParam
  );
  const getData = useGetRequests(dataToFetch);
  const useAsyncConfig = TableUtil.useAsyncConfig;
  let search: string | undefined = "";
  if (searchParam === undefined || typeof searchParam === "string") {
    search = searchParam;
  } else {
    search = "";
  }
  let [config, refetch] = useAsyncConfig(
    getData,
    Number(pageSize ?? 50),
    search
  );

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataToFetch]);
  return {
    config,
    refetch,
    dataToFetch,
    setDataToFetch,
  };
};
