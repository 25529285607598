import api from "./api";

const patientFormApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getPatientForm: builder.query({
      query: (id) => `patient-forms/${id}`,
    }),
    createPatientForm: builder.mutation({
      query: (body) => ({
        url: "patient-forms",
        method: "POST",
        body,
      }),
    }),
    deletePatientForm: builder.mutation({
      query: (id) => ({
        url: `patient-forms/${id}`,
        method: `DELETE`,
      }),
      invalidatesTags: [{ type: "PatientForms", id: "LIST" }],
    }),
  }),
});

export const {
  useGetPatientFormQuery,
  useCreatePatientFormMutation,
  useDeletePatientFormMutation,
} = patientFormApi;
