export const filtersProvider = (filterValues) => {
  let qs = Object.keys(filterValues)
    .map((key) => {
      if (filterValues[key]) {
        let keyCamelCase = key.replace(" ", "");
        keyCamelCase =
          keyCamelCase.charAt(0).toLowerCase() + keyCamelCase.slice(1);
        if (typeof filterValues[key] === "object") {
          if (
            typeof filterValues[key].name === "object" &&
            filterValues[key].name
          ) {
            return `&${key + "Name"}=${filterValues[key].name.label}`;
          } else if (filterValues[key].name) {
            return `&${key + "Name"}=${encodeURIComponent(
              filterValues[key].name
            )}`;
          } else if (filterValues[key].value) {
            return `&${keyCamelCase}=${encodeURIComponent(
              filterValues[key].value.toLowerCase()
            )}`;
          } else {
            return "";
          }
        } else {
          return `&${keyCamelCase}=${encodeURIComponent(filterValues[key])}`;
        }
      } else {
        return "";
      }
    })
    .join("");

  return qs;
};
