import { Theme } from "../components/atoms/theme";

export const statusColor = {
  new: Theme.colors.red_000,
  processing: Theme.colors.blue_000,
  entered: Theme.colors.green_000,
  awaiting_law_firm_info: Theme.colors.amber_000,
  vetted: Theme.colors.violet_000,
  closed_not_sent: Theme.colors.gray_000,
  needs_further_review: Theme.colors.green_100,
};
