import { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Breadcrumb } from "../../../components/molecules/Breadcrumb";
import { ContentWrapper } from "../../../components/ContentWrapper";
import { BreadcrumbContainer } from "./style";
import Form from "../../../components/organisms/Form";
import { useForm } from "react-hook-form";
import { patientsForm } from "./constants";
import { TitleM } from "../../../components/atoms/Typography";
import { useCreatePatientMutation } from "../../../redux/reduxQuery/patientApi";
import { DataSelectedPatient } from "./types";
import { ModalWithTwoButtons } from "../../../components/molecules/Modal";
import { useNavigate } from "react-router-dom";
import { useMutationWrapper } from "../../../common/api";

export default function CreatePatient() {
  let navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const locationState = location.state as DataSelectedPatient;
  const { pathname } = location;
  const isFromNewRequestForm = (pathname) => pathname === "/admin/requests/new";
  const isFromClientPortal = (pathname) => {
    return pathname?.includes("client");
  };
  const goBackToPreviousScreen = (previousPathname, res?) => {
    if (!previousPathname) return navigate("/admin/patients");
    return isFromNewRequestForm(previousPathname) && res
      ? navigate(`/admin/requests/new`, {
          state: {
            formData: locationState?.formData,
            cameFrom: pathname,
            isCreatedNewPatient: true,
            patient: res,
          },
        })
      : isFromClientPortal(previousPathname) && res
      ? navigate(`${previousPathname}`, {
          state: {
            formData: locationState?.formData,
            cameFrom: "clientPortalNewPatient",
            isCreatedNewPatient: true,
            patient: res,
          },
        })
      : isFromNewRequestForm(previousPathname)
      ? navigate("/admin/requests/new", {
          state: {
            formData: locationState?.formData,
            cameFrom: pathname,
          },
        })
      : isFromClientPortal(previousPathname)
      ? navigate(`${previousPathname}`, {
          state: {
            formData: locationState?.formData,
            cameFrom: "clientPortalNewPatient",
          },
        })
      : navigate("/admin/patients");
  };
  const [createPatient] = useCreatePatientMutation();
  const [openModal, setOpenModal] = useState(false);
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const form = useForm();

  const crumbLinks = {
    crumbs: [
      {
        text: "Patients",
        onClick: () => validForm(),
      },
      {
        text: "New Patient",
      },
    ],
  };
  const isCreating = location.pathname.includes("new");

  const validForm = () => {
    const isEmpty =
      Object.values(form.getValues()).filter(
        (item) => item !== "" && item !== null && item !== undefined
      ).length === 0;
    isEmpty && isCreating ? navigate("/admin/patients") : setOpenModal(true);
  };

  const mutate = useMutationWrapper(
    createPatient,
    (formData) => {
      return `${formData.name} was added successfully`;
    },
    (res) => goBackToPreviousScreen(locationState?.cameFrom, res)
  );

  const onSubmit = form.handleSubmit((data) => {
    const patientFormData = data;

    patientFormData.state = patientFormData.state?.value;

    if (params.lawFirmId) {
      patientFormData.firmId = params.lawFirmId;
    }

    return mutate(patientFormData);
  });

  return (
    <>
      {openModal && (
        <ModalWithTwoButtons
          title="Unsaved Changes"
          content="Your changes have not been saved. If you leave you will lose all your progress. Are you sure you want to leave without saving?"
          button1Text="Leave"
          button2Text="Stay"
          onClick1={() =>
            goBackToPreviousScreen(
              locationState?.cameFrom ? locationState?.cameFrom : pathname
            )
          }
          onClick2={() => setOpenModal(false)}
        />
      )}
      {openCancelModal && (
        <ModalWithTwoButtons
          title="Cancel new Patient"
          content="Are you sure you want to cancel? If you do, you will lose all your changes."
          button1Text="Cancel new Patient"
          button2Text="Keep Editing"
          onClick1={() => goBackToPreviousScreen(locationState?.cameFrom)}
          onClick2={() => setOpenCancelModal(false)}
        />
      )}
      <ContentWrapper height={"100%"}>
        <>
          <BreadcrumbContainer>
            <Breadcrumb crumbs={crumbLinks.crumbs} />
          </BreadcrumbContainer>
          <TitleM>{locationState?.name}</TitleM>
          <Form
            {...form}
            onSubmit={onSubmit}
            formInputs={patientsForm}
            numberOfColumns={2}
            submitButtonText={"Add New Patient"}
            cancelAction={() => setOpenCancelModal(true)}
            customSubmit={false}
            buttonsAlignment="start"
            onFileDelete={(input) => {
              const files = form.getValues(input.inputId);
              const index = files.findIndex((f) => f.name === input.name);
              files.splice(index, 1);
              files.length > 0
                ? form.setValue(input.inputId, files)
                : form.setValue(input.inputId, "");
            }}
          />
        </>
      </ContentWrapper>
    </>
  );
}
