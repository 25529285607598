import styled from "styled-components";
import { Theme } from "./theme";

export const CardWrapper = styled.div`
  background: #fff;
  width: 100%;
  min-width: ${({ style }) => style?.minWidth};
  height: fit-content;
  border-radius: 0.75rem;
  border: 1px solid ${Theme.colors.gray_100};
  padding: 0.5rem;
  box-sizing: border-box;
  margin-top: ${({ style }) => style?.marginTop || "0.5rem"};
  margin-bottom: ${({ style }) => style?.marginBottom || "0.5rem"};
  flex-direction: column;
`;
