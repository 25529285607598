import React, { useState, useRef, useEffect } from "react";
import { UseFormReturn } from "react-hook-form";
import { Button, Select } from "@tyrannosaurustech/ui";
import { Input } from "../atoms/Input";
import { RequestType } from "../atoms/RequestTypes";
import { TextArea } from "../atoms/TextArea";
import { ParagraphS, SubtitlesS } from "../atoms/Typography";
import Calendar from "react-calendar";
import styled from "styled-components";
import { CardWrapper } from "../atoms/Card";
import { DivideLine } from "../atoms/DivideLine";
import dayjs from "dayjs";

interface ConnectionDetailFormProps extends UseFormReturn<any, any> {
  setValue: any;
  watch: any;
}

const CustomCalendar = styled(Calendar)`
  border-width: 0px;
`;

function useClickOutside(ref, onClickOutside) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, onClickOutside]);
}

function ConnectionDetailForm({
  setValue,
  watch,
  register,
  formState,
  clearErrors,
}: ConnectionDetailFormProps) {
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [calendarPosition, setCalendarPosition] = useState("");
  const [dateId, setDateId] = useState("");
  const calendarRef = useRef(null);
  useClickOutside(calendarRef, () => setCalendarOpen(false));

  const fieldErrorMessage = (id, name, fieldState) => {
    const error = fieldState.errors[id];
    if (!error) return "";
    if (error.type === "required") return `${name} is required.`;
    else return error.message;
  };

  return (
    <>
      <div className="flex flex-col gap-4">
        <Select
          label="Select Request Type that is relevant to this connection"
          placeholder="Select Request Type that is relevant to this connection"
          options={RequestType}
          value={watch("requestType")}
          {...register("requestType", {
            required: true,
            validate: (value) => !!value || "Request Type is Required",
          })}
          errorText={fieldErrorMessage(
            "requestType",
            "Request Type",
            formState
          )}
          onChange={(value) => {
            setValue("requestType", value);
            clearErrors("requestType");
          }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            gap: "0.75rem",
          }}
          className={
            fieldErrorMessage("requestType", "Request Type", formState) &&
            "mt-3"
          }
        >
          {calendarOpen && (
            <div
              ref={calendarRef}
              style={{
                position: "absolute",
                zIndex: "999",
                left: calendarPosition === "right" ? "100%" : "auto",
                right: calendarPosition === "left" ? "100%" : "auto",
                top: "50%",
              }}
            >
              <CardWrapper>
                <CustomCalendar
                  onChange={(value) =>
                    setValue(
                      dateId,
                      dayjs(value?.toLocaleString()).format("MM/DD/YYYY")
                    )
                  }
                />
                <div style={{ display: "flex", gap: "0.5rem" }}>
                  <Button
                    base="secondary"
                    label="Apply"
                    size="small"
                    type="button"
                    onClick={() => {
                      setCalendarOpen(false);
                    }}
                    btnContainerClasses="w-[48%] p-[0.4rem] my-2"
                  />
                  <Button
                    base="primary"
                    size="small"
                    label="Cancel"
                    type="button"
                    onClick={() => {
                      setValue(dateId, "");
                      setCalendarOpen(false);
                    }}
                    btnContainerClasses="w-[50%] my-2"
                  />
                </div>

                <DivideLine />
                <ParagraphS>
                  <span style={{ textTransform: "none" }}>
                    If this provider is valid up to the current date, please{" "}
                  </span>
                  <span
                    onClick={() => {
                      setValue(dateId, "Present");
                    }}
                    style={{
                      textDecoration: "underline",
                      cursor: "pointer",
                      textTransform: "none",
                    }}
                  >
                    Click Here
                  </span>
                </ParagraphS>
              </CardWrapper>
            </div>
          )}
          <Input
            {...register("validFrom", {
              required: true,
              validate: (value) =>
                !isNaN(new Date(value).getDate()) ||
                value === "Present" ||
                "Please Enter a valid date",
            })}
            error={formState.errors["validFrom"]}
            errorText={fieldErrorMessage("validFrom", "From Date", formState)}
            noLabel={true}
            labelText={"From"}
            img={"./assets/icons/CalendarIcon.svg"}
            width={"100%"}
            placeholderText={"Select Date"}
            type={"text"}
            setFormValue={(value) => {
              setValue("validFrom", value);
              return value;
            }}
            onClick={() => {
              setDateId("validFrom");
              setCalendarPosition("left");
              setCalendarOpen(true);
            }}
          />
          <Input
            noLabel={true}
            {...register("validTo", {
              validate: (value) =>
                !isNaN(new Date(value).getDate()) ||
                !value ||
                value === "Present" ||
                "Please Enter a valid date",
            })}
            error={formState.errors["validTo"]}
            errorText={fieldErrorMessage("validTo", "To Date", formState)}
            labelText={"To"}
            img={"./assets/icons/CalendarIcon.svg"}
            width={"100%"}
            placeholderText={"Select Date"}
            type={"text"}
            setFormValue={(value) => {
              setValue("validTo", value);
              return value;
            }}
            onClick={() => {
              setDateId("validTo");
              setCalendarPosition("right");
              setCalendarOpen(true);
            }}
          />
        </div>
        <div>
          <SubtitlesS>Relevant notes about this connection</SubtitlesS>
          <TextArea
            value={watch("connectionNotes")}
            width="100%"
            lineHeight="15px"
            onChange={(e) => {
              setValue("connectionNotes", e.target.value);
            }}
            placeholder="Input Text"
          />
        </div>
      </div>
    </>
  );
}

export default ConnectionDetailForm;
