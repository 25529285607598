import { createSlice } from "@reduxjs/toolkit";
const initialState: { token: string | null; user: IUser | null } = {
  token: null,
  user: null,
};

interface IUser {
  id: string;
  name: string;
  email: string;
  roles: string;
  firmId: string;
  modalPreference: { [key: string]: boolean };
}

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login(state, action) {
      state.token = action?.payload;
    },
    logout(state) {
      state.token = null;
      state.user = null;
    },
    setCurrentUser(state, action) {
      state.user = action?.payload;
    },
  },
});

export const { login, logout, setCurrentUser } = authSlice.actions;
export default authSlice.reducer;
