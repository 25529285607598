import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../../hooks/redux";

export const useActionButtons = () => {
  const [openFilters, setOpenFilters] = useState(false);
  const globalUserFilter = useAppSelector((state) => state.filter.request);
  let globalUserFilterLength = 0;
  if (Object.keys(globalUserFilter ?? {})?.length) {
    globalUserFilterLength = Object.keys(globalUserFilter ?? {}).length;
  }
  const navigate = useNavigate();
  const actionButtonsConfig = [
    {
      label: `Filters${
        globalUserFilterLength ? ` (${globalUserFilterLength})` : ""
      }`,
      icon: "AdjustmentsVerticalIcon",
      base: "secondary",
      onClick: () => setOpenFilters(true),
      btnContainerClasses:
        "box-border border-2 border-primary-400 border-solid",
    },
    {
      label: "Add New",
      icon: "PlusIcon",
      onClick: () => navigate("/admin/requests/new"),
      btnContainerClasses: "box-border",
    },
  ];

  return { openFilters, setOpenFilters, actionButtonsConfig };
};
