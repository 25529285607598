import { useAppSelector } from "../../hooks/redux";

export const useGet = () => {
  let headers = new Headers();
  const token = useAppSelector((s) => s.auth.token);

  if (token) {
    headers.set("authorization", `Bearer ${token}`);
  }

  return (url) => fetch(url, { headers });
};
