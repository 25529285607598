import styled from "styled-components";

export const Title2XL = styled.h1`
  font-size: 6rem;
  font-weight: 600;
  line-height: 7.25rem;
  text-transform: capitalize;
`;
export const TitleXL = styled.h1`
  font-size: 3.75rem;
  font-weight: 600;
  line-height: 4.5rem;
  text-transform: capitalize;
`;
export const TitleL = styled.h1`
  font-size: 3rem;
  font-weight: 600;
  line-height: 3.5rem;
  text-transform: capitalize;
`;
export const TitleM = styled.h1`
  font-size: 2rem;
  font-weight: 600;
  line-height: 2.5rem;
  text-transform: capitalize;
`;
export const TitleS = styled.h1`
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.75rem;
  text-transform: capitalize;
`;
export const TitleXS = styled.h1`
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.5rem;
  text-transform: capitalize;
`;
export const SubtitlesM = styled.h2`
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.25rem;
  text-transform: capitalize;
  color: ${({ color }) => color};
`;

export const CoreSubtitlesM = styled.h2`
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 0.875rem;
  text-transform: capitalize;
  color: ${({ color }) => color};
`;
export const SubtitlesS = styled.h2`
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.25rem;
  text-transform: capitalize;
`;
export const SubtitlesBase = styled.h2`
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5rem; /* 150% */
  color: ${({ color }) => color};
`;
export const ParagraphM = styled.p`
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25rem;
  color: ${({ color }) => color};
`;
export const ParagraphS = styled.p`
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
  color: ${({ color }) => color};
`;
export const ButtonM = styled.p`
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.25rem;
  text-transform: capitalize;
  color: ${({ color }) => color};
`;
