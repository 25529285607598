import React, { Fragment } from "react";
import styled from "styled-components";
import { ChevronRightIcon } from "@heroicons/react/outline";

import { LinkText } from "../atoms/LinkText";

interface BreadcrumbProps {
  crumbs: { text: string; path?: string; onClick?: () => void }[];
  buttons?: JSX.Element[];
  icon?: JSX.Element;
}

const Breadcrumbs = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
`;
const BreadWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.75rem;
  width: 100%;
`;
const CrumbSection = styled.div``;
const ButtonsSection = styled.div`
  display: flex;
`;
const ActiveText = styled.p`
  color: ${({ theme }) => theme.colors.white};
  border-bottom: 0.125rem solid transparent;
  margin-left: 1rem;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: default;
`;

export const Breadcrumb = ({
  crumbs = [],
  buttons = [],
  icon,
}: BreadcrumbProps) => {
  const lastLink = 0;
  return (
    <BreadWrapper>
      <CrumbSection>
        <Breadcrumbs>
          {crumbs.map((crumb, index) =>
            crumb.path ? (
              <Fragment key={`crumb-with-path-${index}`}>
                {icon ? icon : null}
                <LinkText
                  name={crumb.text}
                  isSelected={lastLink === index}
                  path={crumb.path}
                  style={{ marginLeft: "0rem" }}
                />
                {crumb[index] === 1 || crumbs.length - 1 === index ? null : (
                  <ChevronRightIcon width={18} />
                )}
              </Fragment>
            ) : !crumb.path && !crumb.onClick ? (
              <ActiveText key={`crumb-no-path-or-click-${index}`}>
                {crumb.text}
              </ActiveText>
            ) : (
              <Fragment key={`crumb-with-click-${index}`}>
                {icon ? icon : null}
                <LinkText
                  name={crumb.text}
                  isSelected={lastLink === index}
                  onClick={crumb.onClick}
                  style={{ marginLeft: icon ? "0.6rem" : "0rem" }}
                />
                {crumb[index] === 1 || crumbs.length - 1 === index ? null : (
                  <ChevronRightIcon width={18} />
                )}
              </Fragment>
            )
          )}
        </Breadcrumbs>
      </CrumbSection>
      <ButtonsSection>
        {buttons.map((button, index) => (
          <div style={{ display: "flex" }} key={`breadcrumb-button-${index}`}>
            {button}
            {buttons.length === index + 1 ? null : (
              <div style={{ marginRight: "1rem" }} />
            )}
          </div>
        ))}
      </ButtonsSection>
    </BreadWrapper>
  );
};
