import { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import { useGetRequestQuery } from "../../../redux/reduxQuery/requestApi";
import {
  useGetProviderConnectionsQuery,
  useGetProviderQuery,
  useSearchProvidersByName,
  useUpdateProviderMutation,
} from "../../../redux/reduxQuery/medicalProviderApi";
import { Breadcrumb } from "../../../components/molecules/Breadcrumb";
import {
  Button,
  EmptyModal,
  Modal,
  SideNavItem,
  Toggle,
} from "@tyrannosaurustech/ui";
import { useForm } from "react-hook-form";
import Form from "../../../components/organisms/TabbedForm";
import UntabbedForm from "../../../components/organisms/Form";
import { useUpdateRequestMutation } from "../../../redux/reduxQuery/requestApi";
import useToast from "../../../hooks/useToast";
import { useMutationWrapper } from "../../../common/api";
import {
  FirmForm,
  GeneralRequestForm,
  PatientForm,
} from "../../../common/forms/request";
import { CardWrapper } from "../../../components/atoms/Card";
import { Theme } from "../../../components/atoms/theme";
import {
  RequestTypeCertifiedMapping,
  RequestTypeCertifiedLabelMapping,
  RequestTypeIsSentMapping,
  RequestTypeLabelMapping,
  RequestTypeNotesMapping,
} from "../../../components/atoms/RequestTypes";
import {
  useGetMedicalRequestQuery,
  useCreateMedicalRequestMutation,
  useDeleteMedicalRequestMutation,
} from "../../../redux/reduxQuery/requestApi";
import searchIcon from "../../../components/assets/search.svg";
import CreateRequestTypeForm from "../../../components/organisms/CreateRequestTypeForm";
import { ParagraphS, TitleS } from "../../../components/atoms/Typography";
import { Shimmer } from "../../../components/organisms/LoadingForm";
import { TextArea } from "../../../components/atoms/TextArea";
import { ConnectionCards } from "../../../components/organisms/MedicalProviderConnections";
import { keyBy } from "lodash";
import { CheckCircleIcon, ExclamationIcon } from "@heroicons/react/outline";
import { skipToken } from "@reduxjs/toolkit/dist/query";
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: #f1f3f5;
  // This is the height of the header
  min-height: calc(100vh - 5.25rem);
  overflow: scroll;
  padding-bottom: 2.5rem;
`;

const SectionWrapper = styled.div<{ width?: any; style?: any }>`
  width: ${({ width }: any) => width || "100%"};
  height: 75vh;
  display: flex;
  align-items: center;
  align-items: stretch;
`;

const MedicalProviderSectionWrapper = styled(CardWrapper)`
  width: 75%;
  margin: 1rem;
  height: 100%;
  display: flex;
  flex-direction: row;
`;
const DivideLine = styled.div`
  display: flex;
  border: 1px solid ${Theme.colors.neutrals_400};
  border-radius: 9999px;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
`;

const MedicalRequestHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 1rem;
  margin-left: 1rem;
  padding: 0.3rem;
  padding-left: 0;
`;

const MedicalProviderWrapper = styled.div`
  justify-content: space-between;
  padding: 1rem 1rem 1rem 0;
  flex-grow: 30;
  padding: 0.3rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const MedicalProviderHeader = styled.div`
  justify-content: space-between;
  margin-top: 1rem;
`;

const MedicalProviderConnectionsHeader = styled.div`
  justify-content: space-between;
  margin-top: 1rem;
`;
export const useSetRequestFields = (request, form) => {
  useEffect(() => {
    if (request) {
      Object.keys(request).map((k) => {
        switch (k) {
          case "patient":
            const patient = {
              fullAddress: `${request[k]?.address}. ${request[k]?.city}, ${request[k]?.state} ${request[k]?.zip}`,
              ...request[k],
            };
            form.setValue("patient", patient);
            break;
          case "provider":
            break;
          default:
            if (
              !RequestTypeLabelMapping[k] &&
              !RequestTypeCertifiedLabelMapping[k]
            ) {
              //check if request[k] is a string that is a valid date. If it is set the form to local en date mm/dd/yyyy
              if (!isNaN(Date.parse(request[k])) && k === "submittedDate") {
                const date = new Date(Date.parse(request[k]));
                form.setValue(k, date.toLocaleDateString("en-US"));
              } else form.setValue(k, request[k]);
            }
        }
        return true;
      });
    }
  }, [form, request]);
};
export const useSetProviderFields = (provider, form) => {
  useEffect(() => {
    form.setValue("provider", provider);
  }, [form, provider]);
};

export const useSetRequestTypeFields = (
  requestType,
  form,
  setCurrentRequestType,
  currentRequestType
) => {
  useEffect(() => {
    if (requestType) {
      // Set all the existing request type in form to true
      requestType.forEach((type) => {
        if (RequestTypeLabelMapping[type.requestType]) {
          form.setValue(type.requestType, true);
          form.setValue(
            RequestTypeCertifiedMapping[type.requestType],
            type?.certified
          );
          form.setValue(
            RequestTypeIsSentMapping[type.requestType],
            type.isSent
          );
          form.setValue(
            RequestTypeNotesMapping[type.requestType],
            type.requestNotes
          );
        }
      });
    }

    // Set all other value to false
    Object.keys(RequestTypeLabelMapping).forEach((k) => {
      if (!form.getValues(k)) {
        form.setValue(k, false);
        form.setValue(RequestTypeCertifiedMapping[k], false);
        form.setValue(RequestTypeIsSentMapping[k], false);
      }
    });

    if (
      (requestType?.length > 0 && !currentRequestType) ||
      requestType[0]?.requestId !== currentRequestType?.requestId
    ) {
      const requestTypeMap: {
        [key: string]: {
          id: string;
          requestType: string;
          isSent: boolean;
          requestNotes: string | null;
          createdByLawFirm: boolean | null;
        };
      } = keyBy(requestType, "requestType");

      const requestTypeWithNulls = Object.keys(RequestTypeLabelMapping).map(
        (requestType) => {
          return requestTypeMap?.[requestType];
        }
      );
      const requestTypeSorted = requestTypeWithNulls.filter(
        (requestType) => requestType
      );
      setCurrentRequestType(requestTypeSorted[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, requestType, setCurrentRequestType]);
};

export default function EditRequest() {
  const { id } = useParams();
  let navigate = useNavigate();
  const toast = useToast();
  const { data: request = {}, isLoading, refetch } = useGetRequestQuery(id);
  const {
    data: requestType = [],
    isLoading: isMedicalRequestLoading,
    refetch: refetchMedRequest,
  } = useGetMedicalRequestQuery(id);
  const { data: provider } = useGetProviderQuery(
    request?.provider?.id ?? skipToken
  );
  const { data: providerConnection, isLoading: isProviderLoading } =
    useGetProviderConnectionsQuery(request?.provider?.id ?? skipToken);
  const [updateRequest] = useUpdateRequestMutation();
  const [updateProvider] = useUpdateProviderMutation();
  const [createRequestType] = useCreateMedicalRequestMutation();
  const [deleteRequestType] = useDeleteMedicalRequestMutation();
  const searchProviders = useSearchProvidersByName();
  const [openRequestTypeModal, setOpenRequestTypeModal] = useState(false);
  const [currentRequestType, setCurrentRequestType] =
    useState<{
      id: string;
      requestType: string;
      isSent: boolean;
      requestNotes: string | null;
      createdByLawFirm: boolean | null;
    } | null>(null);
  const form = useForm();
  const changeProviderForm = useForm();
  const mutate = useMutationWrapper(
    updateRequest,
    `Changes have been saved successfully`,
    () => {}
  );

  const mutateProvider = useMutationWrapper(
    updateProvider,
    `Changes have been saved successfully`,
    () => {}
  );

  const mutateCreateMedicalRequestType = useMutationWrapper(
    createRequestType,
    `Changes have been saved successfully`,
    () => {}
  );

  const mutateDeleteRequestType = useMutationWrapper(
    deleteRequestType,
    "Changes have been saved successfully",
    () => {}
  );
  const [processing, setProcessing] = useState(false);
  const useSubmitChangeProvider = changeProviderForm.handleSubmit(
    async (data) => {
      await mutate({
        ...request,
        firm: null,
        package: null,
        provider: null,
        patient: null,
        providerId: data.provider.id,
      });
      setChangeProvider(false);
    }
  );
  const isPageLoading =
    isLoading || isMedicalRequestLoading || isProviderLoading || processing;
  const [activeTab, setActiveTab] = useState("Request Info");

  const columnsByCategory = {
    "Request Info": GeneralRequestForm,
    "Patient Details": {
      ...PatientForm,
      ...FirmForm,
      inputs: [
        ...PatientForm.inputs,
        {
          id: "patient.id",
          label: "Go To Patient",
          button: true,
          buttonLabel: "Go To Patient",
          onClick: () => navigate(`/admin/patients/${request?.patient?.id}`),
          divideLine: true,
        },
        ...FirmForm.inputs,
        {
          id: "firm.id",
          label: "Go To Law Firm",
          button: true,
          buttonLabel: "Go To Law Firm",
          onClick: () => navigate(`/admin/law-firms/${request?.firm?.id}`),
        },
      ],
    },

    Firm: {
      ...FirmForm,
      inputs: [
        ...FirmForm.inputs,
        {
          id: "firm.id",
          label: "Go To Law Firm",
          button: true,
          buttonLabel: "Go To Law Firm",
          onClick: () => navigate(`/admin/law-firms/${request?.firm?.id}`),
        },
      ],
    },
  };

  const requestTypeMap: {
    [key: string]: {
      id: string;
      requestType: string;
      isSent: boolean;
      requestNotes: string | null;
      createdByLawFirm: boolean | null;
    };
  } = keyBy(requestType, "requestType");

  const requestFromTabsHeader = [
    { text: "Request Info", disabled: false },
    { text: "Patient Details", disabled: false },
  ];
  const onSubmit = form.handleSubmit(async (data) => {
    setProcessing(true);
    if (data?.medicalProviderChanges) {
      await Object.keys(data.medicalProviderChanges).forEach(
        async (provider) => {
          if (data.medicalProviderChanges[provider]) {
            await mutateProvider({
              id: provider,
              notes: data.medicalProviderChanges[provider].senderNotes,
            });
          }
        }
      );
    }
    const medicalRequests = Object.keys(RequestTypeLabelMapping)
      .map((request) => {
        if (data[request]) {
          return {
            id: requestTypeMap[request]?.id,
            requestType: request,
            requestNotes: data[RequestTypeNotesMapping[request]],
            isSent: data[RequestTypeIsSentMapping[request]],
            certified: data[RequestTypeCertifiedMapping[request]],
            createdByLawFirm: false,
          };
        }
        return null;
      })
      .filter((request) => request);

    const connections =
      data?.medicalProviderConnectionChanges &&
      Object.keys(data?.medicalProviderConnectionChanges ?? {})?.map(
        (connectionId) => {
          return {
            toId: connectionId,
            fromId: data.providerId,
            senderNotes:
              data.medicalProviderConnectionChanges[connectionId].senderNotes,
            connectionNotes:
              data.medicalProviderConnectionChanges[connectionId]
                .connectionNotes,
          };
        }
      );
    await mutate({
      ...request,
      ...data,
      status: data.status,
      patient: null,
      firm: null,
      provider: null,
      providerId: data.provider?.id || data.providerId,
      medicalRequests,
      connections,
    }).then((res) => {
      toast.success("Changes have been saved successfully");
      refetch();
    });
    setProcessing(false);
  });

  const onSubmitRequest = async (form) => {
    const values = form.getValues();
    const newRequestTypes: {
      requestType: string;
      certified: boolean;
      isSent: boolean;
    }[] = [];

    const legacyRequestType: {
      [key: string]: boolean;
    } = {};

    const deleteRequestType: string[] = [];
    Object.keys(RequestTypeCertifiedMapping).forEach((requestType) => {
      const certified = !!values[RequestTypeCertifiedMapping[requestType]];
      if (values[requestType]) {
        legacyRequestType[requestType] = true;
        legacyRequestType[RequestTypeCertifiedMapping[requestType]] = certified;
        newRequestTypes.push({
          requestType,
          certified: certified,
          isSent: requestTypeMap[requestType]?.isSent ?? false,
        });
      }

      if (!values[requestType]) {
        legacyRequestType[requestType] = false;
        legacyRequestType[RequestTypeCertifiedMapping[requestType]] = false;
        deleteRequestType.push(requestType);
      }
    });

    await mutateCreateMedicalRequestType({
      requestId: values.id,
      newRequestTypes,
    }).then(() => {
      mutateDeleteRequestType({
        id: values.id,
        requestType: { requestType: deleteRequestType },
      }).then(() => {
        refetchMedRequest();
      });
    });
  };
  useSetRequestFields(request, form);
  useSetRequestTypeFields(
    requestType,
    form,
    setCurrentRequestType,
    currentRequestType
  );
  useSetProviderFields(provider, form);
  const crumbLinks = [
    {
      text: "Requests",
      path: `/admin/requests`,
    },
    {
      text: `Request for ${request?.patient?.name}`,
    },
  ];

  // Sort request type by the order of the RequestTypeLabelMapping
  const requestTypeWithNulls = Object.keys(RequestTypeLabelMapping).map(
    (requestType) => {
      return requestTypeMap?.[requestType];
    }
  );
  const requestTypeSorted = requestTypeWithNulls.filter(
    (requestType) => requestType
  );
  const isCertified = currentRequestType
    ? form.watch(
        RequestTypeCertifiedMapping[currentRequestType?.requestType ?? ""]
      )
    : false;

  const [changeProvider, setChangeProvider] = useState(false);

  return (
    <>
      <EmptyModal
        title="Change Medical Provider"
        onConfirm={useSubmitChangeProvider}
        confirmText="Save"
        open={changeProvider}
        setOpen={setChangeProvider}
        panelClassName="overflow-visible w-[37.5rem]"
      >
        <div>
          <div
            className={`rounded-lg border-[1px] border-[${Theme.colors.neutrals_200}] flex flex-row items-start p-[1rem] mb-[1rem]`}
          >
            <ExclamationIcon
              className={`h-[1.5rem] w-[4.5rem] text-danger-500 m-2 mt-0`}
            ></ExclamationIcon>
            <ParagraphS className="text-danger-500">
              WARNING: Please contact Lori or Terry before changing a provider,
              as this can result in irreversible changes and lead to the loss of
              important information for the request.
            </ParagraphS>
          </div>
          <ParagraphS className="text-neutral-500">
            Please choose the medical provider you wish to associate with this
            request.
          </ParagraphS>
          <UntabbedForm
            {...changeProviderForm}
            onSubmit={useSubmitChangeProvider}
            customSubmit={true}
            cancelAction={undefined}
            numberOfColumns={1}
            style={{
              padding: "1rem 0 0 0",
              boxShadow: "none",
              margin: 0,
              marginBottom: "-2.25rem",
            }}
            formInputs={[
              {
                id: "provider",
                label: "Medical Provider",
                required: true,
                copy: false,
                select: true,
                asyncConfig: {
                  loadOptions: searchProviders,
                  getOptionLabel: (p: any) => p.name,
                  getOptionValue: (p: any) => p.id,
                  placeholder: "Search Medical Providers",
                },
                img: searchIcon,
                detailed: true,
              },
            ]}
          />
        </div>
      </EmptyModal>
      <Wrapper>
        <div style={{ width: "85%", marginTop: "1.50rem" }}>
          <Breadcrumb
            crumbs={crumbLinks}
            buttons={[
              <Button
                onClick={() => {
                  onSubmit();
                  navigate(-1);
                }}
                label="Save and Exit"
                base="secondary"
                loading={isPageLoading}
                size="small"
                btnContainerClasses="mr-4 px-[2.5rem] bg-white"
              />,
              <Button
                onClick={() => {
                  onSubmit();
                }}
                label="Save Changes"
                loading={isPageLoading}
                size="small"
                btnContainerClasses="mr-4 px-[2.5rem]"
              />,
            ]}
          />
          <SectionWrapper>
            <MedicalProviderSectionWrapper id="MedicalProviderSectionWrapper">
              {isMedicalRequestLoading ? (
                <Shimmer label={"Request Type"} />
              ) : (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexGrow: 1,
                      flexDirection: "column",
                      justifyContent: "space-between",
                      paddingTop: ".5rem",
                      paddingBottom: ".5rem",
                      maxWidth: "13.125rem",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexGrow: 1,
                        flexDirection: "column",
                        gap: "1rem",
                      }}
                    >
                      {requestTypeSorted.map((type) => {
                        return (
                          <div className="relative">
                            <SideNavItem
                              text={RequestTypeLabelMapping[type.requestType]}
                              onClick={() => {
                                type &&
                                  setCurrentRequestType({
                                    id: type?.id,
                                    requestType: type.requestType,
                                    createdByLawFirm:
                                      type?.createdByLawFirm ?? false,
                                    isSent: form.getValues(
                                      RequestTypeIsSentMapping[type.requestType]
                                    ),
                                    requestNotes:
                                      form.getValues(
                                        RequestTypeNotesMapping[
                                          type.requestType
                                        ]
                                      ) ?? "",
                                  });
                              }}
                              currentPage={currentRequestType?.id === type.id}
                              classes={{
                                parentDivClasses: "!w-[100%]",
                                activeClasses:
                                  "bg-primary-500 !text-white hover:!bg-primary-700",
                                textClasses: "!max-w-[18rem]",
                              }}
                            ></SideNavItem>
                            {form.getValues(
                              RequestTypeIsSentMapping[type.requestType]
                            ) && (
                              <CheckCircleIcon className="w-[1.5rem] h-[1.5rem] absolute right-[.5rem] top-[50%] translate-y-[-50%] text-success-500"></CheckCircleIcon>
                            )}
                          </div>
                        );
                      })}
                    </div>
                    <div style={{ display: "contents" }}>
                      <Button
                        base="secondary"
                        label="Add Request Type"
                        icon="PlusIcon"
                        size="small"
                        onClick={() =>
                          !isMedicalRequestLoading
                            ? setOpenRequestTypeModal(true)
                            : {}
                        }
                        btnContainerClasses="px-[5rem]"
                      />
                    </div>
                  </div>
                  <DivideLine style={{ flexGrow: 0, margin: "0 0 0 1rem" }} />
                  <MedicalProviderWrapper>
                    {currentRequestType && (
                      <MedicalRequestHeader>
                        <TitleS>
                          {
                            RequestTypeLabelMapping[
                              currentRequestType?.requestType || ""
                            ]
                          }
                        </TitleS>
                        <Toggle
                          label="Mark as sent"
                          enabled={
                            currentRequestType
                              ? form.watch(
                                  RequestTypeIsSentMapping?.[
                                    currentRequestType?.requestType ?? ""
                                  ]
                                )
                              : false
                          }
                          onChange={() => {
                            if (currentRequestType) {
                              const isSentValue = form.getValues(
                                RequestTypeIsSentMapping[
                                  currentRequestType?.requestType
                                ]
                              );
                              form.setValue(
                                RequestTypeIsSentMapping[
                                  currentRequestType.requestType
                                ],
                                !isSentValue
                              );
                            }
                          }}
                          isRight={true}
                        />
                      </MedicalRequestHeader>
                    )}
                    <div
                      style={{
                        height: "100%",
                        overflowY: "scroll",
                        overflowX: "hidden",
                        marginTop: "1rem",
                        paddingRight: "1rem",
                        paddingLeft: "1rem",
                      }}
                    >
                      {currentRequestType && (
                        <div>
                          <div
                            className={`${
                              isCertified ? "bg-danger-100" : "bg-neutral-50"
                            } px-[1rem] py-[.5rem] rounded-xl`}
                          >
                            <Toggle
                              label={
                                isCertified
                                  ? "This request must be certified."
                                  : "This request doesn’t need to be certified."
                              }
                              color={isCertified ? "danger" : "neutral"}
                              disabled={
                                currentRequestType?.createdByLawFirm ?? false
                              }
                              enabled={
                                currentRequestType
                                  ? form.watch(
                                      RequestTypeCertifiedMapping[
                                        currentRequestType?.requestType ?? ""
                                      ]
                                    )
                                  : false
                              }
                              onChange={() => {
                                if (currentRequestType) {
                                  const isCertified = form.getValues(
                                    RequestTypeCertifiedMapping[
                                      currentRequestType.requestType
                                    ]
                                  );
                                  form.setValue(
                                    RequestTypeCertifiedMapping[
                                      currentRequestType.requestType
                                    ],
                                    !isCertified
                                  );
                                }
                              }}
                              isRight={true}
                              labelClasses={`text-pg-sm ${
                                isCertified
                                  ? "text-danger-500"
                                  : "text-neutral-500"
                              }`}
                            />
                          </div>
                          <div className="mt-2">
                            <TextArea
                              noLabel={true}
                              fullScreen={true}
                              labelText={`${
                                currentRequestType
                                  ? RequestTypeLabelMapping?.[
                                      currentRequestType?.requestType ?? ""
                                    ] + " "
                                  : ""
                              }Notes (Rayna Team Only)`}
                              required={false}
                              placeholderText={"Input Text"}
                              width="100%"
                              style={{
                                maxHeight: "5.4rem",
                              }}
                              value={currentRequestType?.requestNotes ?? ""}
                              onChange={(e) => {
                                setCurrentRequestType(
                                  (prev) =>
                                    prev && {
                                      ...prev,
                                      requestNotes: e.target.value,
                                    }
                                );
                                if (currentRequestType) {
                                  form.setValue(
                                    RequestTypeNotesMapping[
                                      currentRequestType?.requestType
                                    ],
                                    e.target.value
                                  );
                                }
                              }}
                            />
                          </div>
                        </div>
                      )}
                      {request?.provider && (
                        <MedicalProviderHeader>
                          <div className="flex flex-row justify-between items-center">
                            <TitleS className="mb-[0.5rem]">
                              Medical Provider Info
                            </TitleS>
                            <Button
                              base="secondary"
                              label="Change Provider"
                              icon="PencilIcon"
                              size="small"
                              onClick={() => setChangeProvider(true)}
                            />
                          </div>
                          <ConnectionCards
                            connection={provider}
                            setValue={form.setValue}
                            getValue={form.getValues}
                            changeId={"medicalProviderChanges"}
                            openEditModal={() => {}}
                            requestType={currentRequestType?.requestType}
                            noWarning={true}
                            isConnection={false}
                          />
                        </MedicalProviderHeader>
                      )}
                      {providerConnection?.filter(
                        (connections) =>
                          connections.requestType ===
                          currentRequestType?.requestType
                      )?.length > 0 && (
                        <MedicalProviderConnectionsHeader>
                          <TitleS className="mb-[0.5rem]">
                            Medical Provider Connections
                          </TitleS>
                          {providerConnection
                            ?.filter(
                              (connections) =>
                                connections.requestType ===
                                currentRequestType?.requestType
                            )
                            ?.map((connection) => {
                              return (
                                <ConnectionCards
                                  connection={connection}
                                  setValue={form.setValue}
                                  getValue={form.getValues}
                                  changeId={"medicalProviderConnectionChanges"}
                                  openEditModal={() => {}}
                                  requestType={currentRequestType?.requestType}
                                />
                              );
                            })}
                        </MedicalProviderConnectionsHeader>
                      )}
                    </div>
                  </MedicalProviderWrapper>
                </>
              )}
            </MedicalProviderSectionWrapper>
            <Form
              {...form}
              onSubmit={onSubmit}
              formInputs={[columnsByCategory[activeTab]]}
              numberOfColumns={2}
              submitButtonText={"Submit Request"}
              customSubmit={true}
              cancelAction={undefined}
              loading={isLoading}
              style={{
                minWidth: "26.25rem",
                width: "25%",
                margin: "1rem",
                height: "100%",
                overflowY: "scroll",
              }}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              tabHeader={requestFromTabsHeader}
            />
          </SectionWrapper>
          <Modal
            title=""
            onConfirm={() => {
              onSubmitRequest(form);
              setOpenRequestTypeModal(false);
            }}
            confirmText="Save"
            swapButtons={true}
            open={openRequestTypeModal}
            setOpen={setOpenRequestTypeModal}
            panelClassName="!w-[57.75rem] !max-w-5xl h-[31.5rem]"
          >
            <div>
              <CreateRequestTypeForm
                requestType={requestType}
                setOpen={setOpenRequestTypeModal}
                {...form}
              />
            </div>
          </Modal>
        </div>
      </Wrapper>
    </>
  );
}
