import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, ButtonOutline } from "../../../components/atoms/Button";
import { SaveIcon, TrashIcon } from "@heroicons/react/outline";
import { Theme } from "../../../components/atoms/theme";
import { Breadcrumb } from "../../../components/molecules/Breadcrumb";
import { ContentWrapper } from "../../../components/ContentWrapper";
import { BreadcrumbContainer } from "./style";
import Form from "../../../components/organisms/Form";
import { useForm } from "react-hook-form";
import { patientsForm } from "./constants";
import { TitleM } from "../../../components/atoms/Typography";
import {
  useGetPatientQuery,
  useUpdatePatientMutation,
  useDeletePatientMutation,
} from "../../../redux/reduxQuery/patientApi";
import { useDeletePatientFormMutation } from "../../../redux/reduxQuery/patientFormApi";
import { ModalWithTwoButtons } from "../../../components/molecules/Modal";
import { useNavigate } from "react-router-dom";
import { useMutationWrapper } from "../../../common/api";
import { UploadFVButton } from "../../../common/fv";
import { formatSSN } from "../../../common/format";

export default function EditPatient() {
  let navigate = useNavigate();
  const { id } = useParams();
  const [isProcessing, setIsProcessing] = useState(false);
  const [isDeletingPatient, setIsDeletingPatient] = useState(false);
  const [isDeletingFile, setIsDeletingFile] = useState(false);
  const {
    data: selectedPatient,
    isLoading,
    refetch,
  } = useGetPatientQuery(id, { refetchOnMountOrArgChange: true });
  const [updatePatient] = useUpdatePatientMutation();
  const [openModal, setOpenModal] = useState(false);
  const [openModalFileDelete, setOpenModalFileDelete] = useState(false);
  const [selectedFile, setSelectedFile] = useState<any>({});
  const [initValue, setInitValue] = useState({});
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [deletePatientMutation] = useDeletePatientMutation();
  const form = useForm();
  const crumbLinks = {
    crumbs: [
      {
        text: "Patients",
        onClick: () => validForm(),
      },
      {
        text: selectedPatient?.name,
      },
    ],
    buttons: [
      <UploadFVButton
        type="patient"
        record={selectedPatient}
        modalTitle="Upload Patient to FileVine"
        modalContent={`Are you sure you want to upload ${selectedPatient?.name}'s information to FileVine?`}
        refetch={refetch}
      />,
      <Button
        icon={true}
        color={Theme.colors.primaries_000}
        hoverColor={Theme.colors.primaries_neg100}
        noText={true}
        buttonText="save changes"
        loader={isProcessing}
        img={<SaveIcon width={"1.25rem"} color={Theme.colors.neutrals_500} />}
        onClick={() => onSubmit()}
      />,
      <ButtonOutline
        icon={true}
        color={Theme.colors.primaries_000}
        hoverColor={Theme.colors.primaries_neg100}
        noText={false}
        img={<TrashIcon width={"1.25rem"} color={Theme.colors.primaries_000} />}
        onClick={() => setOpenModalDelete(true)}
      />,
    ],
  };

  const validForm = () => {
    const keys1 = Object.keys(initValue);
    const notAffectedFields = ["firm", "forms"];
    for (let key of keys1) {
      if (!notAffectedFields.includes(key)) {
        let val = form.getValues(key);
        if (initValue[key] === null && val !== initValue[key]) {
          setOpenModal(true);
          return;
        } else if (initValue[key] !== null) {
          if (typeof val === "object" && initValue[key] !== val?.value) {
            setOpenModal(true);
            return;
          } else if (typeof val === "string" && initValue[key] !== val) {
            setOpenModal(true);
            return;
          }
        }
      }
    }
    navigate("/admin/patients");
  };
  const [deletePatientForm] = useDeletePatientFormMutation();
  const mutateDeletePatient = useMutationWrapper(
    deletePatientMutation,
    "Patient deleted succesfully",
    () => {
      setIsDeletingPatient(false);
      setOpenModalDelete(false);
      navigate("/admin/patients");
    }
  );
  const deletePatient = () => {
    setIsDeletingPatient(true);
    mutateDeletePatient(selectedPatient?.id);
  };

  const getInfoSelectedProvider = useCallback(() => {
    if (selectedPatient) {
      Object.keys(selectedPatient).forEach((item) => {
        if (item === "ssn") {
          form.setValue(item, formatSSN(selectedPatient[item]));
        } else if (item === "dateOfBirth" && selectedPatient[item]) {
          const date = new Date(selectedPatient[item]);
          form.setValue(item, date.toLocaleDateString("en-US"));
        } else {
          form.setValue(item, selectedPatient[item]);
        }
      });
      const hipaaAuthorizationFiles = selectedPatient.forms.filter(
        (f) => f.type === "hipaaAuthorization"
      );
      const otherFiles = selectedPatient.forms.filter(
        (f) => f.type === "other"
      );
      form.setValue("hipaaAuthorizationFile", hipaaAuthorizationFiles);
      form.setValue("otherFiles", otherFiles);
    }

    setInitValue(selectedPatient);
  }, [selectedPatient, form]);

  useEffect(() => {
    getInfoSelectedProvider();
  }, [getInfoSelectedProvider]);

  const mutate = useMutationWrapper(
    updatePatient,
    `Changes have been saved successfully`,
    () => {
      setIsProcessing(false);
      navigate(-1);
    }
  );

  const onSubmit = form.handleSubmit((data) => {
    setIsProcessing(true);
    const patientFormData = data;
    patientFormData.state = patientFormData.state?.value;
    patientFormData.id = selectedPatient.id;
    return mutate(patientFormData);
  });

  const onFileDelete = (file: { id: string; name: string }) => {
    setSelectedFile(file);
    setOpenModalFileDelete(true);
  };

  return (
    <>
      {openModal && (
        <ModalWithTwoButtons
          title="Unsaved Changes"
          content="Your changes have not been saved. If you leave you will lose all your progress. Are you sure you want to leave without saving?"
          button1Text="Leave"
          button2Text="Stay"
          onClick1={() => navigate("/admin/patients")}
          onClick2={() => setOpenModal(false)}
        />
      )}
      {openModalDelete && (
        <ModalWithTwoButtons
          title="delete patient"
          content={`Are you sure you want to delete ${selectedPatient.name}? If you do you won’t be able to recover it.`}
          button1Text="delete"
          button2Text="cancel"
          loader={isDeletingPatient}
          onClick1={() => deletePatient()}
          onClick2={() => setOpenModalDelete(false)}
        />
      )}
      {openModalFileDelete && (
        <ModalWithTwoButtons
          title="delete file"
          content={`Are you sure you want to delete ${selectedFile.name}? If you do you won’t be able to recover it.`}
          button1Text="delete"
          button2Text="cancel"
          loader={isDeletingFile}
          onClick1={() => {
            setIsDeletingFile(true);
            deletePatientForm(selectedFile.id)
              .unwrap()
              .then(() => {
                setIsDeletingFile(false);
                const files = form.getValues(selectedFile.inputId);
                const index = files.findIndex((f) => f.id === selectedFile.id);
                files.splice(index, 1);
                files.length > 0
                  ? form.setValue(selectedFile.inputId, files)
                  : form.setValue(selectedFile.inputId, "");
                setOpenModalFileDelete(false);
              });
          }}
          onClick2={() => setOpenModalFileDelete(false)}
        />
      )}
      <ContentWrapper height={"100%"}>
        <>
          <BreadcrumbContainer>
            <Breadcrumb
              crumbs={crumbLinks.crumbs}
              buttons={crumbLinks.buttons}
            />
          </BreadcrumbContainer>
          <TitleM>{selectedPatient?.name}</TitleM>
          <Form
            {...form}
            onSubmit={onSubmit}
            formInputs={patientsForm}
            numberOfColumns={2}
            submitButtonText={"Submit Request"}
            customSubmit={true}
            onFileDelete={onFileDelete}
            loading={isLoading}
            cancelAction={() => navigate(-1)}
          />
        </>
      </ContentWrapper>
    </>
  );
}
