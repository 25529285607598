import ShimmerEffect from "react-shimmer-effect";
import { SubtitlesM } from "../atoms/Typography";
import { useEffect, useState } from "react";
export const Shimmer = ({ label }) => {
  const [height, setHeight] = useState(600);
  useEffect(() => {
    //Track the height of the parent element to determine how many shimmer lines to render
    const parentElem = document.getElementById("MedicalProviderSectionWrapper");
    if (parentElem instanceof Element) {
      const resizeObserver = new ResizeObserver((event) => {
        //Account for title height by subtracting 20px
        setHeight(event[0].contentBoxSize[0].blockSize - 20);
      });
      resizeObserver.observe(parentElem);
    }
  });
  return (
    <div style={{ width: "100%", overflow: "hidden" }}>
      <SubtitlesM>{label}</SubtitlesM>
      <ShimmerEffect>
        {
          //Create however many shimmer lines fit in the variable height of the parent element
          Array(height ? Math.floor(height / 40) : 10)
            .fill(0)
            .map((_, i) => (
              <div
                key={`loading-form-${label ?? ""}-${i}`}
                style={{
                  height: "1.5rem",
                  width: "calc(100% - 2rem)",
                  margin: ".5rem 1rem",
                }}
              />
            ))
        }
      </ShimmerEffect>
    </div>
  );
};
