import React, { useState } from "react";
import styled from "styled-components";
import {
  CloudIcon,
  ExclamationCircleIcon,
  CheckCircleIcon,
  XCircleIcon,
} from "@heroicons/react/outline";

import { Theme } from "./theme";
import { ParagraphS } from "./Typography";
import { BadgeProps, BadgeWrapperProps } from "../../types/atoms/badgeTypes";

const BadgeWrapper = styled.div<BadgeWrapperProps>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  padding: 0.25rem 0.75rem;
  background: ${({ color }) => color};
  border-radius: 624.938rem;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  &:hover {
    background: ${({ hovered }) => hovered};
    svg {
      background: ${({ hovered }) => hovered};
    }
  }
`;
const BadgeWrapperOutline = styled(BadgeWrapper)`
  background: none;
  padding: 0.125rem 0.625rem;
  border: 0.125rem solid ${({ color }) => color};
  &:hover {
    border: 0.125rem solid ${({ hovered }) => hovered};
    background: none;
  }
`;
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const Spacer = styled.div`
  margin-right: 0.656rem;
`;
export const Badge = ({
  icon,
  color,
  hoverColor,
  height = "1.75rem",
  img = <CloudIcon width={"0.938rem"} color={Theme.colors.neutrals_500} />,
  badgeText,
  width = "fit-content",
  iconLeft = false,
  onClick,
}: BadgeProps) => {
  return (
    <BadgeWrapper
      color={color}
      height={height}
      width={width}
      hovered={hoverColor}
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
    >
      <ContentWrapper>
        {icon && iconLeft && (
          <div className="flex">
            {img}
            {badgeText && <Spacer />}
          </div>
        )}
        {badgeText && (
          <ParagraphS color={Theme.colors.neutrals_500}>{badgeText}</ParagraphS>
        )}
        {icon && !iconLeft && (
          <div className="flex">
            {badgeText && <Spacer />}
            {img}
          </div>
        )}
      </ContentWrapper>
    </BadgeWrapper>
  );
};

export const BadgeOutline = ({
  style,
  icon,
  color,
  hoverColor,
  img = <CloudIcon width={"0.938rem"} color={color} />,
  badgeText = "Badge Text",
}: BadgeProps) => {
  const [isHovered, setIsHovered] = useState(false);
  const hoveredColor = isHovered ? hoverColor : color;

  return (
    <BadgeWrapperOutline
      style={style}
      color={color}
      hovered={hoverColor}
      onMouseOver={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <ContentWrapper>
        <ParagraphS color={hoveredColor} style={{ margin: 0 }}>
          {badgeText}
        </ParagraphS>
        {icon && (
          <>
            <Spacer />
            {img}
          </>
        )}
      </ContentWrapper>
    </BadgeWrapperOutline>
  );
};

export const BadgeStatus = ({ status, iconLeft = false }) => {
  switch (status) {
    case "verified":
      return (
        <Badge
          icon={true}
          width={"8.25rem"}
          color={Theme.colors.success_000}
          hoverColor={Theme.colors.success_neg100}
          badgeText={"Verified"}
          iconLeft={iconLeft}
          img={
            <CheckCircleIcon
              width={"1rem"}
              color={Theme.colors.neutrals_500}
              style={{ background: "inherit" }}
            />
          }
        />
      );
    case "unverified":
      return (
        <Badge
          icon={true}
          width={"8.25rem"}
          color={Theme.colors.danger_000}
          hoverColor={Theme.colors.danger_neg100}
          badgeText={"Unverified"}
          iconLeft={iconLeft}
          img={
            <XCircleIcon
              width={"1rem"}
              color={Theme.colors.neutrals_500}
              style={{ background: "inherit" }}
            />
          }
        />
      );
    default:
      return (
        <Badge
          icon={true}
          width={"8.25rem"}
          color={Theme.colors.warning_000}
          hoverColor={Theme.colors.warning_neg100}
          badgeText={"Outdated"}
          iconLeft={iconLeft}
          img={
            <ExclamationCircleIcon
              width={"1rem"}
              color={Theme.colors.neutrals_500}
              style={{ background: "inherit" }}
            />
          }
        />
      );
  }
};
