import { UserIcon } from "@heroicons/react/outline";
import React from "react";
import styled from "styled-components/macro";

import { Theme } from "./theme";
import { TitleXS } from "./Typography";
import { AvatarProps, ImgAvatarProps, EmptyAvatarProps} from "../../types/atoms/avatarTypes"

const EmptyAvatar = styled.div<EmptyAvatarProps>`
  border-radius: 50%;
  background: ${({ background }) => background};
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  font-size: ${({ fontSize }) => `${fontSize}px`};
  color: ${({ color }) => color};
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  &:hover {
    background: ${({ hover }) => hover};
  }
  &:active {
    background: ${({ hover }) => hover};
    box-shadow: ${({ theme }) => theme.shadows.dropshadow_accent};
  }
`;

const ImgAvatar = styled.img<ImgAvatarProps>`
  vertical-align: middle;
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  border-radius: 50%;
`;

export const ImageAvatar = ({ size = 32, src, alt }: ImgAvatarProps) => (
  <ImgAvatar size={size} src={src} alt={alt} />
);

export const InitialsAvatar = ({
  size = 32,
  background = Theme.colors.neutrals_000,
  color = Theme.colors.neutrals_500,
  text,
  hover
}: AvatarProps) => {
  const fontSize = size / 2;
  return (
    <EmptyAvatar
      size={size}
      background={background}
      color={color}
      hover={hover}
      fontSize={fontSize}
      style={{cursor: 'pointer'}}
    >
      <TitleXS>{text}</TitleXS>
    </EmptyAvatar>
  );
};

export const IconAvatar = ({
  size = 32,
  background = Theme.colors.neutrals_000,
  color = Theme.colors.neutrals_500,
  icon,
}) => {
  const iconSize = size / 1.75;
  return (
    <EmptyAvatar
      size={size}
      background={background}
      color={color}
    >
      {icon ? (
        React.createElement(icon, {
          width: iconSize,
          height: iconSize,
        })
      ) : (
        <UserIcon width={iconSize} height={iconSize} />
      )}
    </EmptyAvatar>
  );
};
