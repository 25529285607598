import { Dispatch, Fragment, SetStateAction, useEffect, useRef } from "react";
import { useTable, useGlobalFilter, useSortBy, useFilters } from "react-table";
import ShimmerEffect from "react-shimmer-effect";

import styled from "styled-components";
import { ParagraphS } from "../atoms/Typography";
import { Theme } from "../atoms/theme";
import {
  TableWrapper,
  TableStyle,
  TableH,
  TableData,
  HeaderText,
  HeaderWrapper,
  DownArrow,
  UpArrow,
} from "./SharedTableStyles";
import { Tooltip } from "../atoms/Tooltip";
import { getColumnSize } from "../../utils/getColumnSize";

const CUSTOM_RENDER_COLUMNS = ["Actions"];
const NO_SORT_COLUMNS = ["Actions"];

const TableBody = styled.tbody`
  max-height: ${({ style }) => (style?.maxHeight ? style.maxHeight : "50vh")};
  overflow: auto;
  width: 100%;
  display: block;

  &::-webkit-scrollbar {
    background: transparent;
    width: 0px;
  }
`;

const TableWrapperStyled = styled(TableWrapper)`
  margin-bottom: 3.5rem;
`;
const ShimmerShape = styled.div`
  height: calc(2.375 - 1.75rem);
  width: 90%;
`;

const RowCell = styled(ParagraphS)`
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

interface ITable {
  columns: any;
  data: any;
  rowClick?: any;
  tableType?: string;
  setPage?: Dispatch<SetStateAction<number>>;
  totalAmountOfRecords?: number;
  searchByPage?: boolean;
  style?: any;
  onScroll?: any;
  scrollPosition?: number;
}

export const SimpleTable = ({
  columns,
  data,
  rowClick,
  tableType,
  setPage,
  totalAmountOfRecords,
  searchByPage,
  style,
  onScroll,
  scrollPosition,
}: ITable) => {
  const tableInstance = useTable(
    {
      columns,
      data,
      rowClick,
      autoResetSortBy: false,
    },
    useGlobalFilter,
    useFilters,
    useSortBy
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    allColumns,
    setSortBy,
  } = tableInstance;

  const tableRef = useRef<any>(null);

  useEffect(() => {
    let newData: any[] = [];
    allColumns.map((column: any) => {
      newData.push({
        id: column.id,
        label: column.Header,
        type: "text",
        required: false,
        clearButton: true,
        select: !!column.options,
        options: column.options,
        canFilter: column.canFilter,
      });
      return column;
    });
  }, [allColumns]);

  const handleSortBy = (column, setSortBy) => {
    if (NO_SORT_COLUMNS.includes(column.Header)) return;
    const desc =
      column.isSortedDesc === true
        ? undefined
        : !!(column.isSortedDesc === false);

    setSortBy([{ id: column.id, desc }]);

    if (desc === undefined) {
      setSortBy([]);
    }
  };

  return (
    <>
      <TableWrapperStyled style={{ boxShadow: "none" }}>
        <TableStyle {...getTableProps()}>
          <thead style={{ borderBottom: `none` }}>
            {headerGroups.map((headerGroup) => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                {headerGroup.headers.map((column, index) => {
                  return (
                    <TableH
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      onClick={() => handleSortBy(column, setSortBy)}
                      style={{
                        width: getColumnSize(tableType, index),
                        cursor: "pointer",
                      }}
                    >
                      <HeaderWrapper>
                        <HeaderText
                          sorted={column.isSorted}
                          style={{ display: "flex" }}
                        >
                          {column.render("Header")}
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <DownArrow />
                            ) : (
                              <UpArrow />
                            )
                          ) : (
                            ""
                          )}
                        </HeaderText>
                      </HeaderWrapper>
                    </TableH>
                  );
                })}
              </tr>
            ))}
          </thead>
          <TableBody
            {...getTableBodyProps()}
            ref={tableRef}
            style={style}
            onScroll={() => {
              const tableBody = tableRef.current;
              const scrollPosition = tableBody?.scrollTop;
              if (onScroll) {
                onScroll(scrollPosition);
              }
            }}
          >
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    borderBottom: `none`,
                  }}
                >
                  {row.cells.map((cell: any, index) => {
                    const RenderedCell = cell.render("Cell");
                    return CUSTOM_RENDER_COLUMNS.includes(
                      cell.column.Header
                    ) ? (
                      <TableData
                        key={`simple-table-custom-render-${
                          cell.column.header ?? ""
                        }-${index}`}
                        style={{
                          width: getColumnSize(tableType, index),
                        }}
                      >
                        {RenderedCell}
                      </TableData>
                    ) : (
                      <TableData
                        key={`simple-table-data-${
                          cell.column.header ?? ""
                        }-${index}`}
                        style={{
                          width: getColumnSize(tableType, index),
                        }}
                      >
                        {cell.column.includeToolTip ? (
                          <Tooltip
                            text={RenderedCell}
                            position="top"
                            scrollPosition={scrollPosition}
                            background={Theme.colors.neutrals_000}
                          >
                            <RowCell color={Theme.colors.neutrals_100}>
                              {RenderedCell}
                            </RowCell>
                          </Tooltip>
                        ) : (
                          <RowCell color={Theme.colors.neutrals_100}>
                            {RenderedCell}
                          </RowCell>
                        )}
                      </TableData>
                    );
                  })}
                </tr>
              );
            })}
            {data.length === 0 && <ParagraphS>No Data</ParagraphS>}
          </TableBody>
        </TableStyle>
      </TableWrapperStyled>
    </>
  );
};

export const LoadingTable = ({ headers }) => (
  <TableWrapper>
    <TableStyle>
      <thead>
        <tr>
          {headers.map((data, index) => (
            <TableH key={`simple-table-loading-header-${index}`}>
              <HeaderWrapper>
                <HeaderText>{data}</HeaderText>
              </HeaderWrapper>
            </TableH>
          ))}
        </tr>
      </thead>
      <tbody>
        {[1, 2, 3, 4, 5].map(() => (
          <tr>
            {headers.map((_, index) => (
              <td
                style={{ paddingTop: "1.75rem", paddingBottom: "1.75rem" }}
                key={`simple-table-loading-body-${index}`}
              >
                <ShimmerEffect>
                  <ShimmerShape />
                  <ShimmerShape />
                </ShimmerEffect>
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </TableStyle>
  </TableWrapper>
);
