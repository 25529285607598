import { Fragment, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ContentWrapper } from "../../../components/ContentWrapper";
import { Breadcrumb } from "../../../components/molecules/Breadcrumb";
import { TitleM } from "../../../components/atoms/Typography";
import Form from "../../../components/organisms/SectionedForm";
import { useForm } from "react-hook-form";
import { SaveIcon, TrashIcon } from "@heroicons/react/outline";
import { Theme } from "../../../components/atoms/theme";
import { Button, ButtonOutline } from "../../../components/atoms/Button";
import { useCreateProviderMutation } from "../../../redux/reduxQuery/medicalProviderApi";
import { BreadcrumbContainer } from "./style";
import {
  sendInformationByForm,
  medicalProviderForm,
  medicalProviderNotesForm,
} from "../../../common/forms/medicalProviderV2";
import { ModalWithTwoButtons } from "../../../components/molecules/Modal";
import { DataSelectedProvided } from "./types";
import { useUser } from "../../../hooks/useLogin";
import { useMutationWrapper } from "../../../common/api";
export default function CreateMedicalProvider() {
  const user = useUser();
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  let navigate = useNavigate();
  const [createMedicalProvider] = useCreateProviderMutation();
  const [isProcessing, setIsProcessing] = useState(false);
  const location = useLocation();
  const { pathname } = location;
  const state = location.state as DataSelectedProvided;
  const isFromNewRequestForm = (pathname) => pathname === "/admin/requests/new";
  const isFromClientPortal = (pathname) => {
    return pathname?.includes("client");
  };
  const goBackToPreviousScreen = (previousPathname, res?) => {
    return isFromNewRequestForm(previousPathname) && res
      ? navigate(`/admin/requests/new`, {
          state: {
            formData: state?.formData,
            cameFrom: pathname,
            isCreatedNewProvider: true,
            provider: res,
          },
        })
      : isFromClientPortal(previousPathname) && res
      ? navigate(`${previousPathname}`, {
          state: {
            formData: state?.formData,
            cameFrom: "clientPortalNewPatient",
            isCreatedNewProvider: true,
            provider: res,
          },
        })
      : isFromNewRequestForm(previousPathname)
      ? navigate("/admin/requests/new", {
          state: {
            formData: state?.formData,
            cameFrom: pathname,
          },
        })
      : isFromClientPortal(previousPathname)
      ? navigate(`${previousPathname}`, {
          state: {
            formData: state?.formData,
            cameFrom: "clientPortalNewPatient",
          },
        })
      : navigate("/admin/medical-providers");
  };
  const crumbLinks = {
    crumbs: [
      {
        text: "Medical Providers",
        onClick: () => validForm(),
      },
      {
        text: "New Medical Provider",
      },
    ],
    buttons: [
      <ButtonOutline
        icon={false}
        color={Theme.colors.primaries_000}
        hoverColor={Theme.colors.primaries_neg100}
        noText={true}
        buttonText="Cancel"
        img={<TrashIcon width={"1.25rem"} color={Theme.colors.primaries_000} />}
        onClick={() => setOpenCancelModal(true)}
      />,
      <Button
        icon={false}
        color={Theme.colors.primaries_000}
        hoverColor={Theme.colors.primaries_neg100}
        noText={true}
        buttonText="Add New Provider"
        loader={isProcessing}
        img={<SaveIcon width={"1.25rem"} color={Theme.colors.neutrals_500} />}
        onClick={() => onSubmit()}
      />,
    ],
  };
  const form = useForm();

  const validForm = () => {
    const isEmpty =
      Object.values(form.getValues()).filter(
        (item) => item !== "" && item !== null
      ).length === 0;
    isEmpty ? navigate("/admin/medical-providers") : setOpenModal(true);
  };
  const mutate = useMutationWrapper(
    createMedicalProvider,
    (formData) => {
      return `${formData.name} was added successfully`;
    },
    (res) => {
      setIsProcessing(false);
      goBackToPreviousScreen(state?.cameFrom, res);
    }
  );
  const onSubmit = form.handleSubmit((data) => {
    setIsProcessing(true);
    return mutate({
      ...data,
      state: data.state,
      type: data.type,
      sendBillsBy: data.sendBillsBy,
      sendRecordsBy: data.sendRecordsBy,
      status: "unverified",
    });
  });

  const formSections: any = [];
  if (user?.isAdmin()) {
    formSections.push(medicalProviderNotesForm);
  }

  formSections.push(medicalProviderForm);

  if (user?.isAdmin()) {
    formSections.push(sendInformationByForm);
  }

  return (
    <Fragment>
      {openCancelModal && (
        <ModalWithTwoButtons
          title="Cancel new Provider"
          content="Are you sure you want to cancel new medical provider? If you do you will loose all your changes."
          button1Text="Cancel new Provider"
          button2Text="Keep Editing"
          onClick1={() =>
            goBackToPreviousScreen(state?.cameFrom ? state?.cameFrom : pathname)
          }
          onClick2={() => setOpenCancelModal(false)}
        />
      )}
      {openModal && (
        <ModalWithTwoButtons
          title="Unsaved Changes"
          content="Your changes have not been saved. If you leave you will lose all your progress. Are you sure you want to leave without saving?"
          button1Text="Leave"
          button2Text="Stay"
          onClick1={() =>
            goBackToPreviousScreen(state?.cameFrom ? state?.cameFrom : pathname)
          }
          onClick2={() => setOpenModal(false)}
        />
      )}

      <ContentWrapper height={"100%"}>
        <>
          <BreadcrumbContainer>
            <Breadcrumb
              crumbs={crumbLinks.crumbs}
              buttons={crumbLinks.buttons}
            />
          </BreadcrumbContainer>
          <TitleM>{state?.name}</TitleM>

          <Form
            {...form}
            onSubmit={onSubmit}
            initialValue={[]}
            formInputs={formSections}
            numberOfColumns={2}
            submitButtonText={"Submit Request"}
            customSubmit={true}
            cancelAction={undefined}
            style={{ marginBottom: "50px", marginTop: "1rem" }}
          />
        </>
      </ContentWrapper>
    </Fragment>
  );
}
