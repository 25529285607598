import React, { useState, Fragment } from "react";
import styled from "styled-components";
import {
  ClipboardCopyIcon,
  QuestionMarkCircleIcon,
  XIcon,
} from "@heroicons/react/outline";
import ShimmerEffect from "react-shimmer-effect";
import { Tooltip } from "../atoms/Tooltip";
import { RadioGroup } from "../atoms/RadioButton";
import { Theme } from "../atoms/theme";
import { Input } from "../atoms/Input";
import { CustomSelect } from "../atoms/Select";
import FileInput from "../atoms/FileInput";
import { ButtonOutline, Button } from "../atoms/Button";
import { TextArea } from "../atoms/TextArea";
import { CheckboxAndSwitch } from "../molecules/CheckboxAndSwitch";
import { SubtitlesM, ParagraphS } from "../atoms/Typography";
import { states } from "../../common/select/constants";
import { formErrorMessage } from "../../utils/formError";
import { UseFormReturn } from "react-hook-form";
import useToast from "../../hooks/useToast";
import { useUser } from "../../hooks/useLogin";
import { CustomCheckbox } from "../atoms/Checkbox";
interface FormWrapperProps {
  filter?: boolean;
}

interface InputContainerProps {
  filter?: boolean;
}

const FormWrapper = styled.div<FormWrapperProps>`
  background: #fff;
  width: 100%;
  min-width: ${({ style }) => style?.minWidth};
  height: fit-content;
  border-radius: 0.75rem;
  padding: 1.5rem;
  box-shadow: ${({ style }) => style?.boxShadow || Theme.shadows.dropshadow_m};
  box-sizing: border-box;
  margin-bottom: ${({ style }) => style?.marginBottom || "5.625rem"};
`;

const FormContainer = styled.form`
  display: flex;
  width: 100%;
`;

const FormColumnContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const FormColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ width }: { width?: string }) => width || "100%"};
`;

const InputContainer = styled.div<InputContainerProps>`
  display: flex;
  margin-bottom: 2.25rem;
`;

const DivideLine = styled.div`
  display: flex;
  border: 2px solid ${Theme.colors.neutrals_400};
  border-radius: 9999px;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
`;

const DivideNoLine = styled.div`
  display: flex;
  margin-left: 1rem;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${({ align }: { align?: string }) =>
    align || "space-evenly"};
  padding: 0;
`;

const CopyButtonWrapper = styled.div`
  display: flex;
  margin-left: 0.15rem;
  margin-right: 0.3rem;
  margin-top: auto;
`;

const AddNewButtonWrapper = styled.div`
  display: flex;
  margin-left: 0.5rem;
  ${({ autoMargin }: { autoMargin?: any }) => {
    if (!autoMargin["patient"] && !autoMargin["provider"]) {
      return "margin-top: inherit;";
    }
  }}
  height: 2.875rem;
  width: 10rem;
  margin-top: 30px;
`;
const CheckAndSwitchWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 1.5rem;
`;

const ButtonSpacing = styled.div`
  width: 10rem;
`;
const ButtonContainer = styled.div`
  margin-top: 28px;
`;
const Shimmer = ({ label }) => {
  return (
    <div style={{ width: "100%" }}>
      <SubtitlesM>{label}</SubtitlesM>
      <ShimmerEffect>
        <div style={{ height: ".875rem", width: "90%" }} />
      </ShimmerEffect>
    </div>
  );
};

interface IRenderInputConfig {
  input: any;
  errors: any;
  setValue: any;
  register: any;
  getValues: any;
  control: any;
  onFileDelete: any;
  watch: any;
  loading: boolean;
}
const RenderInput = (config: IRenderInputConfig) => {
  const {
    input,
    errors,
    setValue,
    register,
    getValues,
    control,
    onFileDelete,
    watch,
    loading,
  } = config;
  const emailPattern =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const ssnPattern = /^[\dX]{3}-?[\dX]{2}-?[\dX]{4}$/i;
  const datePattern =
    /^(0?[1-9]|1[0-2])\/(0?[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;
  const zipcodePattern = /^\d{5}(?:[-\s]\d{4})?$/;
  const phoneNumberPattern =
    /^(?:\+?(\d{1,3})\s?)?[-.(]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*/;

  const toast = useToast();
  const user = useUser();
  const [checked, setCheckbox] = useState(getValues(input.id) ? true : false);

  const isBeforeToday = (date) => {
    if (!date) {
      return false;
    }
    const today = new Date();
    const dateIsBeforeToday = new Date(date) < today;
    return dateIsBeforeToday ? null : "Date cannot be in the future";
  };

  const renderState = (input, label, formId) => {
    return (
      <>
        <CustomSelect
          {...input}
          {...register(formId, { required: input.required })}
          error={errors[formId]}
          errorText={`Please enter ${label?.toLowerCase()}`}
          label={label}
          id={formId}
          required={input.required}
          options={states}
          setValue={(value) => {
            setValue(formId, value);
          }}
          value={watch(formId)}
          name={input.name}
          register={register(formId, { required: input.required })}
        />
        {input.copy && (
          <CopyButtonWrapper>
            <ButtonOutline
              icon={true}
              color={Theme.colors.primaries_000}
              hoverColor={Theme.colors.primaries_neg100}
              noText={false}
              onClick={async () => {
                await navigator.clipboard
                  .writeText(getValues(formId).label)
                  .then(() => {
                    toast.success("Copied to clipboard");
                  });
              }}
              width={"2.75rem"}
              img={
                <ClipboardCopyIcon
                  width={"0.938rem"}
                  color={Theme.colors.primaries_000}
                />
              }
            />
          </CopyButtonWrapper>
        )}
        {input.addNewInDropDown && (
          <AddNewButtonWrapper autoMargin={errors}>
            <ButtonOutline
              icon={true}
              color={Theme.colors.primaries_000}
              hoverColor={Theme.colors.primaries_neg100}
              noText={true}
              buttonText={"Add New"}
              onClick={() => {
                if (input.addNewAction) {
                  input.addNewAction();
                }
              }}
              img={
                input.addNewIcon ? (
                  input.addNewIcon
                ) : (
                  <ClipboardCopyIcon
                    width={"0.938rem"}
                    color={Theme.colors.primaries_000}
                  />
                )
              }
            />
          </AddNewButtonWrapper>
        )}
        {input.clearButton && (
          <ButtonContainer>
            <ButtonOutline
              icon={true}
              color={Theme.colors.primaries_000}
              hoverColor={Theme.colors.primaries_neg100}
              width={"2.75rem"}
              onClick={() => setValue(formId, undefined)}
              img={
                <XIcon width={"0.938rem"} color={Theme.colors.primaries_000} />
              }
            />
          </ButtonContainer>
        )}
      </>
    );
  };

  const renderZip = (input, label, formId) => {
    return (
      <>
        <Input
          {...register(formId, {
            required: input.required,
            maxLength: 250,
            pattern: zipcodePattern,
          })}
          noLabel={true}
          labelText={label}
          error={errors[formId]}
          errorText={formErrorMessage(
            errors[formId]?.type,
            label,
            input.message
          )}
          required={input.required}
          icon={input.icon}
          img={input.img}
          width={"100%"}
          type={input.type}
          placeholderText={input.placeholder ? input.placeholder : label}
          {...(input.initialValue ? setValue(formId, input.initialValue) : "")}
          setFormValue={(value) => {
            setValue(formId, value);
          }}
          isInnerTooltip={input.isInnerTooltip}
          tooltipText={input.tooltipText}
          disabled={input.disabled}
        />
        {input.copy && (
          <CopyButtonWrapper>
            <ButtonOutline
              icon={true}
              color={Theme.colors.primaries_000}
              hoverColor={Theme.colors.primaries_neg100}
              noText={false}
              onClick={async () => {
                await navigator.clipboard
                  .writeText(getValues(formId))
                  .then(() => {
                    toast.success("Copied to clipboard");
                  });
              }}
              width={"2.75rem"}
              img={
                <ClipboardCopyIcon
                  width={"0.938rem"}
                  color={Theme.colors.primaries_000}
                />
              }
            />
          </CopyButtonWrapper>
        )}
      </>
    );
  };
  const getInputSelected = () => {
    if (watch(input.id)?.value) {
      return watch(input.id)?.value === "Fax"
        ? watch(input.id)?.value + " Number"
        : watch(input.id)?.value;
    } else if (getValues(input.id)) {
      return getValues(input.id)[0].value;
    } else {
      return input.label.replace("By", "To");
    }
  };
  if (input.radio)
    return loading ? (
      <Shimmer label={input.label} />
    ) : (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <SubtitlesM>{input.radioHeader}</SubtitlesM>
        <RadioGroup
          topMargin={10}
          control={control}
          options={input.radioLabels}
          values={input.values}
        />
      </div>
    );
  if (input.conditional) {
    return loading ? (
      <Shimmer label={input.label} />
    ) : (
      <div style={{ display: "block", width: "100%" }}>
        <div style={{ display: "flex", marginBottom: "2.25rem" }}>
          <CustomSelect
            {...input}
            {...register(input.id, { required: input.required })}
            error={errors[input.id]}
            errorText={`Please enter ${input.label?.toLowerCase()}`}
            label={input.label}
            id={input.id}
            required={input.required}
            options={input.options}
            setValue={(value) => {
              setValue(input.id, value);
            }}
            value={watch(input.id)}
            name={input.name}
            register={register(input.id, { required: input.required })}
          />
          {input.copy && (
            <CopyButtonWrapper>
              <ButtonOutline
                icon={true}
                color={Theme.colors.primaries_000}
                hoverColor={Theme.colors.primaries_neg100}
                noText={false}
                onClick={async () => {
                  await navigator.clipboard
                    .writeText(getValues(input.id).value)
                    .then(() => {
                      toast.success("Copied to clipboard");
                    });
                }}
                width={"2.75rem"}
                img={
                  <ClipboardCopyIcon
                    width={"0.938rem"}
                    color={Theme.colors.primaries_000}
                  />
                }
              />
            </CopyButtonWrapper>
          )}
        </div>
        <div style={{ display: "flex" }}>
          <Input
            {...register(input.input, {
              required: input.required,
              maxLength: 250,
              pattern:
                watch(input.id)?.label === "Email"
                  ? emailPattern
                  : watch(input.id)?.label?.toLowerCase().includes("fax")
                  ? phoneNumberPattern
                  : null,
            })}
            noLabel={true}
            labelText={getInputSelected()}
            error={errors[input.input]}
            errorText={formErrorMessage(
              errors[input.input]?.type,
              input.label,
              input.message,
              watch(input.id)?.value
            )}
            required={input.required}
            icon={input.icon}
            img={input.img}
            disabled={input.disabled}
            width={"100%"}
            type={input.type}
            placeholderText={getInputSelected()}
            setFormValue={(value) => {
              setValue(input.input, value);
            }}
            isInnerTooltip={input.isInnerTooltip}
            tooltipText={input.tooltipText}
          />
          {input.copy && (
            <CopyButtonWrapper>
              <ButtonOutline
                icon={true}
                color={Theme.colors.primaries_000}
                hoverColor={Theme.colors.primaries_neg100}
                noText={false}
                onClick={async () => {
                  await navigator.clipboard
                    .writeText(getValues(input.input))
                    .then(() => {
                      toast.success("Copied to clipboard");
                    });
                }}
                width={"2.75rem"}
                img={
                  <ClipboardCopyIcon
                    width={"0.938rem"}
                    color={Theme.colors.primaries_000}
                  />
                }
              />
            </CopyButtonWrapper>
          )}
        </div>
      </div>
    );
  }
  if (input.select || input.type === "state") {
    if (input.type === "state") input.options = states;
    return loading ? (
      <Shimmer label={input.label} />
    ) : (
      <>
        <CustomSelect
          {...input}
          {...register(input.id, { required: input.required })}
          error={errors[input.id]}
          errorText={`Please enter ${input.label?.toLowerCase()}`}
          label={input.label}
          id={input.id}
          required={input.required}
          options={input.options}
          setValue={(value) => {
            setValue(input.id, value);
          }}
          value={watch(input.id)}
          name={input.name}
          register={register(input.id, { required: input.required })}
        />
        {input.copy && (
          <CopyButtonWrapper>
            <ButtonOutline
              icon={true}
              color={Theme.colors.primaries_000}
              hoverColor={Theme.colors.primaries_neg100}
              noText={false}
              onClick={async () => {
                await navigator.clipboard
                  .writeText(getValues(input.id).label)
                  .then(() => {
                    toast.success("Copied to clipboard");
                  });
              }}
              width={"2.75rem"}
              img={
                <ClipboardCopyIcon
                  width={"0.938rem"}
                  color={Theme.colors.primaries_000}
                />
              }
            />
          </CopyButtonWrapper>
        )}
        {input.addNewInDropDown && (
          <AddNewButtonWrapper autoMargin={errors}>
            <ButtonOutline
              icon={true}
              color={Theme.colors.primaries_000}
              hoverColor={Theme.colors.primaries_neg100}
              noText={true}
              buttonText={"Add New"}
              onClick={() => {
                if (input.addNewAction) {
                  input.addNewAction();
                }
              }}
              img={
                input.addNewIcon ? (
                  input.addNewIcon
                ) : (
                  <ClipboardCopyIcon
                    width={"0.938rem"}
                    color={Theme.colors.primaries_000}
                  />
                )
              }
            />
          </AddNewButtonWrapper>
        )}
        {input.clearButton && (
          <ButtonContainer>
            <ButtonOutline
              icon={true}
              color={Theme.colors.primaries_000}
              hoverColor={Theme.colors.primaries_neg100}
              width={"2.75rem"}
              onClick={() => setValue(input.id, undefined)}
              img={
                <XIcon width={"0.938rem"} color={Theme.colors.primaries_000} />
              }
            />
          </ButtonContainer>
        )}
      </>
    );
  }
  if (input.type === "stateAndZip") {
    return loading ? (
      <Shimmer label={input.label} />
    ) : (
      <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
        {renderState(input, input.formLabels[0], input.formIds[0])}
        {renderZip(input, input.formLabels[1], input.formIds[1])}
      </div>
    );
  }
  if (input.textArea)
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          marginRight: "1.50rem",
        }}
      >
        {loading ? (
          <Shimmer label={input.label} />
        ) : (
          <TextArea
            getValues={getValues}
            setValue={setValue}
            name={input.id}
            fullScreen={input.fullScreen}
            register={register(input.id, { required: input.required })}
            noLabel={true}
            labelText={input.label}
            error={errors[input.id]}
            errorText={`Please enter ${input.label?.toLowerCase()}`}
            required={input.required}
            placeholderText={
              input.placeholder ? input.placeholder : input.label || ""
            }
            disabled={input.disabled}
            style={input.style}
            width="100%"
          />
        )}
        {!user?.isAdmin() && (
          <ParagraphS style={{ marginTop: "2.25rem", marginBottom: 0 }}>
            <span style={{ color: Theme.colors.danger_000 }}>NOTE:</span> Please
            check the information on this request is correct. Once your request
            is submitted you wont be able to edit it.
          </ParagraphS>
        )}
      </div>
    );
  if (input.addNew)
    return loading ? (
      <Shimmer label={input.label} />
    ) : (
      <>
        <Input
          noLabel={true}
          labelText={input.label}
          error={errors[input.id]}
          errorText={`Please enter ${input.label?.toLowerCase()}`}
          required={input.required}
          icon={input.icon}
          img={input.img}
          width={"100%"}
          placeholderText={input.placeholder ? input.placeholder : input.label}
          {...register(input.id, { required: input.required })}
        />
        <AddNewButtonWrapper autoMargin={errors}>
          <ButtonOutline
            icon={true}
            color={Theme.colors.primaries_000}
            hoverColor={Theme.colors.primaries_neg100}
            noText={true}
            buttonText={"Add New"}
            onClick={() => {
              if (input.addNewAction) {
                input.addNewAction();
              }
            }}
            img={
              input.addNewIcon ? (
                input.addNewIcon
              ) : (
                <ClipboardCopyIcon
                  width={"0.938rem"}
                  color={Theme.colors.primaries_000}
                />
              )
            }
          />
        </AddNewButtonWrapper>
      </>
    );
  if (input.checkAndSwitch)
    return loading ? (
      <Shimmer label={input.label} />
    ) : (
      <CheckAndSwitchWrapper>
        {input.checkAndSwitchTitleTooltip ? (
          <div style={{ display: "flex" }}>
            <SubtitlesM>Requests</SubtitlesM>
            <div style={{ marginRight: "0.406rem" }} />
            <Tooltip
              position="top"
              background="black"
              style={{ position: "absolute" }}
              text={input.titleTooltipText}
            >
              <QuestionMarkCircleIcon
                width={"1.25rem"}
                color={Theme.colors.neutrals_200}
              />
            </Tooltip>
          </div>
        ) : input.noCheckAndSwitchHeader === true ? (
          <></>
        ) : (
          <div style={{ display: "flex" }}>
            <SubtitlesM>Requests</SubtitlesM>
            <div style={{ marginRight: "0.406rem" }} />
          </div>
        )}

        <CheckboxAndSwitch
          disabled={input.disabled}
          control={control}
          checkLabels={input.checkLabels}
          switchLabel={input.switchLabel}
          tooltipText={input.tooltipText || ""}
          setValue={setValue}
          onEnable={input.onEnable}
        />
      </CheckAndSwitchWrapper>
    );
  if (input.file)
    return loading ? (
      <Shimmer label={input.label} />
    ) : (
      <FileInput
        {...register(input.id, { required: input.required })}
        type={input.type}
        error={errors[input.id]}
        errorText={`Please Enter ${input.label}`}
        initialValues={getValues(input.id)}
        setFormValue={(value) => {
          setValue(input.id, value);
        }}
        onFileDelete={onFileDelete}
        {...{
          config: input,
          errors,
          setValue,
          register,
          getValues,
          control,
        }}
      />
    );

  if (input.checkbox) {
    return loading ? (
      <Shimmer label={input.label} />
    ) : (
      <div style={{ width: "100%", marginBottom: "0rem" }}>
        <CheckboxWrapper>
          <CustomCheckbox
            onChange={() => {
              setValue(input.id, !getValues(input.id));
              setCheckbox(getValues(input.id));
            }}
            checked={checked}
            disabled={false}
            label={input.label}
          />
        </CheckboxWrapper>
        {checked && input.subFields && (
          <InputContainer>
            {input.subFields?.map((field, i) => {
              return (
                <Fragment key={`${input?.label ?? ""}-${field?.id ?? ""}-${i}`}>
                  <Input
                    {...register(field.id, {
                      required: field.required,
                      maxLength: 250,
                      pattern: input.pattern,
                    })}
                    noLabel={true}
                    labelText={field.label}
                    error={errors[field.id]}
                    errorText={formErrorMessage(
                      errors[field.id]?.type,
                      field.label,
                      field.errorText
                    )}
                    required={field.required}
                    icon={input.icon}
                    img={input.img}
                    width={"100%"}
                    copy={field.copy}
                    getValues={getValues}
                    inputId={field.id}
                    type={input.type}
                    placeholderText={
                      field.placeholder ? field.placeholder : field.label
                    }
                    {...(input.initialValue
                      ? setValue(field.id, input.initialValue)
                      : "")}
                    setFormValue={(value) => {
                      setValue(field.id, value);
                    }}
                    isInnerTooltip={input.isInnerTooltip}
                    tooltipText={input.tooltipText}
                    disabled={input.disabled}
                  />
                </Fragment>
              );
            })}
          </InputContainer>
        )}
      </div>
    );
  }
  return (
    <div style={{ width: "100%", display: "flex" }}>
      {loading ? (
        <Shimmer label={input.label} />
      ) : (
        <Input
          {...register(input.id, {
            required: input.required,
            maxLength: 250,
            pattern:
              input?.type === "email"
                ? emailPattern
                : input?.type === "zip"
                ? zipcodePattern
                : input?.type === "ssn"
                ? ssnPattern
                : input?.type === "date" || input?.type === "dateOfBirth"
                ? datePattern
                : input?.type === "phoneNumber"
                ? phoneNumberPattern
                : null,
            validate: input?.type === "dateOfBirth" ? isBeforeToday : null,
          })}
          noLabel={true}
          labelText={input.label}
          error={errors[input.id]}
          errorText={formErrorMessage(
            errors[input.id]?.type,
            input.label,
            input.message || errors[input.id]?.message
          )}
          required={input.required}
          icon={input.icon}
          img={input.img}
          width={"100%"}
          type={input.type}
          placeholderText={input.placeholder ? input.placeholder : input.label}
          {...(input.initialValue
            ? setValue(input.id, input.initialValue)
            : "")}
          setFormValue={(value) => {
            setValue(input.id, value);
          }}
          isInnerTooltip={input.isInnerTooltip}
          tooltipText={input.tooltipText}
          disabled={input.disabled}
        />
      )}
      {input.notFullLength ? <ButtonSpacing /> : null}
      {input.copy && (
        <CopyButtonWrapper>
          <ButtonOutline
            icon={true}
            color={Theme.colors.primaries_000}
            hoverColor={Theme.colors.primaries_neg100}
            noText={false}
            onClick={async () => {
              await navigator.clipboard
                .writeText(getValues(input.id))
                .then(() => {
                  toast.success("Copied to clipboard");
                });
            }}
            width={"2.75rem"}
            img={
              <ClipboardCopyIcon
                width={"0.938rem"}
                color={Theme.colors.primaries_000}
              />
            }
          />
        </CopyButtonWrapper>
      )}
    </div>
  );
};

interface RaynaForm extends UseFormReturn<any, any> {
  formInputs: any;
  numberOfColumns: any;
  cancelAction?: any;
  submitButtonText?: any;
  customSubmit?: boolean;
  onSubmit?: any;
  setValue: any;
  getValues: any;
  control: any;
  style?: any;
  buttonsAlignment?: any;
  onFileDelete?: any;
  filter?: boolean;
  buttonWidth?: string;
  resetFilters?: any;
  loading?: any;
}

function Form({
  formInputs,
  numberOfColumns,
  cancelAction,
  submitButtonText,
  customSubmit,
  register,
  onSubmit,
  setValue,
  getValues,
  control,
  style = {},
  watch,
  buttonsAlignment = "start",
  onFileDelete,
  filter,
  reset,
  buttonWidth = "100%",
  resetFilters,
  setFocus,
  loading,
  ...rest
}: RaynaForm) {
  const [isProcessing, setIsProcessing] = useState(false);

  const errors = rest.formState.errors;
  const handleSubmit = async function (this: any) {
    setIsProcessing(true);
    await onSubmit.apply(this, arguments);
    setIsProcessing(false);
  };

  const FormInput: React.FC<any> = ({ input }) => {
    const style = {
      ...(input.marginBottom && { marginBottom: input.marginBottom }),
    };

    return (
      <InputContainer key={`form-${input.id}`} filter={filter} style={style}>
        {RenderInput({
          input: input,
          register: register,
          errors: errors,
          setValue: setValue,
          getValues: getValues,
          control: control,
          onFileDelete: onFileDelete,
          watch: watch,
          loading: loading,
          // defaultValues: defaultValues,
        })}
      </InputContainer>
    );
  };

  return (
    <FormWrapper style={style} filter={filter}>
      <FormContainer onSubmit={handleSubmit}>
        {numberOfColumns === 1 && (
          <FormColumnContainer>
            <FormColumn width={"100%"}>
              {formInputs.map((input, i) => {
                return (
                  <FormInput input={input} key={`${input?.id ?? ""}-${i}`} />
                );
              })}
              {!customSubmit && (
                <ButtonsWrapper>
                  <ButtonOutline
                    buttonText={"Cancel"}
                    icon={false}
                    color={Theme.colors.primaries_000}
                    hoverColor={Theme.colors.primaries_neg100}
                    noText={true}
                    width={buttonWidth}
                    onClick={cancelAction}
                  />
                  <Button
                    buttonText={submitButtonText}
                    icon={false}
                    color={Theme.colors.primaries_000}
                    hoverColor={Theme.colors.primaries_neg100}
                    noText={true}
                    type={"submit"}
                    width={buttonWidth}
                    loader={isProcessing}
                  />
                </ButtonsWrapper>
              )}
            </FormColumn>
          </FormColumnContainer>
        )}
        {numberOfColumns === 2 && (
          <FormColumnContainer>
            <FormColumn width={"50%"}>
              {formInputs[0].map((input, i) => {
                return (
                  <FormInput input={input} key={`${input?.id ?? ""}-${i}`} />
                );
              })}
            </FormColumn>
            <DivideLine />
            <FormColumn width={"50%"}>
              {formInputs[1].map((input, i) => {
                return (
                  <FormInput input={input} key={`${input?.id ?? ""}-${i}`} />
                );
              })}
              {!customSubmit && (
                <ButtonsWrapper
                  align={buttonsAlignment}
                  style={{ width: "100%", maxHeight: "2.875rem" }}
                >
                  <ButtonOutline
                    style={{ margin: 0 }}
                    buttonText={"Cancel"}
                    icon={false}
                    color={Theme.colors.primaries_000}
                    hoverColor={Theme.colors.primaries_neg100}
                    noText={true}
                    onClick={cancelAction}
                  />
                  <Button
                    buttonText={submitButtonText}
                    icon={false}
                    color={Theme.colors.primaries_000}
                    hoverColor={Theme.colors.primaries_neg100}
                    noText={true}
                    width="48%"
                    loader={isProcessing}
                    type={"submit"}
                  />
                </ButtonsWrapper>
              )}
            </FormColumn>
          </FormColumnContainer>
        )}
        {numberOfColumns === 3 && (
          <FormColumnContainer>
            <FormColumn width={"50%"}>
              {formInputs[0].map((input, i) => {
                return (
                  <FormInput input={input} key={`${input?.id ?? ""}-${i}`} />
                );
              })}
            </FormColumn>
            <DivideLine />
            <FormColumn width={"25%"}>
              {formInputs[1].map((input, i) => {
                return (
                  <FormInput input={input} key={`${input?.id ?? ""}-${i}`} />
                );
              })}
              {!customSubmit && (
                <ButtonsWrapper>
                  <ButtonOutline
                    buttonText={"Cancel"}
                    icon={false}
                    color={Theme.colors.primaries_000}
                    hoverColor={Theme.colors.primaries_neg100}
                    noText={true}
                    onClick={cancelAction}
                  />
                  <div style={{ marginRight: "1rem" }} />
                  <Button
                    buttonText={submitButtonText}
                    icon={false}
                    color={Theme.colors.primaries_000}
                    hoverColor={Theme.colors.primaries_neg100}
                    noText={true}
                    loader={isProcessing}
                    width="48%"
                    type={"submit"}
                  />
                </ButtonsWrapper>
              )}
            </FormColumn>
            <DivideNoLine />
            <FormColumn width={"25%"}>
              {formInputs[2].map((input, i) => {
                return (
                  <FormInput input={input} key={`${input?.id ?? ""}-${i}`} />
                );
              })}
            </FormColumn>
          </FormColumnContainer>
        )}
      </FormContainer>
    </FormWrapper>
  );
}

export default Form;
