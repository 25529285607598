export const RequestType = [
  { id: "requestBills", label: "Bills" },
  { id: "requestRecords", label: "Records" },
  { id: "requestERBilling", label: "ER Physicians Billing" },
  { id: "requestRadiologyBilling", label: "Radiology Billing" },
  { id: "requestAnesthesiaBilling", label: "Anesthesia Billing" },
  { id: "requestHospitalAbstract", label: "Hospital Abstract" },
  { id: "requestRadiology", label: "CD of Radiology Images and Films" },
];

export const RequestTypeLabelMapping = {
  requestBills: "Bills",
  requestRecords: "Records",
  requestERBilling: "ER Physicians Billing",
  requestRadiologyBilling: "Radiology Billing",
  requestAnesthesiaBilling: "Anesthesia Billing",
  requestHospitalAbstract: "Hospital Abstract",
  requestRadiology: "CD of Radiology Images and Films",
};

export const RequestTypeFromLabelMapping = {
  Bills: "requestBills",
  Records: "requestRecords",
  "ER Physicians Billing": "requestERBilling",
  "Radiology Billing": "requestRadiologyBilling",
  "Anesthesia Billing": "requestAnesthesiaBilling",
  "Hospital Abstract": "requestHospitalAbstract",
  "CD of Radiology Images and Films": "requestRadiology",
};

export const RequestTypeCheckboxLabelMapping = {
  requestBills: "Bills",
  requestRecords: "Records",
  requestERBilling: "ER Physicians Billing",
  requestRadiologyBilling: "Radiology Billing",
  requestAnesthesiaBilling: "Anesthesia Billing",
  requestHospitalAbstract: "Hospital Abstract (in lieu of full recs)",
  requestRadiology: "CD of Radiology Images and Films",
};

export const RequestTypeFromCheckboxLabelMapping = {
  Bills: "requestBills",
  Records: "requestRecords",
  "ER Physicians Billing": "requestERBilling",
  "Radiology Billing": "requestRadiologyBilling",
  "Anesthesia Billing": "requestAnesthesiaBilling",
  "Hospital Abstract (in lieu of full recs)": "requestHospitalAbstract",
  "CD of Radiology Images and Films": "requestRadiology",
};

export const RequestTypeCertifiedMapping = {
  requestBills: "requestBillsCertified",
  requestRecords: "requestRecordsCertified",
  requestERBilling: "requestERBillingCertified",
  requestRadiologyBilling: "requestRadiologyBillingCertified",
  requestAnesthesiaBilling: "requestAnesthesiaBillingCertified",
  requestHospitalAbstract: "requestHospitalAbstractCertified",
  requestRadiology: "requestRadiologyCertified",
};

export const RequestTypeIsSentMapping = {
  requestBills: "requestBillsIsSent",
  requestRecords: "requestRecordsIsSent",
  requestERBilling: "requestERBillingIsSent",
  requestRadiologyBilling: "requestRadiologyBillingIsSent",
  requestAnesthesiaBilling: "requestAnesthesiaBillingIsSent",
  requestHospitalAbstract: "requestHospitalAbstractIsSent",
  requestRadiology: "requestRadiologyIsSent",
};

export const RequestTypeCertifiedLabelMapping = {
  requestBillsCertified: "Bills Certified",
  requestRecordsCertified: "Records Certified",
  requestERBillingCertified: "ER Physicians Billing Certified",
  requestRadiologyBillingCertified: "Radiology Certified",
  requestAnesthesiaBillingCertified: "Anesthesia Billing Certified",
  requestHospitalAbstractCertified: "Hospital Abstract Certified",
  requestRadiologyCertified: "CD of Radiology Images and Films Certified",
};

export const RequestTypeNotesMapping = {
  requestBills: "requestBillsNotes",
  requestRecords: "requestRecordsNotes",
  requestERBilling: "requestERBillingNotes",
  requestRadiologyBilling: "requestRadiologyBillingNotes",
  requestAnesthesiaBilling: "requestAnesthesiaBillingNotes",
  requestHospitalAbstract: "requestHospitalAbstractNotes",
  requestRadiology: "requestRadiologyNotes",
};
