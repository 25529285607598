import React, { useState } from "react";
import styled from "styled-components";
import {
  ClipboardCopyIcon,
  QuestionMarkCircleIcon,
  XIcon,
} from "@heroicons/react/outline";
import ShimmerEffect from "react-shimmer-effect";
import { Tooltip } from "../atoms/Tooltip";
import { RadioGroup } from "../atoms/RadioButton";
import { Theme } from "../atoms/theme";
import FileInput from "../atoms/FileInput";
import { ButtonOutline } from "../atoms/Button";
import { TextArea } from "../atoms/TextArea";
import { Input, Select, Button } from "@tyrannosaurustech/ui";
import { CheckboxAndSwitch } from "../molecules/CheckboxAndSwitch";
import { SubtitlesM, ParagraphS, ParagraphM } from "../atoms/Typography";
import { states } from "../../common/select/constants";
import { formErrorMessage } from "../../utils/formError";
import useToast from "../../hooks/useToast";
import { useUser } from "../../hooks/useLogin";
import { CustomCheckbox } from "../atoms/Checkbox";
import { MedicalProviderConnections } from "./MedicalProviderConnections";
import { formatDate } from "../../utils/formatDate";

interface InputContainerProps {
  filter?: boolean;
}

const InputContainer = styled.div<InputContainerProps>`
  display: flex;
  margin-bottom: 1.25rem;
`;

const CopyButtonWrapper = styled.div`
  margin-left: 0.15rem;
  margin-right: 0.3rem;
  margin-top: auto;
`;

const AddNewButtonWrapper = styled.div`
  display: flex;
  margin-left: 0.5rem;
  ${({ autoMargin }: { autoMargin?: any }) => {
    if (!autoMargin["patient"] && !autoMargin["provider"]) {
      return "margin-top: inherit;";
    }
  }}
  height: 2.875rem;
  width: 10rem;
  margin-top: 30px;
`;
const CheckAndSwitchWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 1.5rem;
`;

const ButtonSpacing = styled.div`
  width: 10rem;
`;
const ButtonContainer = styled.div`
  margin-top: 28px;
`;

const Shimmer = ({ label }) => {
  return (
    <div style={{ width: "100%" }}>
      <SubtitlesM>{label}</SubtitlesM>
      <ShimmerEffect>
        <div style={{ height: ".875rem", width: "90%" }} />
      </ShimmerEffect>
    </div>
  );
};

interface IRenderInputConfig {
  input: any;
  errors: any;
  setValue: any;
  register: any;
  getValues: any;
  control: any;
  onFileDelete: any;
  watch: any;
  initialValue?: any;
  refetchFunction?: any;
  loading: boolean;
}
export const RenderInput = (config: IRenderInputConfig) => {
  const {
    input,
    errors,
    setValue,
    register,
    getValues,
    control,
    onFileDelete,
    watch,
    initialValue,
    refetchFunction = () => {},
    loading,
  } = config;
  const emailPattern =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const ssnPattern = /^[\dX]{3}-?[\dX]{2}-?[\dX]{4}$/i;
  const datePattern =
    /^(0?[1-9]|1[0-2])\/(0[1-9]?|1\d|2\d|3[01])\/(19|20)\d{2}$/;
  const zipcodePattern = /^\d{5}(?:[-\s]\d{4})?$/;
  const phoneNumberPattern =
    /^(?:\+?(\d{1,3})\s?)?[-.(]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*/;

  const toast = useToast();
  const user = useUser();
  const [checked, setCheckbox] = useState(getValues(input.id) ? true : false);

  const isBeforeToday = (date) => {
    if (!date) {
      return false;
    }
    const today = new Date();
    const dateIsBeforeToday = new Date(date) < today;
    return dateIsBeforeToday ? null : "Date cannot be in the future";
  };

  const renderState = (input, label, formId) => {
    return (
      <>
        <Select
          {...input}
          {...register(formId, { required: input.required })}
          errorText={
            errors[formId] ? `Please enter ${label?.toLowerCase()}` : null
          }
          label={label}
          id={formId}
          required={input.required}
          options={states}
          className="w-[100%]"
          value={{
            id: input.id,
            label: watch(input.id),
          }}
          setValue={(value) => {
            setValue(formId, value);
          }}
          name={input.name}
          register={register(formId, { required: input.required })}
        />
        {input.copy && (
          <CopyButtonWrapper>
            <Button
              base="tertiary"
              icon="DocumentDuplicateIcon"
              size="small"
              type="button"
              onClick={async () => {
                await navigator.clipboard
                  .writeText(getValues(formId).label)
                  .then(() => {
                    toast.success("Copied to clipboard");
                  });
              }}
            />
          </CopyButtonWrapper>
        )}
        {input.addNewInDropDown && (
          <AddNewButtonWrapper autoMargin={errors}>
            <ButtonOutline
              icon={true}
              color={Theme.colors.primaries_000}
              hoverColor={Theme.colors.primaries_neg100}
              noText={true}
              buttonText={"Add New"}
              onClick={() => {
                if (input.addNewAction) {
                  input.addNewAction();
                }
              }}
              img={
                input.addNewIcon ? (
                  input.addNewIcon
                ) : (
                  <ClipboardCopyIcon
                    width={"0.938rem"}
                    color={Theme.colors.primaries_000}
                  />
                )
              }
            />
          </AddNewButtonWrapper>
        )}
        {input.clearButton && (
          <ButtonContainer>
            <ButtonOutline
              icon={true}
              color={Theme.colors.primaries_000}
              hoverColor={Theme.colors.primaries_neg100}
              width={"2.75rem"}
              onClick={() => setValue(formId, undefined)}
              img={
                <XIcon width={"0.938rem"} color={Theme.colors.primaries_000} />
              }
            />
          </ButtonContainer>
        )}
      </>
    );
  };

  const renderZip = (input, label, formId) => {
    return (
      <>
        <Input
          {...register(formId, {
            required: input.required,
            maxLength: 250,
            pattern: zipcodePattern,
          })}
          size="small"
          className="w-full"
          label={
            (input.labelMappedKey && getValues(input.labelMappedKey)) || label
          }
          error={errors[formId]}
          errorText={formErrorMessage(
            errors[formId]?.type,
            label,
            input.message
          )}
          required={input.required}
          icon={input.icon}
          img={input.img}
          width={"100%"}
          type={input.type}
          placeholderText={input.placeholder ? input.placeholder : label}
          {...(input.initialValue ? setValue(formId, input.initialValue) : "")}
          setFormValue={(value) => {
            setValue(formId, value);
          }}
          isInnerTooltip={input.isInnerTooltip}
          tooltipText={input.tooltipText}
          disabled={input.disabled}
        />
        {input.copy && (
          <CopyButtonWrapper>
            <Button
              base="tertiary"
              icon="DocumentDuplicateIcon"
              size="small"
              type="button"
              onClick={async () => {
                await navigator.clipboard
                  .writeText(getValues(formId))
                  .then(() => {
                    toast.success("Copied to clipboard");
                  });
              }}
            />
          </CopyButtonWrapper>
        )}
      </>
    );
  };
  const getInputSelected = () => {
    if (watch(input.id)?.value) {
      return watch(input.id)?.value === "Fax"
        ? watch(input.id)?.value + " Number"
        : watch(input.id)?.value;
    } else if (getValues(input.id)) {
      return getValues(input.id)[0].value;
    } else {
      return input.label.replace("By", "To");
    }
  };

  const formatText = (text, type) => {
    switch (type) {
      case "date":
        return formatDate(text);
      default:
        return text;
    }
  };
  if (input.radio)
    return loading ? (
      <Shimmer label={input.label} />
    ) : (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <SubtitlesM>{input.radioHeader}</SubtitlesM>
        <RadioGroup
          topMargin={10}
          control={control}
          options={input.radioLabels}
          values={input.values}
        />
      </div>
    );
  if (input.conditional) {
    return loading ? (
      <Shimmer label={input.label} />
    ) : (
      <div style={{ display: "block", width: "100%" }}>
        <div style={{ display: "flex", marginBottom: "2.25rem" }}>
          <Select
            {...input}
            {...register(input.id, { required: input.required })}
            errorText={
              errors[input.id]
                ? `Please enter ${input.label?.toLowerCase()}`
                : null
            }
            label={input.label}
            id={input.id}
            required={input.required}
            options={input.options}
            setValue={(value) => {
              setValue(input.id, value);
            }}
            className="w-[100%]"
            value={{
              id: input.id,
              label: watch(input.id),
            }}
            name={input.name}
            register={register(input.id, { required: input.required })}
          />
          {input.copy && (
            <CopyButtonWrapper>
              <Button
                base="tertiary"
                icon="DocumentDuplicateIcon"
                size="small"
                type="button"
                onClick={async () => {
                  await navigator.clipboard
                    .writeText(getValues(input.id))
                    .then(() => {
                      toast.success("Copied to clipboard");
                    });
                }}
              />
            </CopyButtonWrapper>
          )}
        </div>
        <div style={{ display: "flex" }}>
          <Input
            {...register(input.input, {
              required: input.required,
              maxLength: 250,
              pattern:
                watch(input.id)?.label === "Email"
                  ? emailPattern
                  : watch(input.id)?.label?.toLowerCase().includes("fax")
                  ? phoneNumberPattern
                  : null,
            })}
            size="small"
            className="w-full"
            noLabel={true}
            label={
              (input.labelMappedKey && getValues(input.labelMappedKey)) ||
              getInputSelected()
            }
            error={errors[input.input]}
            errorText={formErrorMessage(
              errors[input.input]?.type,
              input.label,
              input.message,
              watch(input.id)?.value
            )}
            required={input.required}
            icon={input.icon}
            img={input.img}
            disabled={input.disabled}
            width={"100%"}
            type={input.type}
            placeholderText={getInputSelected()}
            setFormValue={(value) => {
              setValue(input.input, value);
            }}
            isInnerTooltip={input.isInnerTooltip}
            tooltipText={input.tooltipText}
          />
          {input.copy && (
            <CopyButtonWrapper>
              <Button
                base="tertiary"
                icon="DocumentDuplicateIcon"
                size="small"
                type="button"
                onClick={async () => {
                  await navigator.clipboard
                    .writeText(getValues(input.input))
                    .then(() => {
                      toast.success("Copied to clipboard");
                    });
                }}
              />
            </CopyButtonWrapper>
          )}
        </div>
      </div>
    );
  }
  if (input.select || input.type === "state") {
    if (input.type === "state") input.options = states;
    return loading ? (
      <Shimmer label={input.label} />
    ) : (
      <div style={{ width: "100%", display: "flex" }}>
        <div style={{ width: "100%" }}>
          <Select
            {...input}
            {...register(input.id, { required: input.required })}
            errorText={
              errors[input.id]
                ? `Please enter ${input.label?.toLowerCase()}`
                : null
            }
            label={input.label}
            id={input.id}
            required={input.required}
            options={input.options || []}
            onChange={(value) => {
              const selectValue: any = value;
              setValue(input.id, selectValue.value);
            }}
            className="w-[100%]"
            value={{
              id: input.id,
              label: input.options.find((opt) => opt.value === watch(input.id))
                ?.label,
            }}
            name={input.name}
            register={register(input.id, { required: input.required })}
          />
        </div>
        {input.copy && (
          <CopyButtonWrapper>
            <Button
              base="tertiary"
              icon="DocumentDuplicateIcon"
              size="small"
              type="button"
              onClick={async () => {
                const value = input.options.find(
                  (opt) => opt.value === getValues(input.id)
                );
                await navigator.clipboard.writeText(value?.label).then(() => {
                  toast.success("Copied to clipboard");
                });
              }}
            />
          </CopyButtonWrapper>
        )}
        {input.addNewInDropDown && (
          <AddNewButtonWrapper autoMargin={errors}>
            <ButtonOutline
              icon={true}
              color={Theme.colors.primaries_000}
              hoverColor={Theme.colors.primaries_neg100}
              noText={true}
              buttonText={"Add New"}
              onClick={() => {
                if (input.addNewAction) {
                  input.addNewAction();
                }
              }}
              img={
                input.addNewIcon ? (
                  input.addNewIcon
                ) : (
                  <ClipboardCopyIcon
                    width={"0.938rem"}
                    color={Theme.colors.primaries_000}
                  />
                )
              }
            />
          </AddNewButtonWrapper>
        )}
        {input.clearButton && (
          <ButtonContainer>
            <ButtonOutline
              icon={true}
              color={Theme.colors.primaries_000}
              hoverColor={Theme.colors.primaries_neg100}
              width={"2.75rem"}
              onClick={() => setValue(input.id, undefined)}
              img={
                <XIcon width={"0.938rem"} color={Theme.colors.primaries_000} />
              }
            />
          </ButtonContainer>
        )}
        {input.button && (
          <Button
            label={input.buttonText}
            base="primary"
            icon="PlusSmallIcon"
          />
        )}
      </div>
    );
  }
  if (input.type === "stateAndZip") {
    return loading ? (
      <Shimmer label={input.label} />
    ) : (
      <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
        {renderState(input, input.formLabels[0], input.formIds[0])}
        {renderZip(input, input.formLabels[1], input.formIds[1])}
      </div>
    );
  }
  if (input.textArea)
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          marginRight: "1.50rem",
        }}
      >
        {loading ? (
          <Shimmer label={input.label} />
        ) : (
          <TextArea
            getValues={getValues}
            setValue={setValue}
            name={input.id}
            fullScreen={input.fullScreen}
            register={register(input.id, { required: input.required })}
            noLabel={true}
            labelText={input.label}
            error={errors[input.id]}
            errorText={`Please enter ${input.label?.toLowerCase()}`}
            required={input.required}
            placeholderText={
              input.placeholder ? input.placeholder : input.label || ""
            }
            disabled={input.disabled}
            style={input.style}
            width="100%"
          />
        )}
        {!user?.isAdmin() && (
          <ParagraphS style={{ marginTop: "2.25rem", marginBottom: 0 }}>
            <span style={{ color: Theme.colors.danger_000 }}>NOTE:</span> Please
            check the information on this request is correct. Once your request
            is submitted you wont be able to edit it.
          </ParagraphS>
        )}
      </div>
    );
  if (input.addNew)
    return loading ? (
      <Shimmer label={input.label} />
    ) : (
      <>
        <Input
          noLabel={true}
          label={
            (input.labelMappedKey && getValues(input.labelMappedKey)) ||
            input.label
          }
          error={errors[input.id]}
          errorText={`Please enter ${input.label?.toLowerCase()}`}
          required={input.required}
          icon={input.icon}
          img={input.img}
          size="small"
          className="w-full"
          placeholderText={input.placeholder ? input.placeholder : input.label}
          {...register(input.id, { required: input.required })}
        />
        <AddNewButtonWrapper autoMargin={errors}>
          <ButtonOutline
            icon={true}
            color={Theme.colors.primaries_000}
            hoverColor={Theme.colors.primaries_neg100}
            noText={true}
            buttonText={"Add New"}
            onClick={() => {
              if (input.addNewAction) {
                input.addNewAction();
              }
            }}
            img={
              input.addNewIcon ? (
                input.addNewIcon
              ) : (
                <ClipboardCopyIcon
                  width={"0.938rem"}
                  color={Theme.colors.primaries_000}
                />
              )
            }
          />
        </AddNewButtonWrapper>
      </>
    );
  if (input.checkAndSwitch)
    return loading ? (
      <Shimmer label={input.label} />
    ) : (
      <CheckAndSwitchWrapper>
        {input.checkAndSwitchTitleTooltip ? (
          <div style={{ display: "flex" }}>
            <SubtitlesM>Requests</SubtitlesM>
            <div style={{ marginRight: "0.406rem" }} />
            <Tooltip
              position="top"
              background="black"
              style={{ position: "absolute" }}
              text={input.titleTooltipText}
            >
              <QuestionMarkCircleIcon
                width={"1.25rem"}
                color={Theme.colors.neutrals_200}
              />
            </Tooltip>
          </div>
        ) : input.noCheckAndSwitchHeader === true ? (
          <></>
        ) : (
          <div style={{ display: "flex" }}>
            <SubtitlesM>Requests</SubtitlesM>
            <div style={{ marginRight: "0.406rem" }} />
          </div>
        )}

        <CheckboxAndSwitch
          disabled={input.disabled}
          control={control}
          checkLabels={input.checkLabels}
          switchLabel={input.switchLabel}
          tooltipText={input.tooltipText || ""}
          setValue={setValue}
          onEnable={input.onEnable}
        />
      </CheckAndSwitchWrapper>
    );
  if (input.file)
    return loading ? (
      <Shimmer label={input.label} />
    ) : (
      <FileInput
        {...register(input.id, { required: input.required })}
        type={input.type}
        error={errors[input.id]}
        errorText={`Please Enter ${input.label}`}
        initialValues={getValues(input.id)}
        setFormValue={(value) => {
          setValue(input.id, value);
        }}
        onFileDelete={onFileDelete}
        {...{
          config: input,
          errors,
          setValue,
          register,
          getValues,
          control,
        }}
      />
    );

  if (input.connections) {
    return loading ? (
      <Shimmer label={input.label} />
    ) : (
      <div style={{ width: "100%", marginBottom: "0rem" }}>
        <MedicalProviderConnections
          data={getValues(input.id)}
          selectedProvider={initialValue}
          refetchFunction={refetchFunction}
          setValue={setValue}
          getValue={getValues}
          changeId={input.changeId}
        />
      </div>
    );
  }

  if (input.plainText) {
    const value = getValues(input.id);
    return loading ? (
      <Shimmer label={input.label} />
    ) : (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div>
          <SubtitlesM>{input.label}</SubtitlesM>
          <ParagraphM color={Theme.colors.gray_000}>
            {formatText(value, input.type)}
          </ParagraphM>
        </div>
        {input.copy && (
          <CopyButtonWrapper>
            <Button
              base="tertiary"
              icon="DocumentDuplicateIcon"
              size="small"
              type="button"
              onClick={async () => {
                await navigator.clipboard
                  .writeText(getValues(input.id))
                  .then(() => {
                    toast.success("Copied to clipboard");
                  });
              }}
            />
          </CopyButtonWrapper>
        )}
      </div>
    );
  }

  if (input.button) {
    return loading ? (
      <Shimmer label={input.label} />
    ) : (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Button
          base="secondary"
          size="medium"
          icon="ArrowRightIcon"
          type="button"
          label={input.buttonLabel}
          onClick={input.onClick}
        />
      </div>
    );
  }

  if (input.checkbox) {
    return loading ? (
      <Shimmer label={input.label} />
    ) : (
      <div style={{ width: "100%", marginBottom: "0rem" }}>
        <CheckboxWrapper>
          <CustomCheckbox
            onChange={() => {
              setValue(input.id, !getValues(input.id));
              setCheckbox(getValues(input.id));
            }}
            checked={checked}
            disabled={false}
            label={input.label}
          />
        </CheckboxWrapper>
        {checked && input.subFields && (
          <InputContainer>
            {input.subFields?.map((field) => {
              return (
                <>
                  <Input
                    {...register(field.id, {
                      required: field.required,
                      maxLength: 250,
                      pattern: input.pattern,
                    })}
                    size="small"
                    className="w-full"
                    noLabel={true}
                    label={
                      (input.labelMappedKey &&
                        getValues(input.labelMappedKey)) ||
                      field.label
                    }
                    error={errors[field.id]}
                    errorText={formErrorMessage(
                      errors[field.id]?.type,
                      field.label,
                      field.errorText
                    )}
                    required={field.required}
                    icon={input.icon}
                    img={input.img}
                    width={"100%"}
                    copy={field.copy}
                    getValues={getValues}
                    inputId={field.id}
                    type={input.type}
                    placeholderText={
                      field.placeholder ? field.placeholder : field.label
                    }
                    {...(input.initialValue
                      ? setValue(field.id, input.initialValue)
                      : "")}
                    setFormValue={(value) => {
                      setValue(field.id, value);
                    }}
                    isInnerTooltip={input.isInnerTooltip}
                    tooltipText={input.tooltipText}
                    disabled={input.disabled}
                  />
                </>
              );
            })}
          </InputContainer>
        )}
      </div>
    );
  }

  const patternType =
    (input.labelMappedKey && getValues(input.labelMappedKey)?.toLowerCase()) ||
    input?.type;
  return (
    <div style={{ width: "100%", display: "flex" }}>
      {loading ? (
        <Shimmer label={input.label} />
      ) : (
        <Input
          {...register(input.id, {
            required: input.required,
            maxLength: 250,
            pattern:
              patternType === "email"
                ? emailPattern
                : patternType === "zip"
                ? zipcodePattern
                : patternType === "ssn"
                ? ssnPattern
                : patternType === "date" || patternType === "dateOfBirth"
                ? datePattern
                : patternType === "phoneNumber" || patternType === "fax"
                ? phoneNumberPattern
                : null,
            validate: input?.type === "dateOfBirth" ? isBeforeToday : null,
          })}
          size="small"
          className="w-full"
          label={
            (input.labelMappedKey && getValues(input.labelMappedKey)) ||
            input.label
          }
          error={errors[input.id]}
          errorText={formErrorMessage(
            errors[input.id]?.type,
            input.label,
            input.message || errors[input.id]?.message
          )}
          required={input.required}
          icon={input.icon}
          img={input.img}
          width={"100%"}
          type={input.type}
          placeholderText={input.placeholder ? input.placeholder : input.label}
          setFormValue={(value) => {
            setValue(input.id, value);
          }}
          isInnerTooltip={input.isInnerTooltip}
          tooltipText={input.tooltipText}
          disabled={input.disabled}
        />
      )}
      {input.notFullLength ? <ButtonSpacing /> : null}
      {input.copy && (
        <CopyButtonWrapper>
          <Button
            base="tertiary"
            icon="DocumentDuplicateIcon"
            size="small"
            type="button"
            onClick={async () => {
              await navigator.clipboard
                .writeText(getValues(input.id))
                .then(() => {
                  toast.success("Copied to clipboard");
                });
            }}
          />
        </CopyButtonWrapper>
      )}
    </div>
  );
};
