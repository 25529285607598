import React, { Fragment, useMemo, useState } from "react";
import { EyeIcon, TrashIcon } from "@heroicons/react/outline";
import { useNavigate } from "react-router-dom";

import {
  useGetFirmsQuery,
  useDeleteFirmMutation,
} from "../../../redux/reduxQuery/firmApi";
import { TitleM } from "../../../components/atoms/Typography";
import { CustomTable } from "../../../components/organisms/ShimmerTable";
import { EyeTrashIconButtons } from "../../../components/molecules/TableActionButtons";
import { ContentWrapper } from "../../../components/ContentWrapper";
import { ModalWithTwoButtons } from "../../../components/molecules/Modal";

export default function LawFirms() {
  const [deleteLawFirmModal, setDeleteLawFirmModal] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [lawFirm, setLawFirm] = useState<any>(null);
  let navigate = useNavigate();
  const { data, isLoading, refetch } = useGetFirmsQuery();
  const [deleteFirm] = useDeleteFirmMutation();
  const [scrollPosition, setScrollPosition] = useState(0);

  const lawFirmData = useMemo(() => data, [data]);
  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        includeToolTip: true,
      },
      {
        Header: "Contact Name",
        accessor: "contactName",
      },
      {
        Header: "Actions",
        accessor: "id",
        disableSortBy: true,
        disableFilters: true,
        Cell: (cell) => {
          const lawFirm = cell.row.original;
          return (
            <EyeTrashIconButtons
              keyValue={`eye-trash-dot-icons-${cell.row.id}`}
              link={`/admin/law-firms/${lawFirm.id}`}
              icons={[EyeIcon, TrashIcon]}
              onClick1={() => navigate(`/admin/law-firms/${lawFirm.id}`)}
              onClick2={() => {
                setLawFirm(cell.row.values);
                setDeleteLawFirmModal(true);
              }}
            />
          );
        },
      },
    ],
    [navigate]
  );

  return (
    <Fragment>
      {deleteLawFirmModal && (
        <ModalWithTwoButtons
          title="Delete Law Firm"
          content={`Are you sure you want to delete ${lawFirm?.name}? If you do you won’t be able to recover it.`}
          button1Text="Delete"
          button2Text="Cancel"
          loader={buttonLoader}
          onClick1={async () => {
            setButtonLoader(true);
            await deleteFirm(lawFirm?.id);
            refetch();
            setButtonLoader(false);
            setDeleteLawFirmModal(false);
          }}
          onClick2={() => setDeleteLawFirmModal(false)}
        />
      )}
      <ContentWrapper>
        <TitleM style={{ margin: "1rem 0" }}>Law Firms</TitleM>
        <CustomTable
          tableType="firm"
          columns={columns}
          data={lawFirmData}
          isLoading={isLoading}
          navigation={() => navigate("/admin/law-firms/new")}
          onScroll={setScrollPosition}
          scrollPosition={scrollPosition}
        />
      </ContentWrapper>
    </Fragment>
  );
}
