import { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import { useGetRequestQuery } from "../../../redux/reduxQuery/requestApi";
import { Breadcrumb } from "../../../components/molecules/Breadcrumb";
import searchIcon from "../../../components/assets/search.svg";
import {
  ParagraphS,
  SubtitlesM,
  TitleM,
  TitleS,
} from "../../../components/atoms/Typography";
import { TrashIcon, SaveIcon } from "@heroicons/react/outline";
import { Button, ButtonOutline } from "../../../components/atoms/Button";
import { useForm } from "react-hook-form";
import {
  ClipboardCopyIcon,
  OfficeBuildingIcon,
  UserIcon,
  TruckIcon,
} from "@heroicons/react/outline";
import { Theme } from "../../../components/atoms/theme";
import Form from "../../../components/organisms/Form";
import { formatDate } from "../../../utils/formatDate";
import {
  useUpdateRequestMutation,
  useDeleteRequestMutation,
} from "../../../redux/reduxQuery/requestApi";
import useToast from "../../../hooks/useToast";
import { UploadFVButton } from "../../../common/fv";
import { useMutationWrapper } from "../../../common/api";
import { renderButtonStatus } from "../../../common/badge";
import {
  useSearchProvidersByName,
  useUpdateProviderMutation,
} from "../../../redux/reduxQuery/medicalProviderApi";
import {
  medicalProviderForm,
  sendInformationByForm,
} from "../../../common/forms/medicalProvider";
import { CertificationModal } from "../../../components/molecules/CertificationModal";
import { formatSSN } from "../../../common/format";
import { useRequestCertification } from "../../../hooks/useRequestCertification";
import {
  ModalWithTwoButtons,
  ModalWithOneButton,
} from "../../../components/molecules/Modal";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  background: #f1f3f5;
`;
const AlignStartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  width: 100%;
  margin: 0;
`;
const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: ${({ theme }) => theme.shadows.dropshadow_m};
  border-radius: ${({ theme }) => theme.radii.corner_radius_l};
  background: ${({ theme }) => theme.colors.neutrals_500};
`;
const SectionInfoWrapper = styled.div<{ width?: any; style?: any }>`
  width: ${({ width }: any) => width || "100%"};
  display: flex;
  flex-direcion: row;
  align-items: center;
`;
const SectionColumn = styled.div<{ width?: any; style?: any }>`
  display: flex;
  flex-direction: column;
  width: ${({ width }: any) => width || "100%"};
`;
const DivideLine = styled.div<{ height?: any }>`
  display: flex;
  border: 2px solid ${Theme.colors.neutrals_400};
  border-radius: 9999px;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  height: ${({ height }: any) => height || "12rem"};
`;
export const useSetRequestFields = (request, form) => {
  useEffect(() => {
    if (request) {
      Object.keys(request).map((k) => {
        switch (k) {
          case "requestBills":
            form.setValue("Bills", request[k]);
            break;
          case "requestBillsCertified":
            form.setValue("Bills Certified", request[k]);
            break;
          case "requestERBilling":
            form.setValue("ER Physicians Billing", request[k]);
            break;
          case "requestERBillingCertified":
            form.setValue("ER Physicians Billing Certified", request[k]);
            break;
          case "requestHospitalAbstract":
            form.setValue(
              "Hospital Abstract (in lieu of full recs)",
              request[k]
            );
            break;
          case "requestHospitalAbstractCertified":
            form.setValue(
              "Hospital Abstract (in lieu of full recs) Certified",
              request[k]
            );
            break;
          case "requestRadiology":
            form.setValue("CD of Radiology Images and Films", request[k]);
            break;
          case "requestRadiologyCertified":
            form.setValue(
              "CD of Radiology Images and Films Certified",
              request[k]
            );
            break;
          case "requestRadiologyBilling":
            form.setValue("Radiology Billing", request[k]);
            break;
          case "requestRadiologyBillingCertified":
            form.setValue("Radiology Billing Certified", request[k]);
            break;
          case "requestRecords":
            form.setValue("Records", request[k]);
            break;
          case "requestRecordsCertified":
            form.setValue("Records Certified", request[k]);
            break;
          case "requestAnesthesiaBilling":
            form.setValue("Anesthesia Billing", request[k]);
            break;
          case "requestAnesthesiaBillingCertified":
            form.setValue("Anesthesia Billing Certified", request[k]);
            break;
          default:
            form.setValue(k, request[k]);
        }
        return true;
      });
    }
  }, [form, request]);
};

export default function EditRequest() {
  const { id } = useParams();
  let navigate = useNavigate();
  const toast = useToast();
  const { data: request = {}, isLoading, refetch } = useGetRequestQuery(id);
  const [updateRequest] = useUpdateRequestMutation();
  const [deleteRequest] = useDeleteRequestMutation();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const form = useForm();
  const mutate = useMutationWrapper(
    updateRequest,
    `Changes have been saved successfully`,
    () => {}
  );
  const searchProviders = useSearchProvidersByName();
  const [processing, setProcessing] = useState(false);
  const [openVerifyModal, setOpenVerifyModal] = useState(false);
  const [alreadyVerifiedModal, setAlreadyVerifiedModal] = useState(false);
  const [updateProvider] = useUpdateProviderMutation();

  const verifyProvider = async () => {
    await updateProvider({ ...request.provider, status: "verified" }).then(
      (_res) => {
        setOpenVerifyModal(false);
        refetch();
        toast.success(
          `${request.provider.name} has been verified Successfully`
        );
      }
    );
  };

  const onSubmit = form.handleSubmit(async (data) => {
    setProcessing(true);
    await mutate({
      ...request,
      ...data,
      requestBills: !!data["Bills"],
      requestBillsCertified: !!data["Bills Certified"],
      requestRecords: !!data["Records"],
      requestRecordsCertified: !!data["Records Certified"],
      requestRadiology: !!data["CD of Radiology Images and Films"],
      requestRadiologyCertified:
        !!data["CD of Radiology Images and Films Certified"],
      requestERBilling: !!data["ER Physicians Billing"],
      requestERBillingCertified: !!data["ER Physicians Billing Certified"],
      requestRadiologyBilling: !!data["Radiology Billing"],
      requestRadiologyBillingCertified: !!data["Radiology Billing Certified"],
      requestHospitalAbstract:
        !!data["Hospital Abstract (in lieu of full recs)"],
      requestHospitalAbstractCertified:
        !!data["Hospital Abstract (in lieu of full recs) Certified"],
      requestAnesthesiaBilling: !!data["Anesthesia Billing"],
      requestAnesthesiaBillingCertified: !!data["Anesthesia Billing Certified"],

      status: data.status.value,
      patient: null,
      firm: null,
      provider: null,
      providerId: data.provider?.id || data.providerId,
    }).then((res) => {
      toast.success("Changes have been saved successfully");
      navigate(-1);
    });
    setProcessing(false);
  });
  useSetRequestFields(request, form);
  const crumbLinks = [
    {
      text: "Requests",
      path: `/admin/requests`,
    },
    {
      text: `${request?.patient?.name}`,
    },
  ];

  const {
    showCertifiedRequestModal,
    userLoading,
    showModal,
    buttonLoader,
    checked,
    setCheckbox,
    onCloseModal,
    requesterInfoInputsWithTogglefunction,
  } = useRequestCertification();
  const requesterInfoInputsMapped = [
    [...requesterInfoInputs[0], requesterInfoInputsWithTogglefunction],
    [...requesterInfoInputs[1]],
  ];

  return (
    <>
      <Wrapper>
        <div style={{ width: "85%", marginTop: "1.50rem" }}>
          <Breadcrumb
            crumbs={crumbLinks}
            buttons={[
              <Button
                onClick={() => {
                  onSubmit();
                }}
                icon={true}
                color={Theme.colors.primaries_000}
                hoverColor={Theme.colors.primaries_neg100}
                buttonText="Save Changes"
                noText={true}
                loader={processing}
                img={
                  <SaveIcon width={"1rem"} color={Theme.colors.neutrals_500} />
                }
              />,
              <ButtonOutline
                icon={true}
                img={
                  <TrashIcon
                    width={"1rem"}
                    color={Theme.colors.primaries_000}
                  />
                }
                onClick={() => setOpenDeleteModal(true)}
                color={Theme.colors.primaries_000}
                hoverColor={Theme.colors.primaries_neg100}
              />,
            ]}
          />
          <AlignStartWrapper>
            <TitleM>Request For {request?.patient?.name}</TitleM>
          </AlignStartWrapper>
          <AlignStartWrapper>
            <TitleS>Request Status</TitleS>
          </AlignStartWrapper>
          <Form
            onSubmit={onSubmit}
            formInputs={statusRequestInputs}
            numberOfColumns={1}
            customSubmit
            loading={isLoading || userLoading}
            style={{ marginBottom: "0.50rem" }}
            {...form}
          />
          <AlignStartWrapper>
            <TitleS>Requester Info</TitleS>
          </AlignStartWrapper>
          <Form
            onSubmit={onSubmit}
            formInputs={requesterInfoInputsMapped}
            numberOfColumns={2}
            customSubmit
            loading={isLoading || userLoading}
            style={{ marginBottom: "0.50rem" }}
            {...form}
          />
          <AlignStartWrapper>
            <TitleS>Law Firm Info</TitleS>
          </AlignStartWrapper>
          <SectionWrapper>
            <SubtitlesM style={{ margin: "1.500rem 0 0.750rem 1.500rem" }}>
              Name
            </SubtitlesM>
            <SectionInfoWrapper style={{ marginBottom: "2.250rem" }}>
              <ParagraphS style={{ padding: 0, margin: "0 0 0 1.50rem" }}>
                {request.firm?.name}
              </ParagraphS>
              <div style={{ padding: 0, margin: "0 1rem 0 auto" }}>
                <ButtonOutline
                  icon={true}
                  color={Theme.colors.primaries_000}
                  hoverColor={Theme.colors.primaries_neg100}
                  noText={false}
                  onClick={async () => {
                    await navigator.clipboard
                      .writeText(request.firm.name)
                      .then(() => {
                        alert("Copied");
                      });
                  }}
                  img={
                    <ClipboardCopyIcon
                      width={"0.938rem"}
                      color={Theme.colors.primaries_000}
                    />
                  }
                />
              </div>
            </SectionInfoWrapper>
            <div
              style={{
                width: "84.269%",
                marginLeft: "1rem",
                marginBottom: "1.50rem",
              }}
            >
              <Button
                alignButtonContents
                onClick={() =>
                  navigate(`/admin/law-firms/${request.firm.id}`, {
                    state: {
                      id: request.firm.id,
                      name: request.firm.name,
                      contactName: request.firm.contactName,
                      contactEmail: request.firm.contactEmail,
                      address: request.firm.address,
                      state: request.firm.state,
                      city: request.firm.city,
                      zip: request.firm.zip,
                    },
                  })
                }
                width="11rem"
                noText
                icon={true}
                buttonText={"Go To Law Firm"}
                color={Theme.colors.primaries_000}
                img={
                  <OfficeBuildingIcon
                    height={"15px"}
                    width={"15px"}
                    color={Theme.colors.neutrals_500}
                  />
                }
              />
            </div>
          </SectionWrapper>
          <AlignStartWrapper style={{ marginTop: "0.50rem" }}>
            <TitleS>Patient Info</TitleS>
          </AlignStartWrapper>
          <SectionWrapper>
            <SectionInfoWrapper>
              <SectionColumn width={"50%"}>
                <CopyField title="Name" value={request.patient?.name} />
                <CopyField
                  title="SSN"
                  value={formatSSN(request.patient?.ssn)}
                />
              </SectionColumn>
              <DivideLine />
              <SectionColumn width={"50%"}>
                <CopyField
                  title="Date of Birth"
                  value={formatDate(request.patient?.dateOfBirth)}
                />
                <CopyField title="Address" value={request.patient?.address} />
              </SectionColumn>
            </SectionInfoWrapper>
            {!isLoading && (
              <div
                style={{
                  width: "84.269%",
                  marginLeft: "1rem",
                  marginBottom: "1.50rem",
                  display: "flex",
                }}
              >
                <UploadFVButton
                  type="patient"
                  record={request.patient}
                  modalTitle="Upload Patient to FileVine"
                  modalContent={`Are you sure you want to upload ${request.patient?.name}'s information to FileVine?`}
                  refetch={refetch}
                />
                <Button
                  alignButtonContents
                  onClick={() =>
                    navigate(`/admin/patients/${request.patient.id}`)
                  }
                  width="11rem"
                  noText
                  icon={true}
                  buttonText={"Go To Patient"}
                  color={Theme.colors.primaries_000}
                  img={
                    <UserIcon
                      height={"15px"}
                      width={"15px"}
                      color={Theme.colors.neutrals_500}
                    />
                  }
                />
              </div>
            )}
          </SectionWrapper>
          <AlignStartWrapper>
            <TitleS>Medical Provider Info</TitleS>
          </AlignStartWrapper>
          <SectionWrapper>
            <SectionInfoWrapper style={{ flexDirection: "column" }}>
              <Form
                {...form}
                onSubmit={onSubmit}
                formInputs={[
                  [
                    {
                      id: "provider",
                      label: "Medical Provider",
                      required: true,
                      copy: false,
                      select: true,
                      asyncConfig: {
                        loadOptions: searchProviders,
                        getOptionLabel: (p: any) => p.name,
                        getOptionValue: (p: any) => p.id,
                        placeholder: "Search Medical Providers",
                      },
                      icon: true,
                      img: searchIcon,
                      detailed: true,
                      addNewIcon: (
                        <TruckIcon
                          width={"0.938rem"}
                          color={Theme.colors.primaries_000}
                        />
                      ),
                    },
                    ...medicalProviderForm[0]
                      .map((i) => ({
                        ...i,
                        disabled: true,
                        required: false,
                        id: `provider.${i.id}`,
                      }))
                      .filter((x) => x.id !== "provider.name"),
                    medicalProviderForm[1].map((i) => ({
                      ...i,
                      disabled: true,
                      required: false,
                      id: `provider.${i.id}`,
                    }))[0],
                  ],
                  [
                    ...medicalProviderForm[1]
                      .map((i) => ({
                        ...i,
                        disabled: true,
                        required: false,
                        id: `provider.${i.id}`,
                      }))
                      .slice(1),
                    {
                      ...sendInformationByForm[0][0],
                      disabled: true,
                      id: `provider.${sendInformationByForm[0][0].id}`,
                      input: `provider.${sendInformationByForm[0][0].input}`,
                    },
                    {
                      ...sendInformationByForm[1][0],
                      disabled: true,
                      id: `provider.${sendInformationByForm[1][0].id}`,
                      input: `provider.${sendInformationByForm[1][0].input}`,
                    },
                  ],
                ]}
                numberOfColumns={2}
                customSubmit
                loading={isLoading}
                style={{
                  marginBottom: "10px",
                  borderRadius: "0px",
                  boxShadow: "none",
                }}
              />
              <Form
                {...form}
                onSubmit={onSubmit}
                formInputs={[
                  {
                    id: "provider.notes",
                    label: "Notes",
                    placeholder: "Notes",
                    copy: false,
                    textArea: true,
                    disabled: true,
                    style: {
                      minHeight: "30vh",
                    },
                  },
                ]}
                numberOfColumns={1}
                customSubmit={true}
                cancelAction={() => navigate(-1)}
                style={{
                  marginBottom: "0px",
                  borderRadius: "0px",
                  boxShadow: "none",
                }}
              />
            </SectionInfoWrapper>
            {!isLoading && (
              <div
                style={{
                  width: "84.269%",
                  marginLeft: "1rem",
                  marginBottom: "1.50rem",
                  display: "flex",
                }}
              >
                <UploadFVButton
                  type="provider"
                  record={request.provider}
                  modalTitle="Upload Provider to FileVine"
                  modalContent={`Are you sure you want to upload ${request.provider?.name}'s information to FileVine?`}
                  refetch={refetch}
                />
                {renderButtonStatus(request.provider?.status, () =>
                  request.provider?.status === "verified"
                    ? setAlreadyVerifiedModal(true)
                    : setOpenVerifyModal(true)
                )}
                <Button
                  alignButtonContents
                  onClick={() =>
                    navigate(`/admin/medical-providers/${request.provider?.id}`)
                  }
                  width="11rem"
                  noText
                  icon={true}
                  buttonText={"Go To Provider"}
                  color={Theme.colors.primaries_000}
                  img={
                    <TruckIcon
                      height={"15px"}
                      width={"15px"}
                      color={Theme.colors.neutrals_500}
                    />
                  }
                />
              </div>
            )}
            {openDeleteModal && (
              <ModalWithTwoButtons
                title="delete request"
                content={`Are you sure you want to delete this request with 
                  status ${request?.status} for ${request?.patient?.name} 
                  at ${request?.firm?.name}? If you do you won’t be able to recover it.`}
                button1Text="delete"
                button2Text="cancel"
                loader={isProcessing}
                onClick1={async () => {
                  setIsProcessing(true);
                  await deleteRequest(request?.id);
                  setIsProcessing(false);
                  setOpenDeleteModal(false);
                  navigate("/admin/requests");
                }}
                onClick2={() => setOpenDeleteModal(false)}
              />
            )}
            {alreadyVerifiedModal && (
              <ModalWithOneButton
                icon={false}
                title="Provider Already verified"
                content="This provider has already been verified"
                buttonText="Ok"
                onClick={() => {
                  setAlreadyVerifiedModal(false);
                }}
              />
            )}
            {openVerifyModal && (
              <ModalWithTwoButtons
                title="Verify Provider"
                content={`Are you sure you want to update this provider? Please make sure all the necessary information is filled.`}
                button1Text="Verify"
                button2Text="Cancel"
                loader={isProcessing}
                onClick1={async () => {
                  setIsProcessing(true);
                  await verifyProvider();
                  setIsProcessing(false);
                  setOpenVerifyModal(false);
                  navigate("/admin/requests");
                }}
                onClick2={() => setOpenVerifyModal(false)}
              />
            )}
            {showModal && (
              <CertificationModal
                onClick={async () => await onCloseModal()}
                checked={checked}
                checkboxOnChange={() => setCheckbox(!checked)}
                disabled={!showCertifiedRequestModal}
                loader={buttonLoader}
              />
            )}
          </SectionWrapper>
        </div>
        <div style={{ marginBottom: "7.5rem" }} />
      </Wrapper>
    </>
  );
}

const statusRequestInputs = [
  {
    id: "status",
    label: "Status",
    select: true,
    copy: false,
    required: false,
    options: [
      { label: "New", value: "new" },
      { label: "Entered", value: "entered" },
      { label: "Processing", value: "processing" },
      { label: "Awaiting Law Firm Info", value: "awaiting_law_firm_info" },
      { label: "Vetted", value: "vetted" },
      { label: "Closed - Not Sent", value: "closed_not_sent" },
      { label: "Needs Further Review", value: "needs_further_review" },
    ],
  },
];

const requesterInfoInputs = [
  [
    {
      id: "requestedBy",
      label: "Requester",
      required: true,
      copy: true,
    },
    {
      id: "serviceDates",
      label: "Service Dates",
      required: true,
      copy: true,
    },
    {
      id: "requesterEmails",
      label: "Requester Email/emails",
      required: true,
      copy: true,
      notFullLength: false,
    },
  ],
  [
    {
      id: "requestNotes",
      label: "Notes",
      required: false,
      textArea: true,
      noFooterMessage: true,
    },
  ],
];

const CopyField: React.FC<{
  title: string;
  value: string;
  formattedValue?: string;
  height?: string;
}> = (props) => {
  const toast = useToast();
  return (
    <>
      <SubtitlesM style={{ margin: "1.500rem 0 0.750rem 1.500rem" }}>
        {props.title}
      </SubtitlesM>
      <SectionInfoWrapper style={{ marginBottom: "2.250rem" }}>
        <ParagraphS
          style={{
            padding: 0,
            margin: "0 0 0 1.50rem",
            overflow: "auto",
            maxHeight: "168px",
            height: props.height,
          }}
        >
          {props.formattedValue ? props.formattedValue : props.value}
        </ParagraphS>
        <div style={{ padding: 0, margin: "0 1rem 0 auto" }}>
          <ButtonOutline
            icon={true}
            color={Theme.colors.primaries_000}
            hoverColor={Theme.colors.primaries_neg100}
            noText={false}
            onClick={async () => {
              await navigator.clipboard.writeText(props.value).then(() => {
                toast.success("Copied");
              });
            }}
            img={
              <ClipboardCopyIcon
                width={"0.938rem"}
                color={Theme.colors.primaries_000}
              />
            }
          />
        </div>
      </SectionInfoWrapper>
    </>
  );
};
