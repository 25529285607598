export const GeneralRequestForm = {
  numberOfColumns: 1,
  inputs: [
    {
      id: "status",
      label: "Request Status",
      placeholder: "Request Status",
      copy: true,
      select: true,
      options: [
        { label: "New", value: "new" },
        { label: "Entered", value: "entered" },
        { label: "Processing", value: "processing" },
        { label: "Awaiting Law Firm Info", value: "awaiting_law_firm_info" },
        { label: "Vetted", value: "vetted" },
        { label: "Closed - Not Sent", value: "closed_not_sent" },
        { label: "Needs Further Review", value: "needs_further_review" },
      ],
    },
    {
      id: "serviceDates",
      label: "Service Dates",
      required: true,
      placeholder: "Service Dates",
      copy: true,
    },
    {
      id: "requestedBy",
      label: "Requester",
      required: true,
      placeholder: "Requester",
      copy: true,
    },
    {
      id: "requesterEmails",
      label: "Requester Email",
      placeholder: "Requester Email",
      required: true,
      copy: true,
    },
    {
      id: "submittedDate",
      label: "Request Assigned By Firm",
      placeholder: "Request Assigned By Firm",
      required: true,
      copy: true,
    },
    {
      id: "requestNotes",
      label: "Client Notes",
      placeholder: "Input Text",
      copy: true,
      textArea: true,
      strechEnd: true,
      flex: 1,
      fullScreen: true,
      style: {
        height: "20vh",
      },
    },
  ],
};

export const PatientForm = {
  numberOfColumns: 1,
  inputs: [
    {
      id: "patient.name",
      label: "Patient Name",
      placeholder: "Patient Name",
      plainText: true,
      copy: true,
    },
    {
      id: "patient.ssn",
      label: "SSN",
      placeholder: "SSN",
      plainText: true,
      copy: true,
    },
    {
      id: "patient.dateOfBirth",
      label: "Date of Birth",
      placeholder: "SSN",
      plainText: true,
      copy: true,
      type: "date",
    },
    {
      id: "patient.fullAddress",
      label: "Address",
      placeholder: "Address",
      plainText: true,
      copy: true,
    },
    {
      id: "patient.caseId",
      label: "Case/Matter ID",
      placeholder: "Case/Matter ID",
      plainText: true,
      copy: true,
    },
  ],
};

export const FirmForm = {
  numberOfColumns: 1,
  inputs: [
    {
      id: "firm.name",
      label: "Law Firm Name",
      placeholder: "Firm Name",
      plainText: true,
      copy: true,
    },
  ],
};
