import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { ContentWrapper } from "../../../components/ContentWrapper";
import { Breadcrumb } from "../../../components/molecules/Breadcrumb";
import { TitleM } from "../../../components/atoms/Typography";
import Form from "../../../components/organisms/Form";
import { useCreateUserMutation } from "../../../redux/reduxQuery/usersApi";
import { useMutationWrapper } from "../../../common/api";

const crumbLinks = [
  {
    text: "Admin Settings",
    path: "/admin/settings",
  },
  {
    text: "New Admin",
  },
];

export default function CreateAdmin() {
  let navigate = useNavigate();

  const form = useForm();

  const [createUser] = useCreateUserMutation();
  const mutate = useMutationWrapper(
    createUser,
    "Successfuly Created a New Admin",
    () => navigate("/admin/settings")
  );
  const onSubmit = form.handleSubmit((data) =>
    mutate({ ...data, roles: "admin" })
  );

  const formInputs = [
    {
      id: "name",
      label: "Full Name",
      required: true,
      copy: false,
      addNew: false,
      icon: false,
    },
    {
      id: "email",
      label: "Email",
      required: true,
      copy: false,
      addNew: false,
      icon: false,
      type: "email",
    },
  ];

  return (
    <ContentWrapper>
      <div style={{ marginBottom: "1.5rem" }} />
      <Breadcrumb crumbs={crumbLinks} />
      <TitleM>New Admin</TitleM>
      <Form
        {...form}
        onSubmit={onSubmit}
        formInputs={formInputs}
        numberOfColumns={1}
        submitButtonText={"Create New Admin"}
        cancelAction={() => {
          navigate(-1);
        }}
      />
    </ContentWrapper>
  );
}
