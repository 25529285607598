import React from "react";
import { Link } from "react-router-dom";
import {
  Button,
  ButtonOutline,
  DotButton,
  FocusOutlineButton,
} from "../atoms/Button";
import { Theme } from "../atoms/theme";
import styled from "styled-components";
import { EyeIcon } from "@heroicons/react/outline";
interface BaseProps {
  icons: React.ElementType[];
  onClick1: () => void;
  onClick2: () => void;
  keyValue?: string;
}
interface EyeTrashIconButtonsProps extends BaseProps {
  onClick3?: () => void;
  link: string;
  outline?: boolean;
  target?: string;
}
interface EyeTrashDotIconButtonsProps extends BaseProps {
  options: { label: string; function: () => void }[];
}

export const IconWrapper = styled.div<any>`
  width: ${({ width }) => width || "fit-content"};
  padding: ${({ icon }) => (icon ? "0.75rem 1rem" : "0.75rem")};
  background: ${({ color }) => color};
  border-radius: ${({ theme }) => theme.spacing.spacing_xs};
  margin: ${({ filter, style }) =>
    filter ? "0" : style.margin ? style.margin : "0px 8px"};
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  ${({ outline }) => {
    if (outline)
      return `border:  ${Theme.colors.primaries_000} 0.125rem solid;`;
  }};
  &:hover {
    background: ${({ hovered }) => hovered};
  }
`;

export const EyeTrashDotIconButtons = ({
  icons,
  onClick1,
  onClick2,
  options,
  keyValue,
}: EyeTrashDotIconButtonsProps) => {
  const [Eye, Trash, Dots] = icons;
  return (
    <div style={{ display: "flex" }}>
      <Button
        icon={true}
        color={Theme.colors.primaries_000}
        hoverColor={Theme.colors.primaries_neg100}
        noText={false}
        onClick={onClick1}
        img={<Eye width={"0.938rem"} color={Theme.colors.neutrals_500} />}
      />
      <div style={{ marginRight: ".5rem" }} />
      <ButtonOutline
        icon={true}
        color={Theme.colors.primaries_000}
        hoverColor={Theme.colors.primaries_neg100}
        noText={false}
        onClick={onClick2}
        img={<Trash width={"0.938rem"} color={Theme.colors.primaries_000} />}
      />
      <div style={{ marginRight: ".5rem" }} />
      <DotButton
        keyValue={`${keyValue}-dot`}
        icon={true}
        color={Theme.colors.neutrals_500}
        hoverColor={Theme.colors.primaries_300}
        //contentColor={Theme.colors.primaries_000}
        options={options}
        img={<Dots width={"0.938rem"} color={Theme.colors.primaries_000} />}
      />
    </div>
  );
};

export const EyeNavigationButton: React.FC<{
  link: string;
  icon: any;
  outline: boolean;
  target?: string;
}> = ({ link, outline, target }) => (
  <Link to={link} style={{ display: "flex" }} target={target}>
    <IconWrapper
      outline={outline}
      style={{ margin: "0 0.4rem 0 0" }}
      color={outline ? Theme.colors.neutrals_500 : Theme.colors.primaries_000}
    >
      <EyeIcon
        width={"0.938rem"}
        color={outline ? Theme.colors.primaries_000 : Theme.colors.neutrals_500}
      />
    </IconWrapper>
  </Link>
);

export const EyeTrashIconButtons = ({
  icons,
  onClick1,
  onClick2,
  onClick3,
  link,
  outline = false,
}: EyeTrashIconButtonsProps) => {
  const [Eye, Trash, Third] = icons;
  return (
    <div style={{ display: "flex" }}>
      <EyeNavigationButton outline={outline} link={link} icon={Eye} />
      {Trash && (
        <>
          <ButtonOutline
            icon={true}
            color={Theme.colors.primaries_000}
            hoverColor={Theme.colors.primaries_neg100}
            noText={false}
            filter
            onClick={onClick2}
            img={
              <Trash width={"0.938rem"} color={Theme.colors.primaries_000} />
            }
          />
        </>
      )}
      {onClick3 && (
        <FocusOutlineButton
          icon={true}
          color={Theme.colors.neutrals_500}
          hoverColor={Theme.colors.primaries_300}
          contentColor={Theme.colors.primaries_000}
          onClick={onClick3}
          img={<Third width={"0.938rem"} color={Theme.colors.primaries_000} />}
        />
      )}
    </div>
  );
};
export const TripleActionButtonsWithNavigationInTheMiddle = ({
  link,
  icons,
  onClick1,
  onClick2,
  onClick3,
  outline = true,
  target,
}: EyeTrashIconButtonsProps) => {
  const [Eye, Trash] = icons;
  return (
    <div style={{ display: "flex", width: "100%" }}>
      <Button
        style={{ margin: "0 0.4rem 0 0" }}
        icon={true}
        color={Theme.colors.primaries_000}
        hoverColor={Theme.colors.primaries_neg100}
        noText={false}
        filter
        onClick={onClick1}
        img={<Eye width={"0.938rem"} color={Theme.colors.neutrals_500} />}
      />
      <EyeNavigationButton
        outline={outline}
        icon={Eye}
        link={link}
        target={target}
      />
      <Button
        style={{ margin: 0 }}
        hoverColor={Theme.colors.primaries_300}
        icon={true}
        noText={false}
        filter
        onClick={onClick3}
        img={<Trash width={"1rem"} color={Theme.colors.primaries_000} />}
      />
    </div>
  );
};
