import { Dispatch, Fragment, SetStateAction, useEffect, useState } from "react";
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  usePagination,
  useFilters,
} from "react-table";
import styled from "styled-components";
import { XIcon } from "@heroicons/react/outline";
import get from "lodash/get";
import { TitleM } from "../atoms/Typography";
import { Input } from "../atoms/Input";
import { Button, ButtonOutline } from "../atoms/Button";
import { Theme } from "../atoms/theme";
import { useForm } from "react-hook-form";
import { CustomSelect } from "../atoms/Select";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../hooks/redux";
import { setUserFilter } from "../../redux/reducers/filter";
import {
  getColumnFilterValues,
  multipleColumnMappingProviders,
} from "../../utils/multipleColumnMappingProviders";

const FilterWrapper = styled.div`
  display: flex;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-color: ${Theme.colors.neutrals_100};
  opacity: 0.5;
  z-index: 998;
`;

const FilterSideBar = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0px;
  right: 0px;
  width: 21.25rem;
  background-color: ${Theme.colors.neutrals_500};
  opacity: 1;
  height: 100vh;
  z-index: 999;
  overflow: scroll;
`;

const FilterTitleContainer = styled.div`
  display: flex;
  margin-left: 2rem;
  margin-top: 2rem;
`;

const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 2rem;
  margin-right: 2rem;
`;
interface IFilter {
  columns: any;
  data: any;
  rowClick?: any;
  onClick?: any;
  showAddNewConnection?: any;
  openModal?: any;
  tableType?: string;
  openFilters?: boolean;
  onPaginationChange?: (any) => void;
  setOpenFilters?: Dispatch<SetStateAction<boolean>>;
  setPage?: Dispatch<SetStateAction<number>>;
  setFilterValues?: Dispatch<SetStateAction<any>>;
}

export const Filter = ({
  columns,
  data,
  rowClick,
  tableType,
  openFilters,
  setOpenFilters = () => {},
  setPage,
  onPaginationChange,
  setFilterValues,
}: IFilter) => {
  const tableInstance = useTable(
    {
      columns,
      data,
      rowClick,
      initialState: { pageSize: 50 },
      autoResetSortBy: false,
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination
  );
  const { allColumns, setFilter } = tableInstance;

  const [filterInputs, setFilterInputs] = useState<any>([]);
  const form = useForm();
  const dispatch = useDispatch();
  const globalUserFilter = useAppSelector((state) => state.filter);

  const filtersLength = Object.values(form.watch()).reduce((acc, curr) => {
    if ((typeof curr === "string" && curr === "") || curr === undefined) {
      return acc;
    } else if (curr === null) {
      return acc;
    } else if (
      typeof curr === "object" &&
      (Object.values(curr)[0] === undefined ||
        Object.values(curr)[0] === "" ||
        Object.values(curr)[0] === null)
    ) {
      return acc;
    } else return acc + 1;
  }, 0);
  const [pageIndex, setPageIndex] = useState(0);
  const cancelFilters = () => {
    filter();
    setOpenFilters(false);
  };

  const closeFilters = () => {
    setOpenFilters(false);
  };

  useEffect(() => {
    setPageIndex && setPageIndex(pageIndex);
    setPage && setPage(pageIndex + 1);
  }, [pageIndex, setPageIndex, setPage]);

  useEffect(() => {
    let newData: any[] = [];
    allColumns.map((column: any) => {
      if (multipleColumnMappingProviders[column.id]) {
        multipleColumnMappingProviders[column.id].forEach((columnMapping) => {
          newData.push({
            id: columnMapping.id,
            label: columnMapping.Header,
            type: "text",
            required: false,
            clearButton: true,
            select: !!columnMapping.options,
            options: columnMapping.options,
            canFilter: columnMapping.canFilter,
          });
        });
      }
      if (!multipleColumnMappingProviders[column.id]) {
        newData.push({
          id: column.id,
          label: column.Header,
          type: "text",
          required: false,
          clearButton: true,
          select: !!column.options,
          options: column.options,
          canFilter: column.canFilter,
        });
      }
      return column;
    });

    setFilterInputs(newData);
  }, [allColumns]);

  useEffect(() => {
    const filters = globalUserFilter[tableType || ""];
    Object.keys(filters || []).forEach((key) => {
      if (get(filters, key)) {
        const value = get(filters, key);
        form.setValue(key, value);
      }
    });
    const newFilterInputValues = form.getValues();
    setFilterValues && setFilterValues({ ...newFilterInputValues });
  }, [globalUserFilter, tableType, form, setFilter, setFilterValues]);

  const filter = form.handleSubmit(() => {
    const newFilterInputValues = form.getValues();
    const formUserFilter = {};
    allColumns.map((column) => {
      const columnValues = getColumnFilterValues(column, newFilterInputValues);
      const hasValue = !!columnValues.length;
      if (hasValue) {
        columnValues.map(({ id, value }) => {
          if (value !== "") {
            formUserFilter[id] = value;
          }
          if (typeof value === "string" && value !== "") {
            setFilter(column.id, value);
          } else if (typeof value === "object" && column.id !== "state") {
            const { label } = value;
            setFilter(column.id, label);
          } else if (column.id === "state") {
            const { value: selectValue } = value;
            setFilter(column.id, selectValue);
          } else {
            setFilter(column.id, undefined);
          }
          return null;
        });
      } else {
        setFilter(column.id, undefined);
      }
      return column;
    });

    const globalUserFilters = { [tableType || ""]: formUserFilter };
    dispatch(setUserFilter(globalUserFilters));
    setPageIndex(0);
    setPage && setPage(1);
    setFilterValues && setFilterValues({ ...newFilterInputValues });
    setOpenFilters(false);
  });

  const resetFilters = () => {
    form.reset();
    filter();
  };

  useEffect(() => {
    const handleKeyUp = (event) => {
      if (openFilters) {
        event.preventDefault();
        if (event.key === "Enter") {
          filter();
        }
      }
    };

    document.addEventListener("keyup", handleKeyUp);

    return () => {
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, [openFilters, filter]);

  return (
    <>
      <div>
        {openFilters && (
          <Fragment>
            <FilterWrapper onClick={closeFilters}></FilterWrapper>
            <FilterSideBar>
              <FilterTitleContainer>
                <TitleM>Filter</TitleM>
              </FilterTitleContainer>
              <FilterContainer>
                {filtersLength > 0 && (
                  <div style={{ width: "100%" }}>
                    <ButtonOutline
                      width={"100%"}
                      noText={true}
                      buttonText={`Reset ${filtersLength} filters`}
                      onClick={() => resetFilters()}
                      color={Theme.colors.primaries_000}
                      filter={true}
                    />
                  </div>
                )}

                <form>
                  {filterInputs.map(
                    (input) =>
                      input.canFilter &&
                      (input.select || input.type === "state" ? (
                        <div style={{ marginTop: "2.25rem", display: "flex" }}>
                          <CustomSelect
                            {...form.register(input.id)}
                            label={input.label}
                            id={input.id}
                            options={input.options}
                            setValue={(value) => {
                              form.setValue(input.id, value);
                            }}
                            value={form.watch(input.id)}
                            name={input.name}
                          />
                          {form.watch(input.id)?.value?.length > 0 && (
                            <div style={{ marginTop: "1.75rem" }}>
                              <ResetButton {...{ form, input }} />
                            </div>
                          )}
                        </div>
                      ) : (
                        <div style={{ marginTop: "2.25rem", display: "flex" }}>
                          <Input
                            {...form.register(input.id)}
                            noLabel={true}
                            labelText={input.label}
                            required={input.required}
                            icon={input.icon}
                            img={input.img}
                            width={"100%"}
                            type={input.type}
                            placeholderText={
                              input.placeholder
                                ? input.placeholder
                                : input.label
                            }
                            setFormValue={(value) => {
                              form.setValue(input.id, value);
                              return value;
                            }}
                          />
                          {form.watch(input.id)?.length > 0 && (
                            <div
                              style={{
                                marginTop: "1.15rem",
                              }}
                            >
                              <ResetButton {...{ form, input }} />
                            </div>
                          )}
                        </div>
                      ))
                  )}
                  <div
                    style={{
                      display: "flex",
                      marginBottom: "2.25rem",
                      marginTop: "2.25rem",
                    }}
                  >
                    <ButtonOutline
                      style={{ margin: 0 }}
                      buttonText={"Cancel"}
                      icon={false}
                      color={Theme.colors.primaries_000}
                      hoverColor={Theme.colors.primaries_neg100}
                      noText={true}
                      onClick={() => {
                        cancelFilters();
                      }}
                      width={"100%"}
                    />
                    <Button
                      style={{ marginLeft: "1rem", marginRight: 0 }}
                      buttonText={"Apply"}
                      icon={false}
                      color={Theme.colors.primaries_000}
                      hoverColor={Theme.colors.primaries_neg100}
                      noText={true}
                      width="100%"
                      onClick={() => filter()}
                    />
                  </div>
                </form>
              </FilterContainer>
            </FilterSideBar>
          </Fragment>
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        ></div>
      </div>
    </>
  );
};
function ResetButton({ form, input }) {
  return (
    <ButtonOutline
      icon={true}
      color={Theme.colors.primaries_000}
      hoverColor={Theme.colors.primaries_neg100}
      width={"2.75rem"}
      onClick={() => form.setValue(input.id, null)}
      img={<XIcon width={"0.938rem"} color={Theme.colors.primaries_000} />}
      requiredHoverClick={true}
    />
  );
}
