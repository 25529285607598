export const patientsForm = [
  [
    {
      id: "name",
      label: "Full Name",
      required: true,
      copy: true,
      addNew: false,
      icon: false,
    },
    {
      id: "dateOfBirth",
      label: "Date of birth",
      placeholder: "MM/DD/YYYY",
      required: true,
      copy: true,
      addNew: false,
      icon: false,
      type: "dateOfBirth",
    },
    {
      id: "address",
      label: "Address",
      required: true,
      copy: true,
      addNew: false,
      icon: false,
    },
    {
      id: "city",
      label: "City",
      required: true,
      copy: true,
      addNew: false,
      icon: false,
    },
    {
      id: "stateAndCity",
      formIds: ["state", "zip"],
      formLabels: ["State", "Zip Code"],
      label: "State and Zip Code",
      required: true,
      copy: true,
      addNew: false,
      icon: false,
      type: "stateAndZip",
    },
    {
      id: "ssn",
      label: "SSN",
      required: true,
      copy: true,
      type: "ssn",
    },
  ],
  [
    {
      id: "caseId",
      label: "Case/Matter ID (Optional)",
      required: false,
      copy: true,
      addNew: false,
      icon: false,
      type: "caseId",
    },
    {
      id: "minor",
      label: "Is this patient a minor?",
      required: false,
      checkbox: true,
      subFields: [
        {
          id: "personSigningForMinor",
          label: "Who Signed The Auth?",
          copy: true,
          required: true,
          placeholder: "Enter Name",
          errorText: "Please enter name",
        },
        {
          id: "relationToMinor",
          label: "Relationship To Minor",
          copy: true,
          required: true,
          placeholder: "Enter Relationship",
          errorText: "Please enter relationship",
        },
      ],
      marginBottom: "0rem",
    },
    {
      id: "deceased",
      label: "Is this patient deceased?",
      required: false,
      checkbox: true,
      subFields: [
        {
          id: "personSigningForDeceased",
          label: "Who Signed The Auth?",
          copy: true,
          required: true,
          placeholder: "Enter Name",
          errorText: "Please enter name",
        },
        {
          id: "relationToDeceased",
          label: "Relationship To Deceased",
          copy: true,
          required: true,
          placeholder: "Enter Relationship",
          errorText: "Please enter relationship",
        },
      ],
      marginBottom: "0rem",
    },
    {
      id: "hipaaAuthorizationFile",
      label: "Patient Medical Authorization (HIPAA)",
      required: true,
      file: true,
      type: "hipaaAuthorization",
      canDelete: true,
      canUpload: true,
    },
    {
      id: "otherFiles",
      label: "Other (Ie, Custody Order, Death Certificate, Etc)",
      required: false,
      multiple: true,
      file: true,
      type: "other",
      canDelete: true,
      canUpload: true,
    },
  ],
];
