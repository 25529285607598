import React, { useMemo, useState } from "react";
import { EyeIcon, TrashIcon } from "@heroicons/react/outline";
import { useNavigate } from "react-router-dom";

import { TitleM } from "../../../components/atoms/Typography";
import { CustomTable } from "../../../components/organisms/ShimmerTable";
import { EyeTrashIconButtons } from "../../../components/molecules/TableActionButtons";
import { ContentWrapper } from "../../../components/ContentWrapper";
import { useGetUsersQuery } from "../../../redux/reduxQuery/usersApi";
import { useUser } from "../../../hooks/useLogin";
import { DeleteUserModal } from "./DeleteUserModal";

export default function Request() {
  const loggedInUser = useUser();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<any>({});
  let navigate = useNavigate();
  const { data: users, isFetching, refetch } = useGetUsersQuery("roles=admin");
  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Actions",
        accessor: "actions",
        disableFilters: true,
        Cell: (cellProps) => {
          const user = cellProps.row.original;
          let icons = [EyeIcon];
          if (loggedInUser?.id !== user.id) icons.push(TrashIcon);
          return (
            <EyeTrashIconButtons
              icons={icons}
              link={`/admin/settings/${user.id}`}
              onClick1={() => navigate(`/admin/settings/${user.id}`)}
              onClick2={() => {
                setSelectedUser(cellProps.row.original);
                setIsOpen(true);
              }}
            />
          );
        },
      },
    ],
    [navigate, loggedInUser]
  );

  return (
    <>
      <DeleteUserModal
        isOpen={isOpen}
        onClose={() => {
          refetch();
          setIsOpen(false);
        }}
        selectedUser={selectedUser}
      />
      <ContentWrapper>
        <TitleM style={{ margin: "1rem 0" }}>admins</TitleM>
        <CustomTable
          tableType="admins"
          columns={columns}
          data={users}
          isLoading={isFetching}
          navigation={() => navigate("/admin/settings/new")}
        />
      </ContentWrapper>
    </>
  );
}
