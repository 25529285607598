import React from "react";
import styled from "styled-components";
import { SheetButtonWrapperProps } from "../../types/atoms/buttonTypes";
import UserIcon from "../../components/assets/User.svg";
import { ParagraphS, SubtitlesM } from "../atoms/Typography";

const SheetDetailedButtonWrapper = styled.button<SheetButtonWrapperProps>`
  width: 100%;
  padding: 0.75rem 1rem;
  background: none;
  border-radius: ${({ theme }) => theme.spacing.spacing_xs};
  display: flex;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  #text {
    color: ${({ theme }) => theme.colors.neutrals_000};
    text-align: left;
  }
  &:hover,
  :hover #text,
  :hover svg {
    background: ${({ theme }) => theme.colors.primaries_300};
  }
`;

export const PatientSheetDetailedButton = ({ patientData, onClick }) => {
  return (
    <SheetDetailedButtonWrapper onClick={onClick}>
      <div
        style={{
          minWidth: "2.1rem",
          height: "2.1rem",
          backgroundColor: "#AE3EC9",
          borderRadius: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginRight: "1rem",
          position: "relative",
          padding: "0.5rem",
        }}
      >
        <img src={UserIcon} alt={"Patient"} />
      </div>
      <div style={{ textAlign: "left" }}>
        <SubtitlesM style={{ margin: 0 }}>{patientData?.name}</SubtitlesM>
        <ParagraphS style={{ margin: 0 }}>
          Case/Matter ID &bull; {patientData?.caseId || "N/A"}
        </ParagraphS>
      </div>
    </SheetDetailedButtonWrapper>
  );
};

export default PatientSheetDetailedButton;
