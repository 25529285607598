import ShimmerEffect from "react-shimmer-effect";
import styled from "styled-components";
import { PlusIcon, AdjustmentsIcon } from "@heroicons/react/outline";

import searchIcon from "../assets/search.svg";
import { Theme } from "../atoms/theme";
import {
  TableWrapper,
  TableStyle,
  TableH,
  HeaderText,
  HeaderWrapper,
  InputsWrapper,
  TableButtonsWrapper,
} from "./SharedTableStyles";
import { Input } from "../atoms/Input";
import { Table } from "./Table";
import { Button, ButtonOutline } from "../atoms/Button";
import { Dispatch, SetStateAction } from "react";

const ShimmerShape = styled.div`
  height: calc(38px - 1.75rem);
  width: 90%;
`;

interface ICustomTable {
  columns: any;
  showFilter?: any;
  data: any;
  isLoading?: any;
  navigation?: any;
  tableType?: string;
  setPage?: Dispatch<SetStateAction<number>>;
  dataSize?: number;
  searchByPage?: boolean;
  setFilterValues?: Dispatch<SetStateAction<any>>;
  keyToSearch?: string;
  totalAmountOfRecords?: number;
  onScroll?: any;
  scrollPosition?: number;
}

export const CustomTable = ({
  columns,
  showFilter,
  data,
  isLoading,
  navigation,
  tableType,
  setPage,
  totalAmountOfRecords,
  searchByPage,
  setFilterValues,
  keyToSearch,
  onScroll,
  scrollPosition,
}: ICustomTable) => {
  const headers = columns.map((column) => column.Header);
  return (
    <>
      {isLoading ? (
        <LoadingTable headers={headers} showFilter={showFilter} />
      ) : data ? (
        <Table
          columns={columns}
          data={data}
          tableType={tableType}
          onClick={() => navigation()}
          setPage={setPage}
          totalAmountOfRecords={totalAmountOfRecords}
          searchByPage={searchByPage}
          setFilterValues={setFilterValues}
          keyToSearch={keyToSearch}
          onScroll={onScroll}
          scrollPosition={scrollPosition}
        />
      ) : null}
    </>
  );
};

const LoadingTable = ({ headers, showFilter }) => (
  <TableWrapper>
    <InputsWrapper>
      <Input
        noLabel={false}
        error={false}
        required={true}
        icon={true}
        width={showFilter ? "76%" : "88%"}
        placeholderText="Search"
        img={searchIcon}
        type="text"
      />
      <TableButtonsWrapper showFilter={showFilter}>
        {showFilter && (
          <ButtonOutline
            icon={true}
            color={Theme.colors.primaries_000}
            hoverColor={Theme.colors.primaries_neg100}
            noText={true}
            width={"40%"}
            buttonText="filters"
            img={
              <AdjustmentsIcon
                width={"0.938rem"}
                color={Theme.colors.primaries_000}
              />
            }
          />
        )}
        <Button
          icon={true}
          color={Theme.colors.primaries_000}
          hoverColor={Theme.colors.primaries_neg100}
          noText={true}
          width={showFilter ? "50%" : "8rem"}
          buttonText="add new"
          img={
            <PlusIcon width={"0.938rem"} color={Theme.colors.neutrals_500} />
          }
        />
      </TableButtonsWrapper>
    </InputsWrapper>
    <TableStyle>
      <thead>
        <tr>
          {headers.map((data, index) => (
            <TableH key={`table-header-loading-${index}`}>
              <HeaderWrapper>
                <HeaderText>{data}</HeaderText>
              </HeaderWrapper>
            </TableH>
          ))}
        </tr>
      </thead>
      <tbody>
        {[1, 2, 3, 4, 5].map(() => (
          <tr>
            {headers.map((_, index) => (
              <td
                style={{ paddingTop: "1.75rem", paddingBottom: "1.75rem" }}
                key={`table-body-loading-${index}`}
              >
                <ShimmerEffect>
                  <ShimmerShape />
                  <ShimmerShape />
                </ShimmerEffect>
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </TableStyle>
  </TableWrapper>
);
