export const formatProviderType = (type: string) => {
  if (!type) return "";
  const words = type
    .split("_")
    .map((w) => `${w.charAt(0).toUpperCase()}${w.substring(1)}`);
  return words.join(" ");
};

export const formatObjectValues = (value: string) => {
  if (!value) return "";
  if (value[0] === "{") {
    return JSON.parse(value).label;
  } else {
    return value;
  }
};

export const formatPhoneOrFaxNumber = (digits: string) => {
  if (!digits) return "";
  if (digits.length !== 10) return digits;
  return `(${digits.substring(0, 3)}) ${digits.substring(
    3,
    6
  )}-${digits.substring(6)}`;
};

export const formatSSN = (ssn: string) => {
  if (ssn?.length === 4) {
    return `XXX-XX-${ssn}`;
  } else if (ssn?.length === 9) {
    return `${ssn.substring(0, 3)}-${ssn.substring(3, 5)}-${ssn.substring(
      5,
      9
    )}`;
  } else {
    return ssn;
  }
};
