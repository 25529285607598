// Only records that have a FileVine ID have been synced.

import { CloudUploadIcon, CheckCircleIcon } from "@heroicons/react/outline";
import { useState } from "react";
import { Badge } from "../components/atoms/Badge";
import { Button } from "../components/atoms/Button";
import { Theme } from "../components/atoms/theme";
import { Tooltip } from "../components/atoms/Tooltip";
import { ModalWithTwoButtons } from "../components/molecules/Modal";
import useToast from "../hooks/useToast";
import { useUploadFileVineRecordMutation } from "../redux/reduxQuery/fileVineApi";

// (ONLY WORKS WITH PROVIDERS AND PATIENTS)
export const isRecordSyncedWithFileVine = (record) =>
  record && !!record.fileVineID;

export const UploadFVButton: React.FC<{
  record: any;
  type: "patient" | "provider";
  modalTitle: string;
  modalContent: string;
  refetch: any;
}> = (props) => {
  const [openModalFVSync, setOpenModalFVSync] = useState(false);
  const [uploadToFileVine] = useUploadFileVineRecordMutation();
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const alreadySynced = isRecordSyncedWithFileVine(props.record);
  const handleButtonClick = () => {
    if (alreadySynced)
      window.open(
        `https://app.filevine.com/#/addressbook/8443/contact/${props.record.fileVineID}`,
        "_blank"
      );
    else {
      setOpenModalFVSync(true);
    }
  };
  return (
    <>
      <Button
        icon={true}
        color={
          alreadySynced ? Theme.colors.success_000 : Theme.colors.warning_000
        }
        hoverColor={
          alreadySynced
            ? Theme.colors.success_neg100
            : Theme.colors.warning_neg100
        }
        noText={true}
        buttonText={alreadySynced ? "Uploaded" : "Upload"}
        img={
          alreadySynced ? (
            <CheckCircleIcon
              width={"1.25rem"}
              color={Theme.colors.neutrals_500}
            />
          ) : (
            <CloudUploadIcon
              width={"1.25rem"}
              color={Theme.colors.neutrals_500}
            />
          )
        }
        onClick={() => handleButtonClick()}
      />
      {openModalFVSync && (
        <ModalWithTwoButtons
          title="Upload Record to Filevine"
          content={`Are you sure you want to upload ${props.record.name}'s information to Filevine?`}
          button1Text="cancel"
          button2Text="upload"
          loader={loading}
          onClick1={() => setOpenModalFVSync(false)}
          onClick2={() => {
            setLoading(true);
            uploadToFileVine({
              id: props.record.id,
              type: props.type,
            })
              .unwrap()
              .then(() => {
                toast.success(
                  `Successfully Uploaded ${props.record.name} to Filevine`
                );
                props.refetch();
              })
              .catch((err) => {
                const errorMessage: string =
                  err?.message ||
                  err?.data?.message ||
                  String(JSON.stringify(err));
                if (errorMessage && errorMessage.includes("Please enter"))
                  toast.error(errorMessage);
                else
                  toast.error(
                    `Unable to Upload ${props.record.name} to Filevine: ${errorMessage}`
                  );
              })
              .finally(() => {
                setOpenModalFVSync(false);
                setLoading(false);
              });
          }}
        />
      )}
    </>
  );
};

export const RenderCell = (cellProps: any, scrollPosition?: number) => {
  const isSyncedWithFileVine = isRecordSyncedWithFileVine(
    cellProps.row.original
  );
  if (isSyncedWithFileVine)
    return (
      <Tooltip
        text="This record has been created in Filevine"
        position="top"
        background="black"
        scrollPosition={scrollPosition}
      >
        <Badge color={Theme.colors.success_000} icon />
      </Tooltip>
    );
  else
    return (
      <Tooltip
        text="This record has not been created in FileVine"
        position="top"
        background="black"
        scrollPosition={scrollPosition}
      >
        <Badge
          color={Theme.colors.warning_000}
          icon
          img={
            <CloudUploadIcon
              width={"0.938rem"}
              color={Theme.colors.neutrals_500}
            />
          }
        />
      </Tooltip>
    );
};
