import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ParagraphS, TitleM } from "../../../components/atoms/Typography";
import { ContentWrapper } from "../../../components/ContentWrapper";
import { useDeleteRequestMutation } from "../../../redux/reduxQuery/requestApi";
import { ModalWithTwoButtons } from "../../../components/molecules/Modal";
import { CoreTable } from "../../../components/organisms/CoreTable";
import { statusColor } from "../../../utils/statusColor";
import { statusLabel } from "../../../utils/statusLabel";
import { filtersRequest } from "../../../utils/filtersRequest";
import { filterDuplicateSelectOptions } from "../../../utils/filterDuplicateSelectOptions";
import { useActionButtons } from "./table/actionButtons";
import { useGetData } from "./table/data";
import { useColumns } from "./table/columns";
import { useAppSelector } from "../../../hooks/redux";
import { setPageSize, setSearchParam } from "../../../redux/reducers/filter";
import { useDispatch } from "react-redux";

export const Status = (props) => {
  return (
    <ParagraphS
      color={statusColor[props.status]}
      style={{ margin: 0, textTransform: "capitalize" }}
    >
      {statusLabel[props.status]}
    </ParagraphS>
  );
};
interface selectProps {
  value: string;
  label: string;
}

export default function Request() {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [requestId] = useState("");
  const [requestStatus] = useState("");
  const [requestPatientName] = useState("");
  const [requestFirmName] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize] = useState(50);
  const [deleteRequest] = useDeleteRequestMutation();
  const [patients, setPatients] = useState<selectProps[]>([]);
  const [medicalProvider, setMedicalProvider] = useState<selectProps[]>([]);
  const [totalAmountOfRecords, setTotalAmountOfRecords] = useState(0);
  const globalUserFilter = useAppSelector((state) => state.filter);
  const reshapedGlobalUserFilter = useMemo(() => {
    const reshapedFilter =
      (globalUserFilter["request"] &&
        JSON.parse(JSON.stringify(globalUserFilter["request"]))) ??
      {};
    if (globalUserFilter["request"]) {
      Object.keys(globalUserFilter["request"]).forEach((key) => {
        const filter = globalUserFilter["request"]?.[key];
        if (typeof filter === "object") {
          if (!filter?.name && reshapedFilter && key.includes(".")) {
            delete reshapedFilter[key];
            reshapedFilter[key.split(".")[0]] = {
              name: filter,
            };
          }
        }
      });
      return reshapedFilter;
    }
  }, [globalUserFilter]);
  const [filterValues, setFilterValues] = useState(
    reshapedGlobalUserFilter ?? {}
  );
  const statePageSize = globalUserFilter.requestControl?.pageSize;
  const filterString = useMemo(() => {
    return filtersRequest(
      reshapedGlobalUserFilter ?? {},
      "",
      page,
      Number(statePageSize ?? 50)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    page,
    pageSize,
    reshapedGlobalUserFilter,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    statePageSize,
  ]);
  const { config, refetch, setDataToFetch, dataToFetch } = useGetData(
    filterString ?? ""
  );
  const { columns } = useColumns(medicalProvider, patients);
  const { loading: isLoading } = config;
  const data: any = config?.data;

  const { actionButtonsConfig, openFilters, setOpenFilters } =
    useActionButtons();

  const getFillSelect = useCallback(() => {
    if (data) {
      setTotalAmountOfRecords(data.total);
      const patientOptions = data.map((item) => ({
        value: item.patient?.id,
        label: item.patient?.name,
      }));
      setPatients((prevState) =>
        filterDuplicateSelectOptions([...prevState, ...patientOptions])
      );
      const providerOptions = data?.map((item) => ({
        value: item.provider?.id,
        label: item.provider?.name,
      }));
      setMedicalProvider((prevState) =>
        filterDuplicateSelectOptions([...prevState, ...providerOptions])
      );
    }
  }, [data]);

  useEffect(() => {
    if (isLoading) {
      return;
    } else {
      getFillSelect();
    }
  }, [isLoading, getFillSelect]);

  const handleFilters = useCallback(
    async (pageChange) => {
      const qs = filtersRequest(filterValues, pageChange, page, pageSize);
      if (dataToFetch !== qs) {
        setDataToFetch(qs);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filterValues]
  );

  useEffect(() => {
    handleFilters(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValues]);

  useEffect(() => {
    handleFilters(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageSize]);

  useEffect(() => {
    dispatch(
      setPageSize({
        requestControl: { pageSize: config.asyncConfig.pageSize ?? 50 },
      })
    );
  }, [config?.asyncConfig?.pageSize, dispatch]);

  useEffect(() => {
    dispatch(
      setSearchParam({
        requestControl: { search: config?.asyncConfig?.facets?.search },
      })
    );
  }, [config?.asyncConfig?.facets?.search, dispatch]);

  useEffect(() => {
    config.asyncConfig.onPaginationChange({
      pageSize: Number(globalUserFilter.requestControl?.pageSize ?? 50),
      pageIndex: 0,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {isOpen && (
        <ModalWithTwoButtons
          title="delete request"
          content={`Are you sure you want to delete with 
            status ${requestStatus} for ${requestPatientName} 
            at ${requestFirmName}? If you do you won’t be able to recover it.`}
          button1Text="delete"
          button2Text="cancel"
          loader={isProcessing}
          onClick1={async () => {
            setIsProcessing(true);
            await deleteRequest(requestId);
            refetch();
            setIsProcessing(false);
            setIsOpen(false);
          }}
          onClick2={() => setIsOpen(false)}
        />
      )}
      <ContentWrapper>
        <TitleM style={{ margin: "1rem 0" }}>Requests</TitleM>
        <CoreTable
          tableType="request"
          columns={columns}
          isLoading={isLoading}
          setPage={setPage}
          totalAmountOfRecords={totalAmountOfRecords}
          searchByPage={true}
          setFilterValues={setFilterValues}
          actionButtonsConfig={actionButtonsConfig}
          openFilters={openFilters}
          setOpenFilters={setOpenFilters}
          pageSize={Number(globalUserFilter.requestControl?.pageSize ?? 50)}
          {...config}
        />
      </ContentWrapper>
    </>
  );
}
