import React, {
  useEffect,
  useMemo,
  useState,
  useCallback,
  Fragment,
} from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { EyeIcon, TrashIcon, SaveIcon } from "@heroicons/react/outline";
import styled from "styled-components";

import { TitleM, TitleXS } from "../../../components/atoms/Typography";
import { Tooltip } from "../../../components/atoms/Tooltip";
import { Badge } from "../../../components/atoms/Badge";
import { Breadcrumb } from "../../../components/molecules/Breadcrumb";
import { Button, ButtonOutline } from "../../../components/atoms/Button";
import { Theme } from "../../../components/atoms/theme";
import { ContentWrapper } from "../../../components/ContentWrapper";
import Form from "../../../components/organisms/Form";
import { useGetPatientsQuery } from "../../../redux/reduxQuery/patientApi";
import { useGetRequestsQuery } from "../../../redux/reduxQuery/requestApi";
import { EyeTrashIconButtons } from "../../../components/molecules/TableActionButtons";
import { CustomTable } from "../../../components/organisms/ShimmerTable";
import { formatDate } from "../../../utils/formatDate";
import {
  useUpdateFirmMutation,
  useDeleteFirmMutation,
  useGetFirmQuery,
} from "../../../redux/reduxQuery/firmApi";
import useToast from "../../../hooks/useToast";
import { getRequestsFromRequests } from "../../../common/request";
import { Status } from "../Requests";
import { RenderCell } from "../../../common/fv";
import { ModalWithTwoButtons } from "../../../components/molecules/Modal";

const UL = styled.ul`
  margin: 0;
  padding-left: 0.938rem;
`;
const RequestList = ({ requests }) => (
  <UL>
    {requests.map((request) => (
      <li key={request}>{request}</li>
    ))}
  </UL>
);

export default function EditLawFirm() {
  const [updateLawFirm] = useUpdateFirmMutation();
  let navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const firmId = id;
  const [isProcessing, setIsProcessing] = useState(false);
  const [deleteLawFirmModal, setDeleteLawFirmModal] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [deleteFirm] = useDeleteFirmMutation();
  const toast = useToast();
  const { data: patientData, isFetching: isFetchingPatient } =
    useGetPatientsQuery(`firmId=${firmId}`, {
      refetchOnMountOrArgChange: true,
    });
  const { data: requestData, isFetching: isFetchingRequest } =
    useGetRequestsQuery(`firmId=${firmId}`, {
      refetchOnMountOrArgChange: true,
    });
  const { data: firmData, isFetching: isFetchingFirm } = useGetFirmQuery(
    firmId,
    { refetchOnMountOrArgChange: true }
  );

  const form = useForm();

  const getFirm = useCallback(() => {
    if (firmData) {
      Object.keys(firmData).forEach((key) => {
        form.setValue(key, firmData[key]);
      });
    }
  }, [firmData, form]);

  useEffect(() => {
    getFirm();
  }, [getFirm]);

  const onSubmit = form.handleSubmit(async (data) => {
    setIsProcessing(true);
    await updateLawFirm({ ...data, state: data.state.value });
    setIsProcessing(false);
    toast.success(`${data.name} was added successfully`);
    navigate("/admin/law-firms");
  });

  const onDelete = async (data) => {
    setButtonLoader(true);
    await deleteFirm(data?.id);
    setButtonLoader(false);
    setDeleteLawFirmModal(false);
    navigate("/admin/law-firms");
  };

  const crumbLinks = [
    {
      text: "Law Firms",
      path: "/admin/law-firms",
    },
    {
      text: firmData?.name,
    },
  ];

  const buttons = [
    <Button
      icon={true}
      color={Theme.colors.primaries_000}
      hoverColor={Theme.colors.primaries_neg100}
      noText={true}
      onClick={onSubmit}
      loader={isProcessing}
      buttonText="save changes"
      img={<SaveIcon width={"1.25rem"} color={Theme.colors.neutrals_500} />}
    />,
    <ButtonOutline
      icon={true}
      color={Theme.colors.primaries_000}
      hoverColor={Theme.colors.primaries_neg100}
      noText={false}
      onClick={() => setDeleteLawFirmModal(true)}
      img={<TrashIcon width={"1.25rem"} color={Theme.colors.primaries_000} />}
    />,
  ];

  const formInputs = [
    [
      {
        id: "name",
        label: "Firm Name",
        required: true,
      },
      {
        id: "contactName",
        label: "User Name",
        required: true,
      },
      {
        id: "contactEmail",
        label: "User Email",
        required: true,
        email: true,
        message: "Please enter valid email.",
      },
    ],
    [
      {
        id: "address",
        label: "Address",
        required: true,
      },
      {
        id: "state",
        label: "State",
        required: true,
        type: "state",
      },
    ],
    [
      {
        id: "city",
        label: "City",
        required: true,
      },
      {
        id: "zip",
        label: "Zip Code",
        required: true,
        zipcode: true,
        message: "Please enter valid zipcode",
      },
    ],
  ];

  const patientColumns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Birth Date",
        accessor: "dateOfBirth",
        Cell: (cell) => {
          const { dateOfBirth } = cell.row.values;
          return formatDate(dateOfBirth);
        },
      },
      {
        Header: "SSN",
        accessor: "ssn",
      },
      {
        Header: "Law Firm",
        accessor: "firm.name",
        includeToolTip: true,
      },
      {
        Header: "FV Status",
        Cell: (cellProps) => RenderCell(cellProps),
      },
      {
        Header: "Actions",
        accessor: "id",
        Cell: (cell) => {
          const patientId = cell.row.values.id;
          return (
            <EyeTrashIconButtons
              link={`/admin/patients/${patientId}`}
              icons={[EyeIcon, TrashIcon]}
              onClick1={() => navigate(`/admin/patients/${patientId}`)}
              onClick2={() => {
                //setIsOpen(true)
              }}
            />
          );
        },
      },
    ],
    [navigate]
  );

  const requestColumns = useMemo(
    () => [
      {
        Header: "Law Firm",
        accessor: "firm.name",
        includeToolTip: true,
      },
      {
        Header: "Patient",
        accessor: "patient.name",
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: (cell) => <Status status={cell.value} />,
      },
      {
        Header: "Requests",
        accessor: (row) => getRequestsFromRequests(row).length,
        Cell: (cellProps) => {
          const request = cellProps.row.original;
          const requests = getRequestsFromRequests(request);
          return (
            <Tooltip
              text={<RequestList requests={requests} />}
              position="top"
              background={Theme.colors.neutrals_000}
            >
              <Badge
                icon={true}
                color={Theme.colors.primaries_000}
                hoverColor={Theme.colors.primaries_neg100}
                badgeText={`${requests.length}`}
                img={
                  <EyeIcon
                    width={"0.938rem"}
                    color={Theme.colors.neutrals_500}
                  />
                }
              />
            </Tooltip>
          );
        },
      },
      {
        Header: "Medical Provider",
        accessor: "provider.name",
        includeToolTip: true,
      },
      {
        Header: "Service Dates",
        accessor: "serviceDates",
      },
      {
        Header: "Submitted",
        accessor: (row) => formatDate(row.submittedDate),
      },
      {
        Header: "Actions",
        accessor: "id",
        Cell: (cell) => {
          const requestId = cell.row.values.id;
          return (
            <EyeTrashIconButtons
              icons={[EyeIcon, TrashIcon]}
              link={`/admin/requests/${requestId}`}
              onClick1={() => navigate(`/admin/requests/${requestId}`)}
              onClick2={() => {
                //setIsOpen(true)
              }}
            />
          );
        },
      },
    ],
    [navigate]
  );

  return (
    <ContentWrapper height={"100%"}>
      <Fragment>
        {deleteLawFirmModal && (
          <ModalWithTwoButtons
            title="Delete Law Firm"
            content={`Are you sure you want to delete ${firmData?.name}? If you do you won’t be able to recover it.`}
            button1Text="Delete"
            button2Text="Cancel"
            loader={buttonLoader}
            onClick1={() => onDelete(firmData)}
            onClick2={() => setDeleteLawFirmModal(false)}
          />
        )}
      </Fragment>
      <div style={{ marginBottom: "1.5rem" }} />
      <Breadcrumb crumbs={crumbLinks} buttons={buttons} />
      <TitleM>{firmData?.name}</TitleM>
      <TitleXS>Client Info</TitleXS>
      <Form
        {...form}
        formInputs={formInputs}
        numberOfColumns={3}
        customSubmit
        style={{ marginBottom: "1.5rem" }}
        loading={isFetchingFirm}
      />

      <TitleXS>Requests</TitleXS>
      <CustomTable
        tableType="request"
        columns={requestColumns}
        data={requestData?.requests || []}
        isLoading={isFetchingRequest}
        navigation={() =>
          navigate("/admin/requests/new", {
            state: {
              defaultValues: {
                firmId: id,
              },
              cameFrom: location.pathname,
            },
          })
        }
      />
      <div style={{ marginBottom: "1.5rem" }} />
      <TitleXS>Patients</TitleXS>
      <CustomTable
        tableType="patients"
        columns={patientColumns}
        data={patientData || []}
        isLoading={isFetchingPatient}
        navigation={() =>
          navigate("new-patient", {
            state: { cameFrom: location.pathname },
          })
        }
      />
      <div style={{ marginBottom: "4rem" }} />
    </ContentWrapper>
  );
}
