import React, { useState, useEffect } from "react";
import { useDeleteProviderMutation } from "../../../redux/reduxQuery/medicalProviderApi";
import { TitleM } from "../../../components/atoms/Typography";
import { ContentWrapper } from "../../../components/ContentWrapper";
import { CoreTable } from "../../../components/organisms/CoreTable";
import { useMutationWrapper } from "../../../common/api";
import { filtersProvider } from "../../../utils/filterProvider";
import { ProviderRequestModal } from "../../../components/molecules/ProviderRequestModal";
import { Modal } from "@tyrannosaurustech/ui";
import { useColumns } from "./table/columns";
import { useActionButtons } from "./table/actionButtons";
import { useGetData } from "./table/data";
import { useAppSelector } from "../../../hooks/redux";

export default function MedicalProvider() {
  const globalFilter = useAppSelector((s) => s.filter?.medical);
  const [isOpen, setIsOpen] = useState(false);
  const [filterValues, setFilterValues] = useState(globalFilter || {});
  const { config, refetch, setDataToFetch, dataToFetch } = useGetData();
  useEffect(() => {
    const qs = filtersProvider(filterValues);
    if (qs !== dataToFetch) {
      setDataToFetch(qs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValues]);
  const [deleteProviderMutation] = useDeleteProviderMutation();
  const [isDeletingProvider, setIsDeletingProvider] = useState(false);
  const [openRequestModal, setOpenRequestModal] = useState(false);
  const { columns, requestsData, providerData } = useColumns(
    setIsOpen,
    setOpenRequestModal
  );
  const { actionButtonsConfig, openFilters, setOpenFilters } =
    useActionButtons();

  const mutateDeleteProvider = useMutationWrapper(
    deleteProviderMutation,
    "Provider deleted successfully",
    () => {
      refetch();
      setIsDeletingProvider(false);
      setIsOpen(false);
    }
  );
  const deleteProvider = () => {
    setIsDeletingProvider(true);
    mutateDeleteProvider(providerData?.id);
  };

  return (
    <>
      <Modal
        title={"Delete Provider"}
        icon="ExclamationTriangleIcon"
        bodyText={`Are you sure you want to delete this provider? This action can't be undone.`}
        open={isOpen}
        variant={"danger"}
        loading={isDeletingProvider}
        setOpen={setIsOpen}
        onConfirm={() => deleteProvider()}
      />
      {openRequestModal && (
        <ProviderRequestModal
          icon={false}
          title="Unable to Delete Medical Provider"
          content="This provider has associated requests. Please dissociate those requests from this provider."
          buttonText="Cancel"
          requests={requestsData}
          onClick={() => {
            setOpenRequestModal(false);
          }}
        />
      )}
      <ContentWrapper>
        <TitleM style={{ margin: "1rem 0" }}>Medical Providers</TitleM>
        <CoreTable
          tableType="medical"
          columns={columns}
          setFilterValues={setFilterValues}
          openFilters={openFilters}
          setOpenFilters={setOpenFilters}
          actionButtonsConfig={actionButtonsConfig}
          searchByPage={true}
          pageSize={50}
          {...config}
        />
      </ContentWrapper>
    </>
  );
}
