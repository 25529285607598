import { PaginatedApi } from "@tyrannosaurustech/ui/build/src/organisms/Table/async";
import { useAppSelector } from "../../hooks/redux";
import api, { API_URL } from "./api";
import { useGet } from "./useGet";

const medicalProviderApi = api.injectEndpoints({
  endpoints: (build) => ({
    getProviders: build.query<any, string>({
      query: (query = "") => `providers?${query}`,
      providesTags: (result, error, arg) => [{ type: "Providers", id: "LIST" }],
    }),
    updateProvider: build.mutation({
      query: (provider) => ({
        url: `providers/${provider.id}`,
        method: `PUT`,
        body: provider,
      }),
      invalidatesTags: (result, error, { id }) => {
        return [
          { type: "Providers", id },
          { type: "Providers", id: "LIST" },
          { type: "Requests", id: "LIST" },
        ];
      },
    }),
    getProvider: build.query({
      query: (id) => `providers/${id}`,
      providesTags: (result, error, id) => [{ type: "Providers", id }],
    }),
    getProviderRequests: build.query({
      query: (id) => `/providers/${id}/requests`,
      providesTags: (result, error, id) => [
        { type: "Providers", id },
        { type: "Requests", id: "LIST" },
      ],
    }),
    deleteProvider: build.mutation({
      query: (id) => ({
        url: `providers/${id}`,
        method: `DELETE`,
      }),
      invalidatesTags: (result, error, id) => [
        { type: "Providers", id },
        { type: "Providers", id: "LIST" },
      ],
    }),
    getProviderConnections: build.query({
      query: (id) => `/providers/${id}/connections`,
      providesTags: (result, error, id) => [
        { type: "Providers", id },
        { type: "Providers", id: "LIST" },
      ],
    }),
    updateProviderConnection: build.mutation({
      query: (body) => ({
        url: `providers/connections`,
        method: `POST`,
        body: body,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "Providers", id },
        { type: "Providers", id: "LIST" },
      ],
    }),
    deleteProviderConnection: build.mutation({
      query: (body) => ({
        url: `/providers/${body.id}/connections/${body.connectionId}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "Providers", id },
        { type: "Providers", id: "LIST" },
      ],
    }),
    createProvider: build.mutation({
      query: (provider) => ({
        url: `providers`,
        method: `POST`,
        body: provider,
      }),
      invalidatesTags: [{ type: "Providers", id: "LIST" }],
    }),
  }),
});

export const useSearchProvidersByName = () => {
  const token = useAppSelector((s) => s.auth.token);
  return async (name: string) =>
    await fetch(`${API_URL}/providers-search?name=${name}`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }).then((res) => res.json());
};

export const {
  useGetProvidersQuery,
  useGetProviderRequestsQuery,
  useUpdateProviderMutation,
  useGetProviderQuery,
  useDeleteProviderMutation,
  useGetProviderConnectionsQuery,
  useUpdateProviderConnectionMutation,
  useDeleteProviderConnectionMutation,
  useCreateProviderMutation,
} = medicalProviderApi;

export const useGetMedicalProviders = (filter): PaginatedApi<any> => {
  const get = useGet();
  return async (page, pageSize, sort, searchString) => {
    const take = pageSize || 100;
    const skip = page * pageSize || 0;
    const selectedSort = sort && Array.isArray(sort) ? sort[0] : {};
    const orderBy = selectedSort?.id;
    const desc = selectedSort?.desc ? "DESC" : "ASC";

    const response = await get(
      `${API_URL}/providers?${filter}${
        searchString ? `&name=${searchString}` : ""
      }&take=${take}&skip=${skip}${
        orderBy ? `&orderBy=${orderBy}&desc=${desc}` : ""
      }`
    );
    const json = await response.json();
    const { providers, total } = json;
    return {
      results: providers,
      pageCount: Math.ceil(total / pageSize),
      total,
    };
  };
};
