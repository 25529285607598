import React, { Fragment } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

//Layout for the navbar
import { Layout } from "./components/Layout";

// Client Portal
import ClientPortalHome from "./screens/ClientPortal";
import CreateRequest from "./screens/ClientPortal/CreateRequest";
import ViewRequest from "./screens/ClientPortal/ViewRequest";
// Requests
import Requests from "./screens/AdminPortal/Requests";
// import EditRequest from "./screens/AdminPortal/Requests/EditRequest";
import EditRequestV2 from "./screens/AdminPortal/Requests/EditRequestV2";

import AdminCreateRequest from "./screens/AdminPortal/Requests/CreateRequest";

// Law Firms
import LawFirms from "./screens/AdminPortal/LawFirms";
import CreateLawFirm from "./screens/AdminPortal/LawFirms/CreateLawFirm";
import EditLawFirm from "./screens/AdminPortal/LawFirms/EditLawFirm";

// Medical Providers
import MedicalProvider from "./screens/AdminPortal/MedicalProviders";
import CreateMedicalProvider from "./screens/AdminPortal/MedicalProviders/CreateMedicalProvider";
import EditMedicalProvider from "./screens/AdminPortal/MedicalProviders/EditMedicalProvider";

// Patients
import Patients from "./screens/AdminPortal/Patients";
import CreatePatient from "./screens/AdminPortal/Patients/CreatePatient";
import EditPatient from "./screens/AdminPortal/Patients/EditPatient";

// Settings
import AdminSettings from "./screens/AdminPortal/Settings";
import CreateAdmin from "./screens/AdminPortal/Settings/CreateAdmin";
import EditAdmin from "./screens/AdminPortal/Settings/EditAdmin";
import CreateUser from "./screens/ClientPortal/CreateUser";
import ClientUsers from "./screens/ClientPortal/ClientUsers";
import ViewUser from "./screens/ClientPortal/ViewUser";
import { useLogin } from "./hooks/useLogin";
import Logout from "./screens/Login/Logout";
import { useAuth0 } from "@auth0/auth0-react";
import { Spinner } from "@tyrannosaurustech/ui";

export default function RouteNavigator() {
  const { isAuthenticated, isLoading, user } = useLogin();
  const { loginWithRedirect } = useAuth0();

  const authLogin = async () => {
    await loginWithRedirect();
  };
  return (
    <Router>
      {isAuthenticated && !isLoading ? <Layout /> : null}
      <Routes>
        {!isAuthenticated && !isLoading && authLogin()}
        {isAuthenticated &&
          !isLoading &&
          user &&
          (user.isClient() ? (
            <>
              {/* Client Portal Routes */}
              <Route
                path="client/:id/requests"
                element={<ClientPortalHome />}
              ></Route>
              <Route
                path="client/:id/view-request/:requestId"
                element={<ViewRequest />}
              ></Route>
              <Route
                path="client/:id/new-request"
                element={<CreateRequest />}
              ></Route>
              <Route
                path="client/:id/new-user"
                element={<CreateUser />}
              ></Route>
              <Route path="client/:id/users" element={<ClientUsers />}></Route>
              <Route
                path="client/:id/users/:userId"
                element={<ViewUser />}
              ></Route>
              <Route
                path="client/:id/new-request/new-patient"
                element={<CreatePatient />}
              ></Route>
              <Route
                path="client/:id/new-request/new-medical-provider"
                element={<CreateMedicalProvider />}
              ></Route>
              {/* Client Default Route */}
              <Route
                path="/"
                element={
                  <Navigate
                    to={`/client/${user?.firmId}/requests`}
                    replace={true}
                  />
                }
              ></Route>
            </>
          ) : (
            <>
              {/* Admin Portal Routes */}
              {/* Requests */}
              <Route path="admin/requests" element={<Requests />}></Route>
              <Route
                path="admin/requests/:id"
                element={<EditRequestV2 />}
              ></Route>
              <Route
                path="admin/requests/new"
                element={<AdminCreateRequest />}
              ></Route>
              {/* Law Firms */}
              <Route path="admin/law-firms" element={<LawFirms />}></Route>
              <Route
                path="admin/law-firms/new"
                element={<CreateLawFirm />}
              ></Route>
              <Route
                path="admin/law-firms/:id"
                element={<EditLawFirm />}
              ></Route>
              <Route
                path="admin/law-firms/:lawFirmId/new-patient"
                element={<CreatePatient />}
              ></Route>
              {/* Medical Providers */}
              <Route
                path="admin/medical-providers"
                element={<MedicalProvider />}
              ></Route>
              <Route
                path="admin/medical-providers/new"
                element={<CreateMedicalProvider />}
              ></Route>
              <Route
                path="admin/medical-providers/:id"
                element={<EditMedicalProvider />}
              ></Route>
              {/* Patients */}
              <Route path="admin/patients" element={<Patients />}></Route>
              <Route
                path="admin/patients/new"
                element={<CreatePatient />}
              ></Route>
              <Route
                path="admin/patients/:id"
                element={<EditPatient />}
              ></Route>
              {/* Admin Settings */}
              <Route path="admin/settings" element={<AdminSettings />}></Route>
              <Route
                path="admin/settings/new"
                element={<CreateAdmin />}
              ></Route>
              <Route path="admin/settings/:id" element={<EditAdmin />}></Route>
              {/* Admin Default Route */}
              <Route
                path="/"
                element={<Navigate to="/admin/requests" replace={true} />}
              ></Route>
              <Route
                path="admin"
                element={<Navigate to="/admin/requests" replace={true} />}
              ></Route>
            </>
          ))}
        {/* Default route */}
        {isLoading && <Route path="*" element={<Loading />} />}
        {!isLoading && (
          <Route path="*" element={<Navigate to="/" replace={true} />}></Route>
        )}
        <Route path="/logout" element={<Logout />} />
      </Routes>
    </Router>
  );
}

const Loading = () => {
  return (
    <div className="h-screen w-screen flex justify-center items-center">
      <Spinner colorClassName="text-primary" />
    </div>
  );
};
