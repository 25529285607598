import styled from "styled-components/macro";
import { ToastVariants } from "../../constants/variants";
import { Alert } from "@tyrannosaurustech/ui";
import { hide } from "../../redux/reducers/toast";
import { useDispatch } from "react-redux";
interface ToastProps {
  variant: string;
  withIcon: boolean;
  children: string;
}

const ToastWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  width: 37.5rem;
  position: absolute;
  top: 1vh;
  left: 60vw;
  z-index: 1;
  animation: fadeIn 0.5s;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const Toast = ({ variant, withIcon, children }: ToastProps) => {
  const dispatch = useDispatch();
  const color = () => {
    switch (variant) {
      case ToastVariants.DANGER:
        return "danger";
      case ToastVariants.WARNING:
        return "warning";
      case ToastVariants.SUCCESS:
      default:
        return "success";
    }
  };

  const Icon = () => {
    switch (variant) {
      case ToastVariants.DANGER:
        return "ExclamationCircleIcon";
      case ToastVariants.WARNING:
        return "ExclamationTriangleIcon";
      case ToastVariants.SUCCESS:
      default:
        return "CheckCircleIcon";
    }
  };
  return (
    <ToastWrapper>
      <Alert
        title={variant}
        message={children}
        open={true}
        color={color()}
        className="h-[5.1rem] items-center border"
        icon={withIcon ? Icon() : undefined}
        onClose={() => {
          dispatch(hide());
        }}
      />
    </ToastWrapper>
  );
};
