export const requestsFormInput = {
  id: "requests",
  checkLabels: [
    "Bills",
    "Records",
    "ER Physicians Billing",
    "Radiology Billing",
    "Anesthesia Billing",
    "Hospital Abstract (in lieu of full recs)",
    "CD of Radiology Images and Films",
  ],
  switchLabel: "Certified",
  checkAndSwitch: true,
};
