export type optionType = {
  value: string;
  label: string;
};

export const filterDuplicateSelectOptions = (options) => {
  const existingOptions = {};
  const uniqueOptions: optionType[] = [];
  options.forEach((item: any) => {
    if (!existingOptions[item.value]) {
      existingOptions[item.value] = item.label;
      uniqueOptions.push(item);
    }
  });

  return uniqueOptions;
};
