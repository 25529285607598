import searchIcon from "../../../components/assets/search.svg";
import { UserAddIcon, TruckIcon } from "@heroicons/react/outline";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { Theme } from "../../../components/atoms/theme";
import { ContentWrapper } from "../../../components/ContentWrapper";
import { Breadcrumb } from "../../../components/molecules/Breadcrumb";
import { TitleM } from "../../../components/atoms/Typography";
import { useSearchPatientsByName } from "../../../redux/reduxQuery/patientApi";
import { useSearchProvidersByName } from "../../../redux/reduxQuery/medicalProviderApi";
import Form from "../../../components/organisms/Form";
import { useCreateRequestMutation } from "../../../redux/reduxQuery/requestApi";
import { useEffect, useState } from "react";
import { useSearchFirmsByName } from "../../../redux/reduxQuery/firmApi";
import { CertificationModal } from "../../../components/molecules/CertificationModal";
import { useRequestCertification } from "../../../hooks/useRequestCertification";
import { RequestTypeFromCheckboxLabelMapping } from "../../../components/atoms/RequestTypes";
import { useMutationWrapper } from "../../../common/api";
function AdminCreateRequest() {
  const [createRequest] = useCreateRequestMutation();
  const {
    showCertifiedRequestModal,
    userLoading,
    showModal,
    buttonLoader,
    checked,
    setCheckbox,
    onCloseModal,
    requesterInfoInputsWithTogglefunction,
  } = useRequestCertification();

  let navigate = useNavigate();
  const { pathname, ...location } = useLocation();
  const state = location.state as any;
  const [loading, setLoading] = useState(false);
  const isFromNestedForms = (pathname) => {
    return (
      pathname === "/admin/patients/new" ||
      pathname === "/admin/medical-providers/new"
    );
  };
  const mutate = useMutationWrapper(
    createRequest,
    () => `Request Was Added Successfully!`,
    () => {
      setLoading(false);
      if (state?.cameFrom && (state?.cameFrom as string).includes("law-firm"))
        return navigate(state.cameFrom);
      else return navigate("/admin/requests");
    }
  );
  const form = useForm();
  useEffect(() => {
    if (state) {
      if (isFromNestedForms(state.cameFrom)) {
        Object.entries(state.formData)?.map((formValue) => {
          formValue[0] === "patient" && state.isCreatedNewPatient === true
            ? form.setValue("patient", {
                ...state.patient,
                value: state.patient.id,
                label: `${state.patient.name}-${state.patient.ssn}`,
              })
            : formValue[0] === "provider" && state.isCreatedNewProvider === true
            ? form.setValue("provider", {
                ...state.provider,
                value: state.provider.id,
                label: state.provider.name,
              })
            : form.setValue(formValue[0], formValue[1]);
          return formValue;
        });
      }

      if (state.defaultValues?.firmId)
        form.setValue("firmId", state.defaultValues?.firmId);
    }
  }, [navigate, form.setValue, state, form]);
  const searchProviders = useSearchProvidersByName();
  const searchPatients = useSearchPatientsByName();
  const searchFirms = useSearchFirmsByName();
  const onSubmit = form.handleSubmit(async (data) => {
    setLoading(true);
    const dataToSubmit = {
      firmId: data.firmId?.value || data.firmId,
      requestedBy: data.requester,
      requesterEmails: data.requesterEmails,
      requestNotes: data.requestNotes,
      serviceDates: data.dateOfService,
      patientId: data.patient.id,
      providerId: data.provider.id,
      status: "new",
      requestBills: !!data["Bills"],
      requestBillsCertified: !!data["Bills Certified"],
      requestRecords: !!data["Records"],
      requestRecordsCertified: !!data["Records Certified"],
      requestRadiology: !!data["CD of Radiology Images and Films"],
      requestRadiologyCertified:
        !!data["CD of Radiology Images and Films Certified"],
      requestERBilling: !!data["ER Physicians Billing"],
      requestERBillingCertified: !!data["ER Physicians Billing Certified"],
      requestRadiologyBilling: !!data["Radiology Billing"],
      requestRadiologyBillingCertified: !!data["Radiology Billing Certified"],
      requestHospitalAbstract:
        !!data["Hospital Abstract (in lieu of full recs)"],
      requestHospitalAbstractCertified:
        !!data["Hospital Abstract (in lieu of full recs) Certified"],
      requestAnesthesiaBilling: !!data["Anesthesia Billing"],
      requestAnesthesiaBillingCertified: !!data["Anesthesia Billing Certified"],
    };
    const medicalRequests = Object.keys(
      RequestTypeFromCheckboxLabelMapping
    ).map((label) => {
      if (data[label]) {
        return {
          requestType: RequestTypeFromCheckboxLabelMapping[label],
          certified: !!data[`${label} Certified`],
        };
      }
      return null;
    });
    const filteredMedicalRequests = medicalRequests.filter(
      (request) => request
    );
    return mutate({
      medicalRequests: filteredMedicalRequests,
      ...dataToSubmit,
    });
  });
  const crumbLinks = [
    {
      text: "Requests",
      path: `/admin/requests`,
    },
    {
      text: "New Request",
    },
  ];
  const formInputs = [
    [
      {
        id: "firmId",
        label: "Firm",
        required: true,
        copy: false,
        select: true,
        asyncConfig: {
          loadOptions: searchFirms,
          getOptionLabel: (p: any) => p.name,
          getOptionValue: (p: any) => p.id,
          placeholder: "Search Firms",
        },
        icon: true,
        img: searchIcon,
      },
      {
        id: "patient",
        label: "Patient",
        required: true,
        copy: false,
        select: true,
        addNewInDropDown: true,
        patientDetailed: true,
        asyncConfig: {
          loadOptions: searchPatients,
          getOptionLabel: (p: any) => p.name,
          getOptionValue: (p: any) => p.id,
          placeholder: "Search Patients",
        },
        icon: true,
        img: searchIcon,
        addNewIcon: (
          <UserAddIcon width={"0.938rem"} color={Theme.colors.primaries_000} />
        ),
        addNewAction: () => {
          navigate("/admin/patients/new", {
            state: {
              formData: form.getValues(),
              cameFrom: pathname,
            },
          });
        },
      },
      {
        id: "provider",
        label: "Medical Provider",
        required: true,
        copy: false,
        select: true,
        addNewInDropDown: true,
        asyncConfig: {
          loadOptions: searchProviders,
          getOptionLabel: (p: any) => p.name,
          getOptionValue: (p: any) => p.id,
          placeholder: "Search Medical Providers",
        },
        icon: true,
        img: searchIcon,
        detailed: true,
        addNewIcon: (
          <TruckIcon width={"0.938rem"} color={Theme.colors.primaries_000} />
        ),
        addNewAction: () => {
          navigate("/admin/medical-providers/new", {
            state: {
              formData: form.getValues(),
              cameFrom: pathname,
            },
          });
        },
      },
      {
        id: "requester",
        label: "Requester Name",
        required: true,
        copy: false,
        notFullLength: true,
      },
      {
        id: "requesterEmails",
        label: "Requester Email/emails",
        required: true,
        copy: true,
        notFullLength: false,
        message: "Please enter requester email",
      },
      requesterInfoInputsWithTogglefunction,
    ],
    [
      {
        id: "dateOfService",
        label: "Dates of service",
        required: true,
        copy: false,
      },
      {
        id: "requestNotes",
        label: "Notes",
        copy: false,
        textArea: true,
      },
    ],
  ];
  return (
    <>
      <ContentWrapper height="100%">
        <div style={{ marginBottom: "1.5rem" }} />
        <Breadcrumb crumbs={crumbLinks} />
        <TitleM>New Request</TitleM>
        <Form
          {...form}
          onSubmit={onSubmit}
          formInputs={formInputs}
          numberOfColumns={2}
          submitButtonText={"Submit Request"}
          loading={loading || userLoading}
          cancelAction={() => {
            navigate("/admin/requests");
          }}
        />
      </ContentWrapper>
      {showModal && (
        <CertificationModal
          onClick={async () => await onCloseModal()}
          checked={checked}
          checkboxOnChange={() => setCheckbox(!checked)}
          disabled={!showCertifiedRequestModal}
          loader={buttonLoader}
        />
      )}
    </>
  );
}
export default AdminCreateRequest;
