import { useParams } from "react-router-dom";
import styled from "styled-components";
import { Theme } from "../../components/atoms/theme";
import {
  ParagraphS,
  SubtitlesM,
  TitleS,
} from "../../components/atoms/Typography";
import { Breadcrumb } from "../../components/molecules/Breadcrumb";
import { useGetRequestQuery } from "../../redux/reduxQuery/requestApi";
import { formatDate } from "../../utils/formatDate";
import { TailSpin } from "react-loader-spinner";
import { useForm } from "react-hook-form";
import Form from "../../components/organisms/Form";
import { useEffect } from "react";
import { useSetRequestFields } from "../AdminPortal/Requests/EditRequest";
import { requestsFormInput } from "./requestConstants";
import { useGetPatientQuery } from "../../redux/reduxQuery/patientApi";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  background: #f1f3f5;
`;
const AlignStartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  width: 100%;
  margin: 0;
`;
const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: ${({ theme }) => theme.shadows.dropshadow_m};
  border-radius: ${({ theme }) => theme.radii.corner_radius_l};
  background: ${({ theme }) => theme.colors.neutrals_500};
`;
const SectionInfoWrapper = styled.div<{ width?: any; style?: any }>`
  width: ${({ width }: any) => width || "100%"};
  display: flex;
  flex-direcion: row;
  align-items: center;
`;
const SectionColumn = styled.div<{ width?: any; style?: any }>`
  display: flex;
  flex-direction: column;
  width: ${({ width }: any) => width || "100%"};
`;
const DivideLine = styled.div<{ height?: any }>`
  display: flex;
  border: 2px solid ${Theme.colors.neutrals_400};
  border-radius: 9999px;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  height: ${({ height }: any) => height || "12rem"};
`;

export default function ViewRequest() {
  const { id, requestId } = useParams();
  const { data: request = {}, isLoading: requestLoading } =
    useGetRequestQuery(requestId);
  const { data: patientData = {}, isLoading: patientLoading } =
    useGetPatientQuery(request?.patient?.id, { skip: !request?.patient?.id });
  const form = useForm();
  const isLoading = requestLoading || patientLoading;
  const fileFormInputs = [
    {
      id: "hipaaAuthorizationFile",
      label: "Patient Medical Authorization (HIPAA)",
      required: false,
      file: true,
      canDelete: false,
      canUpload: false,
      style: {
        width: "5.1rem",
      },
    },
    {
      id: "otherFiles",
      label: "Other (i.e., Custody Order, Death Certificate, Etc)",
      required: false,
      file: true,
      canDelete: false,
      canUpload: false,
      style: {
        width: "5.1rem",
      },
    },
  ];
  useSetRequestFields(request, form);
  useEffect(() => {
    if (request) {
      if (request?.patient?.forms) {
        const hipaaAuthorizationFiles = request?.patient?.forms.filter(
          (f) => f.type === "hipaaAuthorization"
        );
        const otherFiles = patientData?.forms.filter((f) => f.type === "other");

        form.setValue("hipaaAuthorizationFile", hipaaAuthorizationFiles, {
          shouldValidate: true,
        });

        form.setValue("otherFiles", otherFiles, {
          shouldValidate: true,
        });
      }
    }
  }, [request, form, patientData?.forms]);

  const crumbLinks = [
    {
      text: "Requests",
      path: `/client/${id}/requests`,
    },
    {
      text: `${patientData?.name}`,
    },
  ];
  return !isLoading ? (
    <>
      <Wrapper>
        <div style={{ width: "85%", marginTop: "1.50rem" }}>
          <Breadcrumb crumbs={crumbLinks} />
          <AlignStartWrapper style={{ marginTop: "1.50rem" }}>
            <TitleS>Request Info</TitleS>
          </AlignStartWrapper>
          <SectionWrapper>
            <SectionInfoWrapper>
              <SectionColumn width={"50%"}>
                <SectionComponent
                  topMargin
                  header={"Requester Name"}
                  info={request?.firm.name}
                />
                <SectionComponent
                  header={"Dates of Service"}
                  info={request?.serviceDates}
                />
                <SectionComponent
                  header={"Requester Email/emails"}
                  info={request?.requesterEmails || "-"}
                />
                <SubtitlesM style={{ margin: "0 0 0.5rem 1.500rem" }}>
                  Requests
                </SubtitlesM>
                <Form
                  {...form}
                  numberOfColumns={1}
                  customSubmit
                  formInputs={[
                    {
                      ...requestsFormInput,
                      noCheckAndSwitchHeader: true,
                      disabled: true,
                    },
                  ]}
                  style={{
                    minWidth: "none",
                    width: "100%",
                    height: "100%",
                    boxShadow: "none",
                    paddingTop: "0",
                    marginBottom: "0",
                  }}
                />
              </SectionColumn>
              <DivideLine height="26rem" />
              <SectionColumn style={{ marginBottom: "auto" }} width={"50%"}>
                <SectionComponent
                  topMargin
                  header={"Notes"}
                  info={request?.requestNotes}
                />
              </SectionColumn>
            </SectionInfoWrapper>
          </SectionWrapper>
          <AlignStartWrapper style={{ marginTop: "1.50rem" }}>
            <TitleS>Patient Info</TitleS>
          </AlignStartWrapper>
          <SectionWrapper>
            <SectionInfoWrapper>
              <SectionColumn width={"50%"}>
                <SectionComponent
                  topMargin
                  header={"Full Name"}
                  info={patientData?.name}
                />
                <SectionComponent
                  header={"Date of Birth"}
                  info={formatDate(patientData?.dateOfBirth)}
                />
                <SectionComponent
                  header={"Address"}
                  info={patientData?.address}
                />
                <SectionComponent header={"City"} info={patientData?.city} />
                <SectionComponent header={"State"} info={patientData?.state} />
                <SectionComponent header={"Zip"} info={patientData?.zip} />
              </SectionColumn>
              <DivideLine height={"30.5rem"} />
              <SectionColumn style={{ marginBottom: "auto" }} width={"50%"}>
                <SectionComponent
                  topMargin
                  header={"SSN"}
                  info={patientData?.ssn}
                />
                <Form
                  {...form}
                  formInputs={fileFormInputs}
                  numberOfColumns={1}
                  customSubmit
                  style={{
                    minWidth: "none",
                    width: "100%",
                    height: "100%",
                    boxShadow: "none",
                    paddingTop: "0",
                  }}
                />
              </SectionColumn>
            </SectionInfoWrapper>
          </SectionWrapper>
          <AlignStartWrapper style={{ marginTop: "1.50rem" }}>
            <TitleS>Provider Info</TitleS>
          </AlignStartWrapper>
          <SectionWrapper>
            <SectionInfoWrapper>
              <SectionColumn width={"50%"}>
                <SectionComponent
                  topMargin
                  header={"Type of Provider"}
                  info={request?.provider.type}
                />
                <SectionComponent
                  header={"Provider Name"}
                  info={request?.provider.name}
                />
                <SectionComponent
                  header={"Phone"}
                  info={request?.provider.phone || ""}
                />
                <SectionComponent
                  header={"Fax"}
                  info={request?.provider.fax || ""}
                />
              </SectionColumn>
              <DivideLine height={"20rem"} />
              <SectionColumn style={{ marginBottom: "auto" }} width={"50%"}>
                <SectionComponent
                  topMargin
                  header={"Address"}
                  info={request?.provider.address}
                />
                <SectionComponent
                  header={"City"}
                  info={request?.provider.city}
                />
                <SectionComponent
                  header={"State"}
                  info={request?.provider.state}
                />
                <SectionComponent
                  header={"Zipcode"}
                  info={request?.provider.zip}
                />
              </SectionColumn>
            </SectionInfoWrapper>
          </SectionWrapper>
        </div>
        <div style={{ marginBottom: "7.5rem" }} />
      </Wrapper>
    </>
  ) : (
    <TailSpin color="#FFFFFF" height={20} width={20} />
  );
}
interface SectionComponentProps {
  header: string;
  info: string;
  topMargin?: boolean;
}
const SectionComponent = ({
  header,
  info,
  topMargin,
}: SectionComponentProps) => {
  return (
    <>
      <SubtitlesM
        style={{
          margin: `${
            topMargin ? "1.500rem 0 0.750rem 1.500rem" : "0 0 0.750rem 1.500rem"
          }`,
        }}
      >
        {header}
      </SubtitlesM>
      <SectionInfoWrapper style={{ marginBottom: "2.250rem" }}>
        <ParagraphS style={{ padding: 0, margin: "0 0 0 1.50rem" }}>
          {info}
        </ParagraphS>
      </SectionInfoWrapper>
    </>
  );
};
