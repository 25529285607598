import styled from "styled-components";

export const BreadcrumbContainer = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
`;

export const BadgeWrap = styled.div`
  position: absolute;
  width: 568px;
  background: #fff;
  z-index: 100;
  max-height: 210px;
  min-height: 44px;
  overflow: scroll;
  padding: 16px;
  border-radius: 12px;
`;

export const InputWrapper = styled.div`
  margin-bottom: 52px;
  width: 100%;
`;

