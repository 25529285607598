export const Theme = {
  colors: {
    primaries_neg100: "#862E9C",
    primaries_000: "#AE3EC9",
    primaries_100: "#CC5DE8",
    primaries_200: "#E599F7",
    primaries_300: "#F3D9FA",
    neutrals_000: "#212529",
    neutrals_100: "#495057",
    neutrals_200: "#adb5bd",
    neutrals_300: "#ced4da",
    neutrals_400: "#f1f3f5",
    neutrals_500: "#ffffff",
    success_neg100: "#37b24d",
    success_000: "#40c057",
    success_100: "#69db7c",
    success_200: "#b2f2bb",
    success_300: "#ebfbee",
    warning_neg100: "#f59f00",
    warning_000: "#fab005",
    warning_100: "#ffd43b",
    warning_200: "#ffec99",
    warning_300: "#fff9db",
    danger_neg100: "#f03e3e",
    danger_000: "#fa5252",
    danger_100: "#ff8787",
    danger_200: "#ffc9c9",
    danger_300: "#fff5f5",
    red_000: "#EF4444",
    green_000: "#22C55E",
    green_100: "#0AC3A2",
    blue_000: "#0EA5E9",
    amber_000: "#F59E0B",
    violet_000: "#8B5CF6",
    gray_000: "#71717A",
    gray_100: "#E2E8F0",
    gray_200: "#64748B",
  },
  shadows: {
    dropshadow_xs: "0rem 0.125rem 0.25rem 0rem rgba(176, 184, 192, 0.2)",
    dropshadow_s: "0rem 0.25rem 0.5rem 0rem rgba(176, 184, 192, 0.2)",
    dropshadow_m: "0rem 0.5rem 1rem 0rem rgba(176, 184, 192, 0.2)",
    dropshadow_l: "0rem 0.75rem 1.5rem 0rem rgba(176, 184, 192, 0.2)",
    dropshadow_accent: "0rem 0rem 0rem 0.25rem rgba(243, 218, 250, 1)",
  },
  strokes: {
    outline_primaries_s: "0.063rem",
    outline_primaries_m: "0.125rem",
  },
  spacing: {
    spacing_4xs: "0.125rem",
    spacing_3xs: "0.25rem",
    spacing_2xs: "0.5rem",
    spacing_xs: "0.75rem",
    spacing_s: "1rem",
    spacing_m: "1.5rem",
    spacing_l: "2rem",
    spacing_xl: "3rem",
    spacing_xl2: "4rem",
    spacing_xl3: "6rem",
    spacing_xl4: "8rem",
    spacing_xl5: "16rem",
  },
  radii: {
    corner_radius_xs: "0.125rem",
    corner_radius_s: "0.25rem",
    corner_radius_m: "0.5rem",
    corner_radius_l: "0.75rem",
    corner_radius_full: "624.938rem",
  },
  opacities: {
    opacity_full: "100%",
    opacity_high: "75%",
    opacity_half: "50%",
    opacity_low: "25%",
  },
};
