import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import Form from "../../components/organisms/Form";
import { ContentWrapper } from "../../components/ContentWrapper";
import { TitleM } from "../../components/atoms/Typography";
import { Breadcrumb } from "../../components/molecules/Breadcrumb";
import { useCreateUserMutation } from "../../redux/reduxQuery/usersApi";

import useToast from "../../hooks/useToast";
function CreateUser() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [createUser] = useCreateUserMutation();
  const form = useForm();
  const toast = useToast();
  const onSubmit = form.handleSubmit(async (data) => {
    try {
      const { address, state, zip, email, name, city, roles } = data;
      const response = await createUser({
        roles,
        address,
        state: state.value,
        zip,
        email,
        name,
        city,
      });
      toast.success(`${name} has been created successfully.`);
      navigate(-1);
      return response;
    } catch (error: any) {
      toast.error(
        `Unable to create user: ${
          error?.message || String(JSON.stringify(error))
        }`
      );
    }
  });
  const crumbLinks = [
    {
      text: "Users",
      path: `/client/${id}/users`,
    },
    {
      text: "Add New User",
    },
  ];
  const formInputs = [
    [
      { id: "name", label: "User Name", required: true, copy: false },
      {
        id: "email",
        label: "User Email",
        required: true,
        copy: false,
        type: "email",
      },
      {
        id: "managerMember",
        radioLabels: ["Admin", "Users"],
        values: ["clientmanager", "clientmember"],
        radioHeader: "Admin/Users",
        radio: true,
        required: true,
      },
    ],
    [
      { id: "address", label: "Address", required: true, copy: false },
      {
        id: "state",
        label: "State",
        required: true,
        copy: false,
        type: "state",
      },
    ],
    [
      { id: "city", label: "City", required: true, copy: false },
      {
        id: "zip",
        label: "Zip Code",
        required: true,
        copy: false,
        type: "zip",
      },
    ],
  ];
  return (
    <>
      <ContentWrapper>
        <div style={{ marginBottom: "1.5rem" }} />
        <Breadcrumb crumbs={crumbLinks} />
        <TitleM>Add New User</TitleM>
        <Form
          {...form}
          onSubmit={onSubmit}
          formInputs={formInputs}
          numberOfColumns={3}
          submitButtonText={"Create User"}
          cancelAction={() => navigate(-1)}
        />
      </ContentWrapper>
    </>
  );
}

export default CreateUser;
